import StaticDataService from '@/services/StaticDataService.js'

export const state = StaticDataService.getProjection()

export const mutations = {
  INIT_CLIENT_PROJECTION(state) {
    Object.assign(state, StaticDataService.getProjection())
  },

  PRESET_FINANCIAL_PLAN(state, items) {
    // initialize items with DDB data every time, when switching between items to retain data consistency
    state.financialPlan = items
    // preset retirement parameters with proposed data while retaining ractivity in state
    Object.entries(state.financialPlan).forEach(([, item]) => {
      if (item.id == 2) {
        Object.assign(state.parameters, item.proposedData)
      }
    })
  },

  SET_FINANCIAL_PLAN_PARAMETERS(state, parameters) {
    // Setter for two way computed property parameters in financial plan components
    state.parameters = parameters
  },

  SET_FINANCIAL_PLAN_SELECTED_ITEM_ID(state, id) {
    // Setter for two way computed property selectedItemId
    state.selectedItemId = id
  },
  SET_FINANCIAL_PLAN_NEW_ITEM_ID(state, id) {
    // Setter for two way computed property newItemId
    state.newItemId = id
  }
}

export const actions = {}

export const getters = {}
