import { API } from 'aws-amplify'

const getDefaultState = () => {
  return {
    definitions: {
      assets: [],
      liabilities: [],
      incomeSources: [],
      spendingStreams: [],
      modelPortfolios: []
    }
  }
}

export const state = getDefaultState()

export const mutations = {
  INIT_ADVISOR_DEFINITIONS(state) {
    Object.assign(state, getDefaultState())
  },

  SET_ADVISOR_DEFINITIONS(state, attributes) {
    state.definitions = attributes
  },

  CREATE_ADVISOR_DEFINITION_ITEM(state, definitionItem) {
    console.log('CREATE_ADVISOR_DEFINITION_ITEM', definitionItem.item)
    state.definitions[definitionItem.group].push(definitionItem.item)
  },

  UPDATE_ADVISOR_DEFINITION_ITEM(state, definitionItem) {
    console.log('UPDATE_ADVISOR_DEFINITION_ITEM', definitionItem.item)
    let index = state.definitions[definitionItem.group].findIndex(
      obj => obj.id == definitionItem.item.id
    )
    state.definitions[definitionItem.group].splice(index, 1)
    state.definitions[definitionItem.group].push(definitionItem.item)
  },

  DELETE_ADVISOR_DEFINITION_ITEM(state, definitionItem) {
    console.log('DELETE_ADVISOR_DEFINITION_ITEM', definitionItem.item)
    let index = state.definitions[definitionItem.group].findIndex(
      obj => obj.id == definitionItem.item.id
    )
    state.definitions[definitionItem.group].splice(index, 1)
  },

  DELETE_ADVISOR_MODEL_PORTFOLIO(state, definitionItem) {
    console.log('DELETE_ADVISOR_MODEL_PORTFOLIO', definitionItem.item)
    definitionItem.item.map(id => {
      let index = state.definitions[definitionItem.group].findIndex(
        obj => obj.id == id
      )
      state.definitions[definitionItem.group].splice(index, 1)
    })
  }
}

export const actions = {
  fetchAdvisorDefinitions({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      let path = '/advisors/definitions'
      API.get('Falcon9API', path)
        .then(event => {
          commit('SET_ADVISOR_DEFINITIONS', event.response)
          console.log(
            'SUCCESS - API retrieved advisor definitions',
            event.response
          )
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to retrieve advisor definitions',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  refreshAdvisorDefinitions({ state, commit, dispatch, getters }) {
    return dispatch('fetchAdvisorDefinitions').then(() => {
      let data = {
        assets: [],
        liabilities: [],
        incomeSources: [],
        spendingStreams: [],
        modelPortfolios: []
      }
      // Assets
      // transfer individual growth rates from advisor data into current admin data and refresh advisor dataset
      Object.entries(getters.adminDefinitions.assets).forEach(([, valTen]) => {
        data.assets.push(valTen)
        Object.entries(state.definitions.assets).forEach(([, valAdv]) => {
          if (valTen.id == valAdv.id) {
            valTen.growth = valAdv.growth
            let index = data.assets.findIndex(obj => obj.id == valAdv.id)
            data.assets.splice(index, 1)
            data.assets.push(valTen)
          }
        })
      })
      // add individual advisor definitions to new dataset
      Object.entries(state.definitions.assets).forEach(([, valAdv]) => {
        if (valAdv.id > 9999) {
          data.assets.push(valAdv)
        }
      })
      // Liabilities
      // transfer individual interest rates from advisor data into current admin data and refresh advisor dataset
      Object.entries(getters.adminDefinitions.liabilities).forEach(
        ([, valTen]) => {
          data.liabilities.push(valTen)
          Object.entries(state.definitions.liabilities).forEach(
            ([, valAdv]) => {
              if (valTen.id == valAdv.id) {
                valTen.interest = valAdv.interest
                let index = data.liabilities.findIndex(
                  obj => obj.id == valAdv.id
                )
                data.liabilities.splice(index, 1)
                data.liabilities.push(valTen)
              }
            }
          )
        }
      )
      // add individual advisor definitions to new dataset
      Object.entries(state.definitions.liabilities).forEach(([, valAdv]) => {
        if (valAdv.id > 9999) {
          data.liabilities.push(valAdv)
        }
      })
      // incomeSources
      // transfer individual growth rates from advisor data into current admin data and refresh advisor dataset
      Object.entries(getters.adminDefinitions.incomeSources).forEach(
        ([, valTen]) => {
          data.incomeSources.push(valTen)
          Object.entries(state.definitions.incomeSources).forEach(
            ([, valAdv]) => {
              if (valTen.id == valAdv.id) {
                valTen.growth = valAdv.growth
                let index = data.incomeSources.findIndex(
                  obj => obj.id == valAdv.id
                )
                data.incomeSources.splice(index, 1)
                data.incomeSources.push(valTen)
              }
            }
          )
        }
      )
      // add individual advisor definitions to new dataset
      Object.entries(state.definitions.incomeSources).forEach(([, valAdv]) => {
        if (valAdv.id > 9999) {
          data.incomeSources.push(valAdv)
        }
      })

      // spendingStreams
      // transfer individual growth rates from advisor data into current admin data and refresh advisor dataset
      Object.entries(getters.adminDefinitions.spendingStreams).forEach(
        ([, valTen]) => {
          data.spendingStreams.push(valTen)
          Object.entries(state.definitions.spendingStreams).forEach(
            ([, valAdv]) => {
              if (valTen.id == valAdv.id) {
                valTen.growth = valAdv.growth
                let index = data.spendingStreams.findIndex(
                  obj => obj.id == valAdv.id
                )
                data.spendingStreams.splice(index, 1)
                data.spendingStreams.push(valTen)
              }
            }
          )
        }
      )
      // add individual advisor definitions to new dataset
      Object.entries(state.definitions.spendingStreams).forEach(
        ([, valAdv]) => {
          if (valAdv.id > 9999) {
            data.spendingStreams.push(valAdv)
          }
        }
      )

      // modelPortfolios
      // transfer all current tenant/admin modelPortfolios into advisor data
      data.modelPortfolios = getters.adminDefinitions.modelPortfolios
      // add individual advisor portfolios ( if any ) with id > 9999
      console.log('Step 1 = ', data.modelPortfolios)
      Object.entries(state.definitions.modelPortfolios).forEach(
        ([, valAdv]) => {
          if (valAdv.id > 9999) {
            data.modelPortfolios.push(valAdv)
          }
        }
      )
      console.log('Step 2 = ', data.modelPortfolios)

      let path = '/advisors/definitions'
      let myinit = {
        body: data
      }
      // rewrite refreshed data to database and state
      API.post('Falcon9API', path, myinit)
        .then(event => {
          console.log('SUCCESS - API refreshed advisor definitions', event)
          commit('SET_ADVISOR_DEFINITIONS', event.appContext)
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to refresh advisor definitions',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
        })
    })
  },

  createAdvisorDefinitionItem({ commit, dispatch }, definitionItem) {
    return new Promise((resolve, reject) => {
      let group = definitionItem.group
      let path = '/advisors/definitions/' + group
      let myinit = {
        body: definitionItem.item
      }
      API.post('Falcon9API', path, myinit)
        .then(event => {
          commit('CREATE_ADVISOR_DEFINITION_ITEM', definitionItem)
          console.log('SUCCESS - API created advisor definition in DDB', event)
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to create advisor definition in DDB',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  updateAdvisorDefinitionItem({ commit, dispatch }, definitionItem) {
    return new Promise((resolve, reject) => {
      let group = definitionItem.group
      let path = '/advisors/definitions/' + group
      let myinit = {
        body: definitionItem.item
      }
      API.put('Falcon9API', path, myinit)
        .then(event => {
          commit('UPDATE_ADVISOR_DEFINITION_ITEM', definitionItem)
          console.log('SUCCESS - API updated advisor definition in DDB', event)
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to update advisor definition in DDB',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  deleteAdvisorDefinitionItem({ commit, dispatch }, definitionItem) {
    return new Promise((resolve, reject) => {
      let group = definitionItem.group
      let path = '/advisors/definitions/' + group
      let myinit = {
        body: definitionItem.item
      }
      API.del('Falcon9API', path, myinit)
        .then(() => {
          if (group == 'modelPortfolios') {
            commit('DELETE_ADVISOR_MODEL_PORTFOLIO', definitionItem)
            console.log(
              'SUCCESS - SUCCESS - API deleted advisor model portfolio in DDB'
            )
            resolve()
          } else {
            commit('DELETE_ADVISOR_DEFINITION_ITEM', definitionItem)
            console.log(
              'SUCCESS - SUCCESS - API deleted advisor definition in DDB'
            )
            resolve()
          }
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to delete advisor definition in DDB',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  }
}

export const getters = {
  advisorDefinitionsAssets(state) {
    return state.definitions.assets
  },

  advisorDefinitionsAssetInvestmentCategories(state) {
    const investmentCategories = []
    const investmentCategoryNamesRanked = []
    // retrieve all(default/individual) Investment Categories (ranked) from Database
    Object.entries(state.definitions.assets).forEach(([, obj]) => {
      if (obj.class == 'Investment') {
        investmentCategories.push(obj)
      }
    })
    // sort advisor investment category objects according to rank
    investmentCategories.sort(function(a, b) {
      return a.rank - b.rank
    })
    // create an array of investment category names (sorted by rank)
    Object.entries(investmentCategories).forEach(([, obj]) => {
      investmentCategoryNamesRanked.push({ name: obj.category, type: obj.type })
    })
    return investmentCategoryNamesRanked
  },

  advisorDefinitionsLiabilities(state) {
    return state.definitions.liabilities
  },

  advisorDefinitionsIncome(state) {
    return state.definitions.incomeSources
  },

  advisorDefinitionsSpending(state) {
    return state.definitions.spendingStreams
  },

  advisorDefinitionsModelPortfolios(state) {
    return state.definitions.modelPortfolios
  }
}
