import { API } from 'aws-amplify'

const getDefaultState = () => {
  return {
    activeClients: [],
    activeClientsCount: '',
    archivedClients: [],
    archivedClientsCount: '',
    deletedClients: [],
    deletedClientsCount: '',
    standInClients: [],
    standInClientsCount: '',
    standInAdv: [],
    search: ''
  }
}

export const state = getDefaultState()

export const mutations = {
  INIT_CLIENTS_STATE(state) {
    console.log('INIT_CLIENTS_STATE')
    Object.assign(state, getDefaultState())
  },

  SET_ACTIVE_CLIENTS(state, items) {
    console.log('SET_ACTIVE_CLIENTS', items)
    state.activeClients = items
  },

  SET_ACTIVE_CLIENTS_COUNT(state, value) {
    state.activeClientsCount = value
  },

  SET_ARCHIVED_CLIENTS(state, items) {
    state.archivedClients = items
  },

  SET_ARCHIVED_CLIENTS_COUNT(state, value) {
    state.archivedClientsCount = value
  },

  SET_DELETED_CLIENTS(state, items) {
    state.deletedClients = items
  },

  SET_DELETED_CLIENTS_COUNT(state, value) {
    state.deletedClientsCount = value
  },

  SET_STAND_IN_CLIENTS(state, items) {
    state.standInClients = items
  },

  SET_STAND_IN_CLIENTS_COUNT(state, value) {
    state.standInClientsCount = value
  },

  SET_STAND_IN_ADV(state, items) {
    state.standInAdv = items
  },

  SET_CLIENTS_SEARCH(state, value) {
    state.search = value
  }
}

export const actions = {
  fetchClients({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      let path = '/clients/all'
      API.get('Falcon9API', path)
        .then(event => {
          commit('SET_ACTIVE_CLIENTS', event.response.activeClients.Items)
          commit('SET_ACTIVE_CLIENTS_COUNT', event.response.activeClients.Count)
          commit('SET_ARCHIVED_CLIENTS', event.response.archivedClients.Items)
          commit(
            'SET_ARCHIVED_CLIENTS_COUNT',
            event.response.archivedClients.Count
          )
          commit('SET_DELETED_CLIENTS', event.response.deletedClients.Items)
          commit(
            'SET_DELETED_CLIENTS_COUNT',
            event.response.deletedClients.Count
          )
          commit('SET_STAND_IN_CLIENTS', event.response.standInClients.Items)
          commit(
            'SET_STAND_IN_CLIENTS_COUNT',
            event.response.standInClients.Count
          )
          commit('SET_STAND_IN_ADV', event.response.standInAdv.Items)
          console.log('SUCCESS - API retrieved clients', event.response)
          resolve()
        })
        .catch(err => {
          console.log('ERROR - API failed to retrieve clients', err.response)
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  }
}

export const getters = {
  myActiveClients(state) {
    return state.activeClients
  },

  myActiveClientsCount(state) {
    return state.activeClientsCount
  },

  myArchivedClients(state) {
    return state.archivedClients
  },

  myArchivedClientsCount(state) {
    return state.archivedClientsCount
  },

  myDeletedClients(state) {
    return state.deletedClients
  },

  myDeletedClientsCount(state) {
    return state.deletedClientsCount
  },

  myStandInClients(state) {
    return state.standInClients
  },

  myStandInClientsCount(state) {
    return state.standInClientsCount
  },

  myStandInAdvisors(state) {
    return state.standInAdv
  },

  clientsSearch(state) {
    return state.search
  }
}
