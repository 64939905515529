<template>
  <div v-if="userRoleAdvisor">
    <v-app id="falcon9" class="mx-n4">
      <v-app-bar color="white" height="65" flat clipped app>
        <v-app-bar-nav-icon class="d-md-none" @click.stop="drawer = !drawer" />
        <v-toolbar-title style="width: 250px" class="mt-2">
          <span class="primary--text display-1 font-weight-light">Ledger</span
          ><span class="success--text display-1 font-weight-normal">5</span>
        </v-toolbar-title>

        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="success"
          centered
          class="secondary--text d-none d-md-block mb-4"
        >
          <template v-for="(line, index) in item.content">
            <v-menu
              offset-y
              open-on-hover
              bottom
              origin="center center"
              transition="slide-y-transition"
              :key="index"
            >
              <template v-slot:activator="{ on }">
                <v-tab
                  v-if="line.children"
                  v-model="tab"
                  link
                  :disabled="!clientActive && line.blockNav === 'yes'"
                  :key="index"
                  v-on="on"
                  class="primary--text text-uppercase subtitle-2 mt-8"
                >
                  {{ line.text }}
                </v-tab>

                <v-tab
                  v-else
                  v-model="tab"
                  link
                  :disabled="!clientActive && line.blockNav === 'yes'"
                  :key="index"
                  @click="setThis(line.page)"
                  class="primary--text text-uppercase subtitle-2 mt-8"
                >
                  {{ line.text }}
                </v-tab>
              </template>

              <v-list v-if="line.children" dense class="grey lighten-5">
                <v-list-item
                  v-for="(child, indexChild) in line.children"
                  :key="indexChild"
                  link
                  :disabled="!clientActive && line.blockNav === 'yes'"
                  @click="setThis(child.page)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="primary--text text-uppercase">
                      {{ child.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-tabs>

        <v-spacer />

        <v-menu
          offset-y
          open-on-hover
          bottom
          origin="center center"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              outlined
              large
              v-on="on"
              class="success--text mt-3 mr-0"
            >
              <v-icon color="success">mdi-account-circle</v-icon>
            </v-btn>
          </template>

          <v-list dense class="grey lighten-5">
            <v-list-item>
              <v-list-item-title class="#31465F--text font-weight-light"
                >APP-Mode: CLIENT
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="exitClient">
              <v-list-item-title class="error--text"
                >EXIT CLIENT</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" clipped temporary app>
        <v-list>
          <template v-for="line in item.content">
            <v-list-group
              v-if="line.children"
              color="success"
              :key="line.text"
              v-model="line.model"
              :prepend-icon="line.model ? line.icon : line['icon-alt']"
              append-icon=""
              :disabled="!clientActive && line.blockNav === 'yes'"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    <h4>{{ line.text }}</h4>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(child, i) in line.children"
                :key="i"
                link
                :disabled="!clientActive && line.blockNav === 'yes'"
                @click="setThis(child.page)"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon color="primary">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="primary--text text-capitalize">
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="line.text"
              color="success"
              link
              :disabled="!clientActive && line.blockNav === 'yes'"
              @click="setThis(line.page)"
            >
              <v-list-item-action>
                <v-icon color="primary">{{ line.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="primary--text">
                  {{ line.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <template v-slot:append>
          <div class="text-center ma-4 mx-8">
            <v-card color="primary">
              <v-btn text color="success" @click="exitClient"
                >EXIT CLIENT
              </v-btn>
            </v-card>
          </div>
        </template>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid class="mt-n16">
          <slot></slot>

          <v-footer color="clientLayout" fixed style="height: 25px;">
            <div class="white--text caption mt-n1">
              &copy; 2018 - {{ new Date().getFullYear() }}
            </div>
            <div class="caption clientLayout--text mt-n1">...</div>
            <div class="white--text caption mt-n1">
              powered by Ledger
            </div>
            <div class="caption success--text font-weight-bold mt-n1">5</div>
            <v-spacer />
          </v-footer>
        </v-container>
      </v-main>
    </v-app>
  </div>

  <div v-else>
    <template>
      <v-card width="400" class="mx-auto mt-10">
        <v-toolbar color="primary lighten-1 white--text">
          <v-toolbar-title>
            <h1 class="display-1">
              Welcome ...
            </h1>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-card-actions>
              <v-btn color="info" small :to="`/login`">Login</v-btn>
              <v-spacer></v-spacer>
              <!-- <v-btn color="success" small :to="`/register`">Register</v-btn> -->
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'theClientLayout',

  data() {
    return {
      drawer: null
    }
  },

  methods: {
    exitClient() {
      this.$router.push({ name: 'advisorDashboard' }).then(() => {
        this.$store.dispatch('initClientGlobal')
      })
    },

    setThis(page) {
      // check if there is a page to go to, and if the page is different to the current one, then execute the push
      if (page != '' && page.fullPath != this.$route.fullPath) {
        // check if target is clientProfile and move carousel to appropriate place
        if (page.name == 'clientProfile') {
          this.$store.commit('SET_CLIENT_PROFILE_CARO', page.params.tabs)
        }
        // check if target is clientLedger and move carousel to appropriate place
        if (page.name == 'clientLedger') {
          this.$store.commit('SET_CLIENT_LEDGER_CARO', page.params.tabs)
        }
        // check if target is clientProjection and move carousel to appropriate place
        if (page.name == 'clientProjection') {
          this.$store.commit('SET_CLIENT_PROJECTION_CARO', page.params.tabs)
        }
        // check if target is clientRetirement and move carousel to appropriate place
        // if (page.name == 'clientRetirement') {
        //   this.$store.commit('SET_CLIENT_RETIREMENT_CARO', page.params.tabs)
        // }
        // push router to new page
        this.$router.push(page)
      }
    }
  },

  computed: {
    userRoleAdvisor() {
      return (
        (this.loggedIn && !!(this.role === 'TenAdm')) ||
        (this.loggedIn && !!(this.role === 'TenAdv'))
      )
    },

    clientActive() {
      return this.clientLoaded
    },

    tab: {
      get() {
        return this.system.tabClientLayout
      },
      set(attributes) {
        this.$store.commit('SET_CLIENT_LAYOUT_TAB', attributes)
      }
    },

    item() {
      return {
        content: [
          {
            text: 'Dashboard',
            icon: 'mdi-gauge',
            blockNav: 'yes',
            page: { name: 'clientDashboard' }
          },
          this.getProfileNavItem,
          {
            text: 'Ledger',
            // icon: 'mdi-view-dashboard-variant-outline',
            icon: 'mdi-chevron-up',
            'icon-alt': 'mdi-chevron-down',
            blockNav: 'yes',
            model: false,
            page: { name: 'clientLedger', params: { tabs: 'assets' } },
            children: [
              // {
              //   text: 'Groups',
              //   page: { name: 'clientLedger', params: { tabs: 'groups' } },
              //   blockNav: 'yes'
              // },
              {
                text: 'Assets',
                page: { name: 'clientLedger', params: { tabs: 'assets' } },
                blockNav: 'yes'
              },
              {
                text: 'Liabilities',
                page: { name: 'clientLedger', params: { tabs: 'liabilities' } },
                blockNav: 'yes'
              },
              {
                text: 'Income',
                page: { name: 'clientLedger', params: { tabs: 'income' } },
                blockNav: 'yes'
              },
              {
                text: 'Expenses',
                page: { name: 'clientLedger', params: { tabs: 'expenses' } },
                blockNav: 'yes'
              },
              {
                text: 'Goals',
                page: { name: 'clientLedger', params: { tabs: 'goals' } },
                blockNav: 'yes'
              }
            ]
          },
          {
            text: 'Performance',
            icon: 'mdi-gauge',
            blockNav: 'yes',
            page: { name: 'clientPerformance' }
          },
          {
            text: 'Projection',
            icon: 'mdi-chevron-up',
            'icon-alt': 'mdi-chevron-down',
            blockNav: 'yes',
            model: false,
            page: {
              name: 'clientProjection',
              params: { tabs: 'financialplan' }
            },
            children: [
              {
                text: 'Financial Plan',
                page: {
                  name: 'clientProjection',
                  params: { tabs: 'financialplan' }
                },
                blockNav: 'yes'
              },
              {
                text: 'Assets',
                page: {
                  name: 'clientProjection',
                  params: { tabs: 'assets' }
                },
                blockNav: 'yes'
              }
            ]
          }
        ]
      }
    },

    getProfileNavItem() {
      let profileItem = {}
      if (this.clientAccHolder2 != '  ') {
        profileItem = {
          text: 'Profile',
          // icon: 'mdi-card-account-details-outline',
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          blockNav: 'yes',
          model: false,
          page: { name: 'clientProfile', params: { tabs: 'account1' } },
          children: [
            {
              text: 'Assumptions',
              page: {
                name: 'clientProfile',
                params: { tabs: 'assumptions' }
              },
              blockNav: 'yes'
            },
            {
              text: this.clientAccHolder1,
              page: {
                name: 'clientProfile',
                params: { tabs: 'account1' }
              },
              blockNav: 'yes'
            },
            {
              text: this.clientAccHolder2,
              page: {
                name: 'clientProfile',
                params: { tabs: 'account2' }
              },
              blockNav: 'yes'
            },
            {
              text: 'Risk Type',
              page: { name: 'clientProfile', params: { tabs: 'risktype' } },
              blockNav: 'yes'
            },
            {
              text: 'Model Portfolio',
              page: {
                name: 'clientProfile',
                params: { tabs: 'modelportfolio' }
              },
              blockNav: 'yes'
            }
          ]
        }
      } else {
        profileItem = {
          text: 'Profile',
          // icon: 'mdi-card-account-details-outline',
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          blockNav: 'yes',
          model: false,
          page: { name: 'clientProfile', params: { tabs: 'account1' } },
          children: [
            {
              text: 'Assumptions',
              page: {
                name: 'clientProfile',
                params: { tabs: 'assumptions' }
              },
              blockNav: 'yes'
            },
            {
              text: this.clientAccHolder1,
              page: {
                name: 'clientProfile',
                params: { tabs: 'account1' }
              },
              blockNav: 'yes'
            },
            {
              text: 'Risk Type',
              page: { name: 'clientProfile', params: { tabs: 'risktype' } },
              blockNav: 'yes'
            },
            {
              text: 'Model Portfolio',
              page: {
                name: 'clientProfile',
                params: { tabs: 'modelportfolio' }
              },
              blockNav: 'yes'
            }
          ]
        }
      }
      return profileItem
    },

    ...mapState(['system']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
