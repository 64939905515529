import { API } from 'aws-amplify'
import LedgerService from '@/services/LedgerService.js'
import FinancialPlanService from '@/services/FinancialPlanService.js'
import StaticDataService from '@/services/StaticDataService.js'
import DataServiceLifeExpectancy from '@/services/DataServiceLifeExpectancy.js'

export const state = StaticDataService.getSystemLedger()

export const mutations = {
  // Ledger Mutations
  RESET_CLIENT_LEDGER(state) {
    console.log('RESET_CLIENT_LEDGER')
    Object.assign(state, StaticDataService.getSystemLedger())
  },

  SET_CLIENT_LEDGER_GENESIS(state, ledger) {
    state.ledger = ledger
  },

  SET_CLIENT_LEDGER(state, ledger) {
    console.log('SET_CLIENT_LEDGER', ledger)
    state.ledger.assets = ledger.response.assets
    state.ledger.liabilities = ledger.response.liabilities
    state.ledger.incomeSources = ledger.response.incomeSources
    state.ledger.spendingStreams = ledger.response.spendingStreams
    state.ledger.goals = ledger.response.goals
    state.ledger.advice = ledger.response.advice
  },

  // Asset Mutations
  CREATE_ASSET(state, asset) {
    console.log('CREATE_ASSET', asset)
    state.ledger.assets.push(asset)
  },

  UPDATE_ASSET(state, asset) {
    console.log('UPDATE_ASSET', asset)
    let index = state.ledger.assets.findIndex(obj => obj.id == asset.id)
    state.ledger.assets.splice(index, 1)
    state.ledger.assets.push(asset)
  },

  DELETE_ASSET(state, asset) {
    console.log('DELETE_ASSET', asset)
    let index1 = state.ledger.assets.findIndex(obj => obj.id == asset.id)
    state.ledger.assets.splice(index1, 1)
  },

  CALCULATE_ASSETS_SUM(state) {
    let sum = 0
    let name = [
      { text: 'None', value: -1, class: 'None', projectionInclude: true }
    ]
    Object.entries(state.ledger.assets).forEach(([, val]) => {
      sum += val.priceTodayApp
      name.push({
        text: val.name,
        value: val.id,
        class: val.class,
        projectionInclude: val.projectionInclude
      })
    })
    state.ledger.assetsSum = sum
    state.ledger.assetsName = name
  },

  CALCULATE_ASSETS_PERCENTAGE(state) {
    Object.entries(state.ledger.assets).forEach(([, val]) => {
      val.priceTodayAppPercent =
        Math.floor((val.priceTodayApp * 10000) / state.ledger.assetsSum) / 100
    })
  },

  // Liability Mutations
  CREATE_LIABILITY(state, liability) {
    console.log('CREATE_LIABILITY', liability)
    state.ledger.liabilities.push(liability)
  },

  UPDATE_LIABILITY(state, liability) {
    console.log('UPDATE_LIABILITY', liability)
    let index = state.ledger.liabilities.findIndex(
      obj => obj.id == liability.id
    )
    state.ledger.liabilities.splice(index, 1)
    state.ledger.liabilities.push(liability)
  },

  DELETE_LIABILITY(state, liability) {
    console.log('DELETE_LIABILITY', liability)
    let index = state.ledger.liabilities.findIndex(
      obj => obj.id == liability.id
    )
    state.ledger.liabilities.splice(index, 1)
  },

  CALCULATE_LIABILITIES_SUM(state) {
    let sum = 0
    Object.entries(state.ledger.liabilities).forEach(([, val]) => {
      sum += val.balanceDueTodayApp
    })
    state.ledger.liabilitiesSum = sum
  },

  CALCULATE_LIABILITIES_PERCENTAGE(state) {
    Object.entries(state.ledger.liabilities).forEach(([, val]) => {
      val.balanceDueTodayAppPercent =
        Math.floor(
          (val.balanceDueTodayApp * 10000) / state.ledger.liabilitiesSum
        ) / 100
    })
  },

  // IncomeSource Mutations
  CREATE_INCOME_SOURCE(state, incomeSource) {
    console.log('CREATE_INCOME_SOURCE', incomeSource)
    state.ledger.incomeSources.push(incomeSource)
  },

  UPDATE_INCOME_SOURCE(state, incomeSource) {
    console.log('UPDATE_INCOME_SOURCE', incomeSource)
    let index = state.ledger.incomeSources.findIndex(
      obj => obj.id == incomeSource.id
    )
    state.ledger.incomeSources.splice(index, 1)
    state.ledger.incomeSources.push(incomeSource)
  },

  DELETE_INCOME_SOURCE(state, incomeSource) {
    console.log('DELETE_INCOME_SOURCE', incomeSource)
    let index = state.ledger.incomeSources.findIndex(
      obj => obj.id == incomeSource.id
    )
    state.ledger.incomeSources.splice(index, 1)
  },

  CALCULATE_INCOME_SOURCES_SUM(state) {
    let sum = 0
    Object.entries(state.ledger.incomeSources).forEach(([, val]) => {
      sum += val.incomePaTodayApp
    })
    state.ledger.incomeSourcesSum = sum
  },

  CALCULATE_INCOME_SOURCES_PERCENTAGE(state) {
    Object.entries(state.ledger.incomeSources).forEach(([, val]) => {
      val.incomePaTodayAppPercent =
        Math.floor(
          (val.incomePaTodayApp * 10000) / state.ledger.incomeSourcesSum
        ) / 100
    })
  },

  // SpendingStream Mutations
  CREATE_SPENDING_STREAM(state, spendingStream) {
    console.log('CREATE_SPENDING_STREAM', spendingStream)
    state.ledger.spendingStreams.push(spendingStream)
  },

  UPDATE_SPENDING_STREAM(state, spendingStream) {
    console.log('UPDATE_SPENDING_STREAM', spendingStream)
    let index = state.ledger.spendingStreams.findIndex(
      obj => obj.id == spendingStream.id
    )
    state.ledger.spendingStreams.splice(index, 1)
    state.ledger.spendingStreams.push(spendingStream)
  },

  DELETE_SPENDING_STREAM(state, spendingStream) {
    console.log('DELETE_SPENDING_STREAM', spendingStream)
    let index = state.ledger.spendingStreams.findIndex(
      obj => obj.id == spendingStream.id
    )
    state.ledger.spendingStreams.splice(index, 1)
  },

  CALCULATE_SPENDING_STREAMS_SUM(state) {
    let sum = 0
    Object.entries(state.ledger.spendingStreams).forEach(([, val]) => {
      sum += val.spendingPaTodayApp
    })
    state.ledger.spendingStreamsSum = sum
  },

  CALCULATE_SPENDING_STREAMS_PERCENTAGE(state) {
    Object.entries(state.ledger.spendingStreams).forEach(([, val]) => {
      val.spendingPaTodayAppPercent =
        Math.floor(
          (val.spendingPaTodayApp * 10000) / state.ledger.spendingStreamsSum
        ) / 100
    })
  },

  // Goal Mutations
  CREATE_GOAL(state, goal) {
    console.log('CREATE_GOAL', goal)
    state.ledger.goals.push(goal)
  },

  UPDATE_GOAL(state, goal) {
    console.log('UPDATE_GOAL', goal)
    let index = state.ledger.goals.findIndex(obj => obj.id == goal.id)
    state.ledger.goals.splice(index, 1)
    state.ledger.goals.push(goal)
  },

  DELETE_GOAL(state, goal) {
    console.log('DELETE_GOAL', goal)
    let index = state.ledger.goals.findIndex(obj => obj.id == goal.id)
    state.ledger.goals.splice(index, 1)
  },

  // Conditions Mutations
  SET_CLIENT_LEDGER_CONDITIONS(state, attributes) {
    console.log('SET_CLIENT_LEDGER_CONDITIONS', attributes)
    state.ledger.conditions = attributes
  }
}

export const actions = {
  // Ledger Actions
  investigateClientLedgerDDB({ state, commit, dispatch }, ledger) {
    return new Promise(resolve => {
      // If there is no data in "advice array" available in DDB, inject SystemLedger Data into DDB as well as in ledger.response object
      if (ledger.response.advice[0] == null) {
        dispatch('createGenesisClientLedger').then(() => {
          ledger.response = state.ledger
          resolve(ledger)
        })
      }
      // If there is data in "advice array" available in DDB, update just conditions information in state to enable ledgerService processing
      else {
        commit('SET_CLIENT_LEDGER_CONDITIONS', ledger.response.conditions)
        resolve(ledger)
      }
    })
  },

  createGenesisClientLedger({ commit, dispatch, getters }) {
    let genesisData = StaticDataService.getSystemLedger()
    // Choose genesis Goals according to account type, in this case personal goals

    if (getters.clientProfileType == 'Individual') {
      // get personal system goals
      genesisData.ledger.goals = StaticDataService.getSystemGoalsPersonal()

      // set the retirement year for the person according to retirement age
      genesisData.ledger.goals[1].currentData.yearPerson1 =
        Number(getters.clientPerson1.birthDate.substring(0, 4)) +
        genesisData.ledger.goals[1].currentData.retirementAgePerson1

      // set the post retirement risk type year to the retirement year
      genesisData.ledger.goals[1].currentData.postRetirementRiskTypeFromYear =
        genesisData.ledger.goals[1].currentData.yearPerson1

      // set the goal headline
      genesisData.ledger.goals[1].currentData.headlinePerson1 =
        getters.clientPerson1.firstName + "'s " + 'Retirement'

      // generate personalized simple description
      genesisData.ledger.goals[1].currentData.description =
        'At age ' +
        genesisData.ledger.goals[1].currentData.retirementAgePerson1 +
        ', maint. ' +
        genesisData.ledger.goals[1].currentData.retirementExpenseRate +
        '% of expenses'

      // generate personalized enhanced (per person) description
      genesisData.ledger.goals[1].currentData.descriptionEnhancedPerson1 =
        getters.clientPerson1.firstName +
        ' retires at age ' +
        genesisData.ledger.goals[1].currentData.retirementAgePerson1 +
        ' and maintains ' +
        genesisData.ledger.goals[1].currentData.retirementExpenseRate +
        '% of the current expenses in Retirement, adjusted for inflation'

      // set lifeExpectancy according to New Zealand government recommendation
      genesisData.ledger.goals[1].currentData.lifeExpectancyPerson1 =
        getters.clientPerson1.gender == 'male' ||
        getters.clientPerson1.gender == 'female'
          ? DataServiceLifeExpectancy.getLifeExpectancy(
              getters.clientPerson1.gender,
              getters.clientPerson1.currentAge,
              Number(getters.clientPerson1.birthDate.substring(0, 4))
            )
          : 100 // default value for intersex and non-binary genders (due to the lack of govt data)

      // copy current data into proposed data to be used as initial financial planning starting point
      genesisData.ledger.goals[1].proposedData = Object.assign(
        {},
        genesisData.ledger.goals[1].currentData
      )

      console.log(
        'using individual personal goals for genesis data',
        genesisData.ledger.goals
      )
    } else if (getters.clientProfileType == 'Joint') {
      // get personal system goals
      genesisData.ledger.goals = StaticDataService.getSystemGoalsPersonal()

      // set the retirement years for both persons according to their retirement age
      genesisData.ledger.goals[1].currentData.yearPerson1 =
        Number(getters.clientPerson1.birthDate.substring(0, 4)) +
        genesisData.ledger.goals[1].currentData.retirementAgePerson1
      genesisData.ledger.goals[1].currentData.yearPerson2 =
        Number(getters.clientPerson2.birthDate.substring(0, 4)) +
        genesisData.ledger.goals[1].currentData.retirementAgePerson2

      // set the post retirement risk type year to the retirement year of the person, who retires first (earliest)
      let retYears = [
        { year: genesisData.ledger.goals[1].currentData.yearPerson1 },
        { year: genesisData.ledger.goals[1].currentData.yearPerson2 }
      ]
      retYears.sort(function(a, b) {
        return a.year - b.year
      })
      genesisData.ledger.goals[1].currentData.postRetirementRiskTypeFromYear =
        retYears[0].year

      // set the goal headlines for each person
      genesisData.ledger.goals[1].currentData.headlinePerson1 =
        getters.clientPerson1.firstName + "'s " + 'Retirement'
      genesisData.ledger.goals[1].currentData.headlinePerson2 =
        getters.clientPerson2.firstName + "'s " + 'Retirement'

      // generate personalized simple description
      genesisData.ledger.goals[1].currentData.description =
        getters.clientPerson1.firstName +
        ' at age ' +
        genesisData.ledger.goals[1].currentData.retirementAgePerson1 +
        ' / ' +
        getters.clientPerson2.firstName +
        ' at age ' +
        genesisData.ledger.goals[1].currentData.retirementAgePerson2

      // generate personalized enhanced (per person) descriptions
      genesisData.ledger.goals[1].currentData.descriptionEnhancedPerson1 =
        getters.clientPerson1.firstName +
        ' retires at age ' +
        genesisData.ledger.goals[1].currentData.retirementAgePerson1 +
        ', while maintaining ' +
        genesisData.ledger.goals[1].currentData.retirementExpenseRate +
        '% of the current, combined expenses in Retirement, adjusted for inflation'
      genesisData.ledger.goals[1].currentData.descriptionEnhancedPerson2 =
        getters.clientPerson2.firstName +
        ' retires at age ' +
        genesisData.ledger.goals[1].currentData.retirementAgePerson2 +
        ', while maintaining ' +
        genesisData.ledger.goals[1].currentData.retirementExpenseRate +
        '% of the current, combined expenses in Retirement, adjusted for inflation'

      // set lifeExpectancy according to New Zealand government recommendation
      genesisData.ledger.goals[1].currentData.lifeExpectancyPerson1 =
        getters.clientPerson1.gender == 'male' ||
        getters.clientPerson1.gender == 'female'
          ? DataServiceLifeExpectancy.getLifeExpectancy(
              getters.clientPerson1.gender,
              getters.clientPerson1.currentAge,
              Number(getters.clientPerson1.birthDate.substring(0, 4))
            )
          : 100 // default value for intersex and non-binary genders (due to the lack of govt data)
      genesisData.ledger.goals[1].currentData.lifeExpectancyPerson2 =
        getters.clientPerson2.gender == 'male' ||
        getters.clientPerson2.gender == 'female'
          ? DataServiceLifeExpectancy.getLifeExpectancy(
              getters.clientPerson2.gender,
              getters.clientPerson2.currentAge,
              Number(getters.clientPerson2.birthDate.substring(0, 4))
            )
          : 100 // default value for intersex and non-binary genders (due to the lack of govt data)

      // copy current data into proposed data to be used as initial financial planning starting point
      genesisData.ledger.goals[1].proposedData = Object.assign(
        {},
        genesisData.ledger.goals[1].currentData
      )

      console.log(
        'using joint personal goals for genesis data',
        genesisData.ledger.goals
      )
    }

    // Choose genesis Goals according to account type, in this case institutional goals
    else {
      genesisData.ledger.goals = StaticDataService.getSystemGoalsInstitutional()
      console.log(
        'using goals institutional for genesis data',
        genesisData.ledger.goals
      )
    }

    return new Promise((resolve, reject) => {
      let path = '/clients/ledger/' + getters.clientIdPrimary
      let myinit = {
        body: genesisData.ledger
      }
      API.post('Falcon9API', path, myinit)
        .then(event => {
          console.log(
            'SUCCESS - API created genesis client ledger items',
            event
          )
          commit('SET_CLIENT_LEDGER_GENESIS', event.appContext)
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to create genesis client ledger items',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  fetchClientLedger({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      let path = '/clients/ledger/' + getters.clientIdPrimary
      API.get('Falcon9API', path)
        // investigate ClientLedgerDDB and init DDB (if required) and set conditions to enable further processing (currency, etc.)
        .then(ledger => dispatch('investigateClientLedgerDDB', ledger))
        // calculate Todays Attributes for all ledgers
        .then(ledger => LedgerService.computeLedger(ledger))
        // update State
        .then(ledger => commit('SET_CLIENT_LEDGER', ledger))
        .then(() => commit('CALCULATE_ASSETS_SUM'))
        .then(() => commit('CALCULATE_ASSETS_PERCENTAGE'))
        .then(() => commit('CALCULATE_LIABILITIES_SUM'))
        .then(() => commit('CALCULATE_LIABILITIES_PERCENTAGE'))
        .then(() => commit('CALCULATE_INCOME_SOURCES_SUM'))
        .then(() => commit('CALCULATE_INCOME_SOURCES_PERCENTAGE'))
        .then(() => commit('CALCULATE_SPENDING_STREAMS_SUM'))
        .then(() => commit('CALCULATE_SPENDING_STREAMS_PERCENTAGE'))
        .then(() => {
          console.log(
            'SUCCESS - API retrieved and processed client ledger from DDB'
          )
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to retrieve client ledger from DDB',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  // Asset Actions
  createAsset({ commit, dispatch, getters }, asset) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this asset only (including projection data and year)
      LedgerService.computeAsset(asset)
        // add asset to state and update all existing assets accordingly
        .then(asset => commit('CREATE_ASSET', asset))
        .then(() => commit('CALCULATE_ASSETS_SUM'))
        .then(() => commit('CALCULATE_ASSETS_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before post to DDB
          const assetDDB = Object.assign({}, asset)
          delete assetDDB.priceTodayLocal
          delete assetDDB.priceTodayApp
          delete assetDDB.priceTodayAppPercent
          delete assetDDB.gainTodayLocal
          delete assetDDB.gainTodayLocalPercent
          // Post asset to DDB (always tied to primary client ID)
          let path = '/clients/ledger/asset/' + getters.clientIdPrimary
          let myinit = {
            body: assetDDB
          }
          API.post('Falcon9API', path, myinit)
            .then(event => {
              console.log('SUCCESS - API created new asset in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to create new asset in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  updateAsset({ commit, dispatch, getters }, asset) {
    return new Promise((resolve, reject) => {
      // update Todays Attributes for this asset only (including projection data and year)
      LedgerService.computeAsset(asset)
        // strip down unneccessary attributes before updating DDB
        .then(asset => {
          const assetDDB = Object.assign({}, asset)
          delete assetDDB.priceTodayLocal
          delete assetDDB.priceTodayApp
          delete assetDDB.priceTodayAppPercent
          delete assetDDB.gainTodayLocal
          delete assetDDB.gainTodayLocalPercent
          // update (PUT) asset including related liabilities, income sources and spending streams in DDB
          // (always tied to primary client ID)
          let path = '/clients/ledger/asset/' + getters.clientIdPrimary
          let myinit = {
            body: assetDDB
          }
          API.put('Falcon9API', path, myinit)
            // due to a possible change in other ledgers, calculate Todays Attributes for all ledgers
            .then(ledger => LedgerService.computeLedger(ledger))
            // update State
            .then(ledger => commit('SET_CLIENT_LEDGER', ledger))
            .then(() => commit('CALCULATE_ASSETS_SUM'))
            .then(() => commit('CALCULATE_ASSETS_PERCENTAGE'))
            .then(() => commit('CALCULATE_LIABILITIES_SUM'))
            .then(() => commit('CALCULATE_LIABILITIES_PERCENTAGE'))
            .then(() => commit('CALCULATE_INCOME_SOURCES_SUM'))
            .then(() => commit('CALCULATE_INCOME_SOURCES_PERCENTAGE'))
            .then(() => commit('CALCULATE_SPENDING_STREAMS_SUM'))
            .then(() => commit('CALCULATE_SPENDING_STREAMS_PERCENTAGE'))
            .then(() => {
              console.log(
                'SUCCESS - API retrieved and processed client ledger from DDB'
              )
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to retrieve client ledger from DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  deleteAsset({ commit, dispatch, getters }, asset) {
    return new Promise((resolve, reject) => {
      // strip down unneccessary attributes before updating DDB
      const assetDDB = Object.assign({}, asset)
      delete assetDDB.priceTodayLocal
      delete assetDDB.priceTodayApp
      delete assetDDB.priceTodayAppPercent
      delete assetDDB.gainTodayLocal
      delete assetDDB.gainTodayLocalPercent
      // delete (DEL) asset including related liabilities, income sources and spending streams in DDB
      let path = '/clients/ledger/asset/' + getters.clientIdPrimary
      let myinit = {
        body: assetDDB
      }
      API.del('Falcon9API', path, myinit)
        // due to a possible change in other ledgers, calculate Todays Attributes for all ledgers
        .then(ledger => LedgerService.computeLedger(ledger))
        // update State
        .then(ledger => commit('SET_CLIENT_LEDGER', ledger))
        .then(() => commit('CALCULATE_ASSETS_SUM'))
        .then(() => commit('CALCULATE_ASSETS_PERCENTAGE'))
        .then(() => commit('CALCULATE_LIABILITIES_SUM'))
        .then(() => commit('CALCULATE_LIABILITIES_PERCENTAGE'))
        .then(() => commit('CALCULATE_INCOME_SOURCES_SUM'))
        .then(() => commit('CALCULATE_INCOME_SOURCES_PERCENTAGE'))
        .then(() => commit('CALCULATE_SPENDING_STREAMS_SUM'))
        .then(() => commit('CALCULATE_SPENDING_STREAMS_PERCENTAGE'))
        .then(() => {
          console.log(
            'SUCCESS - API retrieved and processed client ledger from DDB'
          )
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to retrieve client ledger from DDB',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  // Liability Actions
  createLiability({ commit, dispatch, getters }, liability) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this liability only (including projection data and year)
      LedgerService.computeLiability(liability)
        // add liability to state and update all existing liabilities accordingly
        .then(liability => commit('CREATE_LIABILITY', liability))
        .then(() => commit('CALCULATE_LIABILITIES_SUM'))
        .then(() => commit('CALCULATE_LIABILITIES_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before post to DDB
          const liabilityDDB = Object.assign({}, liability)
          delete liabilityDDB.balanceDueTodayLocal
          delete liabilityDDB.balanceDueTodayApp
          delete liabilityDDB.balanceDueTodayAppPercent
          delete liabilityDDB.repayedTodayLocal
          delete liabilityDDB.repayedTodayLocalPercent
          // create (Post) asset to DDB (always tied to primary client ID)
          let path = '/clients/ledger/liability/' + getters.clientIdPrimary
          let myinit = {
            body: liabilityDDB
          }
          API.post('Falcon9API', path, myinit)
            .then(event => {
              console.log('SUCCESS - API created new liability in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to create new liability in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  updateLiability({ commit, dispatch, getters }, liability) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this liability only (including projection data and year)
      LedgerService.computeLiability(liability)
        // update liability, add to state and update all existing liabilities accordingly
        .then(liability => commit('UPDATE_LIABILITY', liability))
        .then(() => commit('CALCULATE_LIABILITIES_SUM'))
        .then(() => commit('CALCULATE_LIABILITIES_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before update to DDB
          const liabilityDDB = Object.assign({}, liability)
          delete liabilityDDB.balanceDueTodayLocal
          delete liabilityDDB.balanceDueTodayApp
          delete liabilityDDB.balanceDueTodayAppPercent
          delete liabilityDDB.repayedTodayLocal
          delete liabilityDDB.repayedTodayLocalPercent
          // Update (PUT) liability in DDB (always tied to primary client ID)
          let path = '/clients/ledger/liability/' + getters.clientIdPrimary
          let myinit = {
            body: liabilityDDB
          }
          API.put('Falcon9API', path, myinit)
            .then(event => {
              console.log('SUCCESS - API updated liability in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to update liability in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  deleteLiability({ commit, dispatch, getters }, liability) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this liability only
      LedgerService.computeLiability(liability)
        // delete liability, delete from state and update all existing liabilities accordingly
        .then(liability => commit('DELETE_LIABILITY', liability))
        .then(() => commit('CALCULATE_LIABILITIES_SUM'))
        .then(() => commit('CALCULATE_LIABILITIES_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before update to DDB
          const liabilityDDB = Object.assign({}, liability)
          delete liabilityDDB.balanceDueTodayLocal
          delete liabilityDDB.balanceDueTodayApp
          delete liabilityDDB.balanceDueTodayAppPercent
          delete liabilityDDB.repayedTodayLocal
          delete liabilityDDB.repayedTodayLocalPercent
          // Delete (DEL) liability in DDB (always tied to primary client ID)
          let path = '/clients/ledger/liability/' + getters.clientIdPrimary
          let myinit = {
            body: liabilityDDB
          }
          API.del('Falcon9API', path, myinit)
            .then(event => {
              console.log('SUCCESS - API deleted liability in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to delete liability in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  // Income Source Actions
  createIncomeSource({ commit, dispatch, getters }, incomeSource) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this incomeSource only
      LedgerService.computeIncomeSource(incomeSource)
        // add incomeSource to state and update all existing incomeSources accordingly
        .then(incomeSource => commit('CREATE_INCOME_SOURCE', incomeSource))
        .then(() => commit('CALCULATE_INCOME_SOURCES_SUM'))
        .then(() => commit('CALCULATE_INCOME_SOURCES_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before post to DDB
          const incomeSourceDDB = Object.assign({}, incomeSource)
          delete incomeSourceDDB.incomePaTodayApp
          delete incomeSourceDDB.incomePaTodayAppPercent
          // create (Post) incomeSource to DDB (always tied to primary client ID)
          let path = '/clients/ledger/income/' + getters.clientIdPrimary
          let myinit = {
            body: incomeSourceDDB
          }
          API.post('Falcon9API', path, myinit)
            .then(event => {
              console.log(
                'SUCCESS - API created new incomeSource in DDB',
                event
              )
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to create new incomeSource in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  updateIncomeSource({ commit, dispatch, getters }, incomeSource) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this incomeSource only
      LedgerService.computeIncomeSource(incomeSource)
        // update incomeSource, add to state and update all existing incomeSources accordingly
        .then(incomeSource => commit('UPDATE_INCOME_SOURCE', incomeSource))
        .then(() => commit('CALCULATE_INCOME_SOURCES_SUM'))
        .then(() => commit('CALCULATE_INCOME_SOURCES_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before update to DDB
          const incomeSourceDDB = Object.assign({}, incomeSource)
          delete incomeSourceDDB.incomePaTodayApp
          delete incomeSourceDDB.incomePaTodayAppPercent
          // Update (PUT) incomeSource in DDB (always tied to primary client ID)
          let path = '/clients/ledger/income/' + getters.clientIdPrimary
          let myinit = {
            body: incomeSourceDDB
          }
          API.put('Falcon9API', path, myinit)
            .then(event => {
              console.log('SUCCESS - API updated incomeSource in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to update incomeSource in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  deleteIncomeSource({ commit, dispatch, getters }, incomeSource) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this incomeSource only
      LedgerService.computeIncomeSource(incomeSource)
        // delete incomeSource, delete from state and update all existing incomeSources accordingly
        .then(incomeSource => commit('DELETE_INCOME_SOURCE', incomeSource))
        .then(() => commit('CALCULATE_INCOME_SOURCES_SUM'))
        .then(() => commit('CALCULATE_INCOME_SOURCES_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before update to DDB
          const incomeSourceDDB = Object.assign({}, incomeSource)
          delete incomeSourceDDB.incomePaTodayApp
          delete incomeSourceDDB.incomePaTodayAppPercent
          // Delete (DEL) incomeSource in DDB (always tied to primary client ID)
          let path = '/clients/ledger/income/' + getters.clientIdPrimary
          let myinit = {
            body: incomeSourceDDB
          }
          API.del('Falcon9API', path, myinit)
            .then(event => {
              console.log('SUCCESS - API deleted incomeSource in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to delete incomeSource in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  // Spending Stream Actions
  createSpendingStream({ commit, dispatch, getters }, spendingStream) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this spendingStream only
      LedgerService.computeSpendingStream(spendingStream)
        // add spendingStream to state and update all existing spendingStreams accordingly
        .then(spendingStream =>
          commit('CREATE_SPENDING_STREAM', spendingStream)
        )
        .then(() => commit('CALCULATE_SPENDING_STREAMS_SUM'))
        .then(() => commit('CALCULATE_SPENDING_STREAMS_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before post to DDB
          const spendingStreamDDB = Object.assign({}, spendingStream)
          delete spendingStreamDDB.spendingPaTodayApp
          delete spendingStreamDDB.spendingPaTodayAppPercent
          // create (Post) spendingStream to DDB (always tied to primary client ID)
          let path = '/clients/ledger/spending/' + getters.clientIdPrimary
          let myinit = {
            body: spendingStreamDDB
          }
          API.post('Falcon9API', path, myinit)
            .then(event => {
              console.log(
                'SUCCESS - API created new spendingStream in DDB',
                event
              )
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to create new spendingStream in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  updateSpendingStream({ commit, dispatch, getters }, spendingStream) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this spendingStream only
      LedgerService.computeSpendingStream(spendingStream)
        // update spendingStream, add to state and update all existing spendingStreams accordingly
        .then(spendingStream =>
          commit('UPDATE_SPENDING_STREAM', spendingStream)
        )
        .then(() => commit('CALCULATE_SPENDING_STREAMS_SUM'))
        .then(() => commit('CALCULATE_SPENDING_STREAMS_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before update to DDB
          const spendingStreamDDB = Object.assign({}, spendingStream)
          delete spendingStreamDDB.spendingPaTodayApp
          delete spendingStreamDDB.spendingPaTodayAppPercent
          // Update (PUT) spendingStream in DDB (always tied to primary client ID)
          let path = '/clients/ledger/spending/' + getters.clientIdPrimary
          let myinit = {
            body: spendingStreamDDB
          }
          API.put('Falcon9API', path, myinit)
            .then(event => {
              console.log('SUCCESS - API updated spendingStream in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to update spendingStream in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  deleteSpendingStream({ commit, dispatch, getters }, spendingStream) {
    return new Promise((resolve, reject) => {
      // calculate Todays Attributes for this spendingStream only
      LedgerService.computeSpendingStream(spendingStream)
        // delete spendingStream, delete from state and update all existing spendingStreams accordingly
        .then(spendingStream =>
          commit('DELETE_SPENDING_STREAM', spendingStream)
        )
        .then(() => commit('CALCULATE_SPENDING_STREAMS_SUM'))
        .then(() => commit('CALCULATE_SPENDING_STREAMS_PERCENTAGE'))
        .then(() => {
          // strip down unneccessary attributes before update to DDB
          const spendingStreamDDB = Object.assign({}, spendingStream)
          delete spendingStreamDDB.spendingPaTodayApp
          delete spendingStreamDDB.spendingPaTodayAppPercent
          // Delete (DEL) spendingStream in DDB (always tied to primary client ID)
          let path = '/clients/ledger/spending/' + getters.clientIdPrimary
          let myinit = {
            body: spendingStreamDDB
          }
          API.del('Falcon9API', path, myinit)
            .then(event => {
              console.log('SUCCESS - API deleted spendingStream in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to delete spendingStream in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  // Goal Actions
  createGoal({ commit, dispatch, getters }, goal) {
    return new Promise((resolve, reject) => {
      // calculate Attributes (descriptions, etc.) for this goal only
      LedgerService.computeGoal(goal).then(goal => {
        // copy current data into proposed data to be used as initial financial planning starting point
        goal.proposedData = Object.assign({}, goal.currentData)
        // create (Post) goal to DDB (always tied to primary client ID)
        let path = '/clients/ledger/goal/' + getters.clientIdPrimary
        let myinit = {
          body: goal
        }
        API.post('Falcon9API', path, myinit)
          .then(event => {
            commit('CREATE_GOAL', goal)
            console.log('SUCCESS - API created new goal in DDB', event)
            resolve()
          })
          .catch(err => {
            console.log(
              'ERROR - API failed to create new goal in DDB',
              err.response
            )
            const notification = {
              type: 'error',
              message: err.message
            }
            dispatch('addNotification', notification)
            reject()
          })
      })
    })
  },

  updateGoal({ commit, dispatch, getters }, goal) {
    return new Promise((resolve, reject) => {
      // Process currentData Attributes (descriptions, etc.) for this goal only
      LedgerService.computeGoal(goal)
        // Process proposedData Attributes (descriptions, etc.) for this goal only
        .then(goal => FinancialPlanService.computeGoal(goal))
        // Update (PUT) goal in DDB (always tied to primary client ID)
        .then(goal => {
          let path = '/clients/ledger/goal/' + getters.clientIdPrimary
          let myinit = {
            body: goal
          }
          API.put('Falcon9API', path, myinit)
            .then(event => {
              // Update Goal (current and proposedData) in state
              commit('UPDATE_GOAL', goal)
              console.log('SUCCESS - API updated goal in DDB', event)
              resolve()
            })
            .catch(err => {
              console.log(
                'ERROR - API failed to update goal in DDB',
                err.response
              )
              const notification = {
                type: 'error',
                message: err.message
              }
              dispatch('addNotification', notification)
              reject()
            })
        })
    })
  },

  deleteGoal({ commit, dispatch, getters }, goal) {
    return new Promise((resolve, reject) => {
      // Delete (DEL) goal in DDB (always tied to primary client ID)
      let path = '/clients/ledger/goal/' + getters.clientIdPrimary
      let myinit = {
        body: goal
      }
      API.del('Falcon9API', path, myinit)
        .then(event => {
          commit('DELETE_GOAL', goal)
          console.log('SUCCESS - API deleted goal in DDB', event)
          resolve()
        })
        .catch(err => {
          console.log('ERROR - API failed to delete goal in DDB', err.response)
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  // Conditions Actions
  updateConditions({ dispatch, getters }) {
    return new Promise((resolve, reject) => {
      let path = '/clients/ledger/conditions/' + getters.clientIdPrimary
      let myinit = {
        body: state.ledger.conditions
      }
      API.put('Falcon9API', path, myinit)
        .then(() => {
          dispatch('fetchClientLedger').then(() => {
            console.log('SUCCESS - API updated conditions')
            resolve()
          })
        })
        .catch(err => {
          console.log('ERROR - API failed to update conditions', err.response)
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  }
}

export const getters = {
  clientLedgerGrouped(state) {
    let index = 0
    let itemsGrouped = []
    let singleAssets = []
    // deep copy each ledger array to prevent direct state modification when adding index attribute
    let assetsCopied = JSON.parse(JSON.stringify(state.ledger.assets))
    let liabilitiesCopied = JSON.parse(JSON.stringify(state.ledger.liabilities))
    let incomeCopied = JSON.parse(JSON.stringify(state.ledger.incomeSources))
    let spendingCopied = JSON.parse(
      JSON.stringify(state.ledger.spendingStreams)
    )
    Object.entries(assetsCopied).forEach(([, asset]) => {
      let assetSingle = true
      Object.entries(liabilitiesCopied).forEach(([, liability]) => {
        if (asset.id == liability.idRelated) {
          asset.index = index++
          itemsGrouped.push(asset)
          assetSingle = false
          liability.index = index++
          itemsGrouped.push(liability)
        }
      })
      Object.entries(incomeCopied).forEach(([, income]) => {
        if (asset.id == income.idRelated) {
          if (assetSingle == true) {
            asset.index = index++
            itemsGrouped.push(asset)
            assetSingle = false
          }
          income.index = index++
          itemsGrouped.push(income)
        }
      })
      Object.entries(spendingCopied).forEach(([, expense]) => {
        if (asset.id == expense.idRelated) {
          if (assetSingle == true) {
            asset.index = index++
            itemsGrouped.push(asset)
            assetSingle = false
          }
          expense.index = index++

          itemsGrouped.push(expense)
        }
      })
      if (assetSingle == true) {
        singleAssets.push(asset)
      }
    })
    Object.entries(singleAssets).forEach(([, asset]) => {
      asset.index = index++
      itemsGrouped.push(asset)
    })
    Object.entries(liabilitiesCopied).forEach(([, liability]) => {
      if (liability.idRelated == -1) {
        liability.index = index++
        itemsGrouped.push(liability)
      }
    })
    Object.entries(incomeCopied).forEach(([, income]) => {
      if (income.idRelated == -1) {
        income.index = index++
        itemsGrouped.push(income)
      }
    })
    Object.entries(spendingCopied).forEach(([, spending]) => {
      if (spending.idRelated == -1) {
        spending.index = index++
        itemsGrouped.push(spending)
      }
    })
    return itemsGrouped
  },
  clientAssets(state) {
    return state.ledger.assets
  },
  clientAssetsName(state) {
    return state.ledger.assetsName
  },
  clientAssetsSum(state) {
    return state.ledger.assetsSum
  },
  clientLiabilities(state) {
    return state.ledger.liabilities
  },
  clientLiabilitiesSum(state) {
    return state.ledger.liabilitiesSum
  },
  clientIncomeSources(state) {
    return state.ledger.incomeSources
  },
  clientIncomeSourcesSum(state) {
    return state.ledger.incomeSourcesSum
  },
  clientSpendingStreams(state) {
    return state.ledger.spendingStreams
  },
  clientSpendingStreamsSum(state) {
    return state.ledger.spendingStreamsSum
  },
  clientGoals(state) {
    return state.ledger.goals
  },
  clientAdvice(state) {
    return state.ledger.advice
  },
  clientExtraSavingsMaxSum(state) {
    let sum = state.ledger.incomeSourcesSum - state.ledger.spendingStreamsSum
    return sum
  },
  currencyApp(state) {
    return state.ledger.conditions.accountCurrency
  }
}
