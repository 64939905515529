<template>
  <div v-if="userRoleAdvisor">
    <v-app id="falcon9" class="mx-n4">
      <v-app-bar color="white" height="65" flat clipped app>
        <v-app-bar-nav-icon class="d-md-none" @click.stop="drawer = !drawer" />
        <v-toolbar-title style="width: 250px" class="mt-2">
          <span class="primary--text display-1 font-weight-light">Ledger</span
          ><span class="success--text display-1 font-weight-normal">5</span>
        </v-toolbar-title>

        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="success"
          centered
          class="secondary--text d-none d-md-block mb-4"
        >
          <template v-for="(line, index) in item.content">
            <v-menu
              offset-y
              open-on-hover
              bottom
              origin="center center"
              transition="slide-y-transition"
              :key="index"
            >
              <template v-slot:activator="{ on }">
                <v-tab
                  v-if="line.children"
                  v-model="tab"
                  link
                  :key="index"
                  v-on="on"
                  class="primary--text text-uppercase subtitle-2 mt-8"
                >
                  {{ line.text }}
                </v-tab>

                <v-tab
                  v-else
                  v-model="tab"
                  link
                  :key="index"
                  @click="setThis(line.page)"
                  class="primary--text text-uppercase subtitle-2 mt-8"
                >
                  {{ line.text }}
                </v-tab>
              </template>

              <v-list v-if="line.children" dense class="grey lighten-5">
                <v-list-item
                  v-for="(child, indexChild) in line.children"
                  :key="indexChild"
                  link
                  @click="setThis(child.page)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="primary--text text-uppercase">
                      {{ child.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-tabs>

        <v-spacer />

        <v-menu
          offset-y
          open-on-hover
          bottom
          origin="center center"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              outlined
              large
              v-on="on"
              class="success--text mt-3 mr-0"
            >
              <v-icon color="success">mdi-account-circle</v-icon>
            </v-btn>
          </template>

          <v-list dense class="grey lighten-5">
            <v-list-item>
              <v-list-item-title class="#31465F--text font-weight-light"
                >APP-Mode: ADVISOR
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :to="'/advisor/profile'">
              <v-list-item-title>Profile & Settings</v-list-item-title>
            </v-list-item>
            <div v-if="userRoleAdministrator">
              <v-list-item :to="'/admin/advisors'">
                <v-list-item-title>Administration</v-list-item-title>
              </v-list-item>
            </div>
            <v-divider></v-divider>
            <v-list-item @click="logoutUser">
              <v-list-item-title class="error--text"
                >SIGN OUT</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" clipped temporary app>
        <v-list>
          <template v-for="line in item.content">
            <v-list-group
              v-if="line.children"
              color="success"
              :key="line.text"
              v-model="line.model"
              :prepend-icon="line.model ? line.icon : line['icon-alt']"
              append-icon=""
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    <h4>{{ line.text }}</h4>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(child, i) in line.children"
                :key="i"
                link
                @click="setThis(child.page)"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon color="primary">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="line.text"
              color="success"
              link
              @click="setThis(line.page)"
            >
              <v-list-item-action>
                <v-icon color="primary">{{ line.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="primary--text">
                  {{ line.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid>
          <slot></slot>

          <v-footer color="advisorLayout" dark fixed style="height: 25px;"
            ><div class="caption mt-n1">
              &copy; 2018 - {{ new Date().getFullYear() }}
            </div>
            <div class="caption advisorLayout--text mt-n1">----</div>
            <div class="caption mt-n1">powered by Ledger</div>
            <div class="caption success--text font-weight-bold mt-n1">5</div>
            <v-spacer />
          </v-footer>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <div v-else>
    <template>
      <v-card width="400" class="mx-auto mt-10">
        <v-toolbar color="primary lighten-1 white--text">
          <v-toolbar-title>
            <h1 class="display-1">
              Welcome ...
            </h1>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-card-actions>
              <v-btn color="info" small :to="`/login`">Login</v-btn>
              <v-spacer></v-spacer>
              <!-- <v-btn color="success" small :to="`/register`">Register</v-btn> -->
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'theAdvisorLayout',
  data() {
    return {
      drawer: null,
      item: {
        content: [
          {
            text: 'Dashboard',
            icon: 'mdi-gauge',
            page: { name: 'advisorDashboard' }
          },
          {
            text: 'Definitions',
            // icon: 'mdi-clipboard-list-outline',
            icon: 'mdi-chevron-up',
            'icon-alt': 'mdi-chevron-down',
            model: false,
            page: { name: 'advisorDefinitions', params: { tabs: 'assets' } },
            children: [
              {
                text: 'Assets',
                page: {
                  name: 'advisorDefinitions',
                  params: { tabs: 'assets' }
                }
              },
              {
                text: 'Liabilities',
                page: {
                  name: 'advisorDefinitions',
                  params: { tabs: 'liabilities' }
                }
              },
              {
                text: 'Income',
                page: {
                  name: 'advisorDefinitions',
                  params: { tabs: 'income' }
                }
              },
              {
                text: 'Expenses',
                page: {
                  name: 'advisorDefinitions',
                  params: { tabs: 'expenses' }
                }
              },
              {
                text: 'Model-Portfolios',
                page: {
                  name: 'advisorDefinitions',
                  params: { tabs: 'modelportfolios' }
                }
              }
            ]
          },
          {
            text: 'SoA',
            icon: 'mdi-clipboard-pulse',
            page: ''
          },
          {
            text: 'Settings',
            icon: 'mdi-cog',
            page: { name: 'advisorProfile' }
          }
        ]
      }
    }
  },

  methods: {
    logoutUser() {
      Auth.signOut({ global: true }) // revokes all JWT tokens for the user
        .then(() => {
          // console.log('LOGOUT:', data)
          this.$store.dispatch('logout')
        })
        .catch(err => {
          // console.log('LOGOUT FAILED:', err)
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        })
    },

    setThis(page) {
      // check if there is a page to go to, and if the page is different to the current one, then execute the push
      if (page != '' && page.fullPath != this.$route.fullPath) {
        // check if target is advisorDefinitions and move carousel to appropriate place
        if (page.name == 'advisorDefinitions') {
          this.$store.commit('SET_ADVISOR_DEFINITIONS_CARO', page.params.tabs)
        }
        this.$router.push(page)
      }
    }
  },

  computed: {
    userRoleAdvisor() {
      return (
        (this.loggedIn && !!(this.role === 'TenAdm')) ||
        (this.loggedIn && !!(this.role === 'TenAdv'))
      )
    },

    userRoleAdministrator() {
      return this.loggedIn && !!(this.role === 'TenAdm')
    },

    search: {
      get() {
        return this.clients.search
      },
      set(attributes) {
        this.$store.commit('SET_CLIENTS_SEARCH', attributes)
      }
    },

    tab: {
      get() {
        return this.system.tabAdvisorLayout
      },
      set(attributes) {
        this.$store.commit('SET_ADVISOR_LAYOUT_TAB', attributes)
      }
    },

    ...mapState(['auth', 'client', 'clients', 'system']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
