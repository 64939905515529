import store from '@/vuex/store'
import SystemService from '@/services/SystemService.js'
import StaticDataService from '@/services/StaticDataService.js'

// Asset calculations
function calculateAssetCost(asset) {
  return new Promise(resolve => {
    if (
      (asset.costValueLocal == '' || asset.costDate == '') &&
      asset.class != 'Pension Scheme'
    ) {
      asset.costValueLocal = asset.priceValueLocal
      asset.costDate = asset.priceDate
    }
    resolve(asset)
  })
}

function calculateAssetPrice(asset) {
  return new Promise(resolve => {
    if (asset.class == 'Precious Metal') {
      // retrieve asset code
      let item
      for (item of StaticDataService.getCategoryPreciousMetal()) {
        if (item.text == asset.catText) {
          asset.catCode = item.value
        }
      }
      // get spot price today and convert in local currency
      let num =
        asset.unit *
        asset.amount *
        store.getters.fxRateToday[asset.catCode] *
        store.getters.fxRateToday[asset.currencyLocal]
      asset.priceValueLocal = Math.floor(num * 100) / 100
      asset.priceDate = SystemService.dateToday()
      resolve(asset)
    } else if (asset.class == 'Crypto Currency') {
      // retrieve asset code
      let item
      for (item of StaticDataService.getCategoryCryptoCurrency()) {
        if (item.text == asset.catText) {
          asset.catCode = item.value
        }
      }
      // get crypto price today and convert in local currency
      let num =
        (asset.amount / store.getters.fxRateToday[asset.catCode]) *
        store.getters.fxRateToday[asset.currencyLocal]
      asset.priceValueLocal = Math.floor(num * 100) / 100
      asset.priceDate = SystemService.dateToday()
      resolve(asset)
    } else {
      resolve(asset)
    }
  })
}

function calculateAssetAttributesToday(asset) {
  return new Promise(resolve => {
    if (asset.class == 'Pension Scheme') {
      // calculate todays local balance from inception
      if (asset.priceValueLocal == null || asset.priceValueLocal == '') {
        // calulate decimal number of years from inception until today
        let yearsInit =
          SystemService.getNumberOfDays(
            SystemService.dateToday(),
            asset.pensionOpeningDate
          ) / 365
        console.log('Number of years = ', yearsInit)

        // calculate compound interest for opening amount
        let subtotal1 =
          asset.pensionOpeningValueLocal *
          (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
            (asset.pensionRegularPeriod * yearsInit)
        console.log('initial subtotal1 today=', subtotal1)

        // calculate future value of a series (regular payments)
        let subtotal2 =
          asset.pensionRegularContributionLocal *
          (((1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
            (asset.pensionRegularPeriod * yearsInit) -
            1) /
            (asset.growthRate / 100 / asset.pensionRegularPeriod)) *
          (1 + asset.growthRate / 100 / asset.pensionRegularPeriod)
        console.log('initial subtotal2 today=', subtotal2)

        // calculate compound interest for extra contributions
        let subtotal3 = 0
        if (asset.pensionExtraContribution) {
          Object.entries(asset.pensionExtraContribution).forEach(
            ([, contribution]) => {
              let yearsExtra =
                SystemService.getNumberOfDays(
                  SystemService.dateToday(),
                  contribution.date
                ) / 365
              let contributionTotal =
                contribution.amount *
                (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
                  (asset.pensionRegularPeriod * yearsExtra)

              subtotal3 += contributionTotal
            }
          )
        }
        console.log('initial subtotal3 today=', subtotal3)

        let total = subtotal1 + subtotal2 + subtotal3
        asset.priceTodayLocal = Number(total.toFixed(0))
      }
      // calculate todays local balance from valuation date until today
      else {
        // calculate future value of a series (regular payments) from valuation date until today
        let yearsVal =
          SystemService.getNumberOfDays(
            SystemService.dateToday(),
            asset.priceDate
          ) / 365

        // calculate compound interest for valuation amount
        let subtotal1 =
          asset.priceValueLocal *
          (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
            (asset.pensionRegularPeriod * yearsVal)
        console.log('valuation subtotal1 today=', subtotal1)

        let subtotal2 =
          asset.pensionRegularContributionLocal *
          (((1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
            (asset.pensionRegularPeriod * yearsVal) -
            1) /
            (asset.growthRate / 100 / asset.pensionRegularPeriod)) *
          (1 + asset.growthRate / 100 / asset.pensionRegularPeriod)
        console.log('valuation subtotal2 today=', subtotal2)

        // calculate compound interest for extra contributions from valuation date until today
        let subtotal3 = 0
        if (asset.pensionExtraContribution) {
          Object.entries(asset.pensionExtraContribution).forEach(
            ([, contribution]) => {
              // check if contribution was post valuation (days value = positive)
              let days = SystemService.getNumberOfDays(
                contribution.date,
                asset.priceDate
              )
              console.log('days = ', days)
              if (days >= 1) {
                let yearsExtra =
                  SystemService.getNumberOfDays(
                    SystemService.dateToday(),
                    contribution.date
                  ) / 365
                let contributionTotal =
                  contribution.amount *
                  (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
                    (asset.pensionRegularPeriod * yearsExtra)

                subtotal3 += contributionTotal
              }
            }
          )
        }
        console.log('valuation subtotal3 today=', subtotal3)

        // add valuations
        let total = subtotal1 + subtotal2 + subtotal3
        asset.priceTodayLocal = Number(total.toFixed(0))
      }
    } else {
      // Calculate todays local balance (growthRate incl. compound interest from valuation date until today)
      let num1 =
        asset.priceValueLocal *
        Math.pow(
          1 + asset.growthRate / 100,
          SystemService.getNumberOfDays(
            SystemService.dateToday(),
            asset.priceDate
          ) / 365
        )
      asset.priceTodayLocal = Math.floor(num1 * 100) / 100
    }

    // Calculate Asset Price today in App currency (1st into NZD and then from there in App currency)
    let num2 =
      (asset.priceTodayLocal / store.getters.fxRateToday[asset.currencyLocal]) *
      store.getters.fxRateToday[store.getters.currencyApp]
    asset.priceTodayApp = Math.floor(num2 * 100) / 100

    // Calculate Assets gain today in absolut numbers (in local currency)
    let num3 = asset.priceTodayLocal - asset.costValueLocal
    asset.gainTodayLocal = Math.floor(num3 * 100) / 100

    // Calculate Assets gain in percent from purchase price (based on local currency figures)
    let num4 = (asset.priceTodayLocal * 100) / asset.costValueLocal - 100
    asset.gainTodayLocalPercent = Math.floor(num4 * 100) / 100

    resolve(asset)
  })
}

function calculateAssetProjection(asset) {
  return new Promise(resolve => {
    // Calculate static time series projection in local currency for the comming years
    if (asset.class == 'Pension Scheme' && asset.maturityOption == 'lumpsum') {
      // Valuation takes place on 31. December each and eyery year, only final year represents lumpsum payed at maturity date
      let projection = { year: '', data: [] }
      if (asset.priceValueLocal != '') {
        // calculate from valuation to maturity
        projection.year = asset.priceDate.substring(0, 4)

        ///// calculate asset price at the end of the first fractional year /////

        // calulate decimal number of years from valuation until 31.12.
        let yearsInit =
          SystemService.getNumberOfDays(
            asset.priceDate.substring(0, 4) + '-12-31',
            asset.priceDate
          ) / 365
        // console.log('Number of yearsInit = ', yearsInit)

        // calculate compound interest for valuation amount
        let subtotal1 =
          asset.priceValueLocal *
          (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
            (asset.pensionRegularPeriod * yearsInit)
        // console.log('initial subtotal1 proj=', subtotal1)

        // calculate future value of a series (regular payments)
        let subtotal2 =
          asset.pensionRegularContributionLocal *
          (((1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
            (asset.pensionRegularPeriod * yearsInit) -
            1) /
            (asset.growthRate / 100 / asset.pensionRegularPeriod)) *
          (1 + asset.growthRate / 100 / asset.pensionRegularPeriod)
        // console.log('initial subtotal2  proj=', subtotal2)

        // calculate compound interest for extra contributions
        let subtotal3 = 0
        if (asset.pensionExtraContribution) {
          Object.entries(asset.pensionExtraContribution).forEach(
            ([, contribution]) => {
              // check if extra contribution was in current year
              if (
                asset.priceDate.substring(0, 4) ==
                contribution.date.substring(0, 4)
              ) {
                let yearsExtra =
                  SystemService.getNumberOfDays(
                    Number(asset.priceDate.substring(0, 4)).toString() +
                      '-12-31',
                    contribution.date
                  ) / 365
                let contributionTotal =
                  contribution.amount *
                  (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
                    (asset.pensionRegularPeriod * yearsExtra)

                subtotal3 += contributionTotal
              }
            }
          )
        }
        console.log('valuation subtotal3  proj=', subtotal3)

        // add valuations for first fractional year (valuation year)
        let total1 = subtotal1 + subtotal2 + subtotal3
        projection.data.push(Number(total1.toFixed(0)))

        ///// calculate the "whole" years up to the year before maturity year /////

        // loop through the "whole" years (start from year after valuation year until year before maturity year)
        // let balance = projection.data[0]
        for (
          let year = Number(asset.priceDate.substring(0, 4)) + 1;
          year < Number(asset.maturityDate.substring(0, 4));
          year++
        ) {
          // calculate compound interest for balance amount
          console.log(
            'balance data =',
            projection.data[
              year - (Number(asset.priceDate.substring(0, 4)) + 1)
            ]
          )
          console.log('year =', year)
          let subtotal4 =
            projection.data[
              year - (Number(asset.priceDate.substring(0, 4)) + 1)
            ] *
            (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
              (asset.pensionRegularPeriod * 1)
          // console.log('whole year subtotal4 proj=', subtotal4)

          // calculate future value of a series (regular payments)
          let subtotal5 =
            asset.pensionRegularContributionLocal *
            (((1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
              (asset.pensionRegularPeriod * 1) -
              1) /
              (asset.growthRate / 100 / asset.pensionRegularPeriod)) *
            (1 + asset.growthRate / 100 / asset.pensionRegularPeriod)
          // console.log('whole year subtotal5 proj=', subtotal5)

          // calculate compound interest for extra contributions
          let subtotal6 = 0
          if (asset.pensionExtraContribution) {
            Object.entries(asset.pensionExtraContribution).forEach(
              ([, contribution]) => {
                // check if extra contribution was in current year
                if (year == contribution.date.substring(0, 4)) {
                  let yearsExtra =
                    SystemService.getNumberOfDays(
                      year + '-12-31',
                      contribution.date
                    ) / 365
                  let contributionTotal =
                    contribution.amount *
                    (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
                      (asset.pensionRegularPeriod * yearsExtra)

                  subtotal6 += contributionTotal
                }
              }
            )
          }
          console.log('whole year subtotal6 proj =', subtotal6)

          let total2 = subtotal4 + subtotal5 + subtotal6
          projection.data.push(Number(total2.toFixed(0)))
        }

        ///// calculate asset price at the end of the last fractional year /////

        // calulate decimal number of years from 1.1. until maturity date
        let yearsLast =
          SystemService.getNumberOfDays(
            asset.maturityDate,
            asset.maturityDate.substring(0, 4) + '-01-01'
          ) / 365
        // console.log('Number of yearsLast = ', yearsLast)

        // calculate compound interest for balance
        let subtotal7 =
          projection.data[projection.data.length - 1] *
          (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
            (asset.pensionRegularPeriod * yearsLast)
        // console.log('initial subtotal7 proj =', subtotal7)

        // calculate future value of a series (regular payments)
        let subtotal8 =
          asset.pensionRegularContributionLocal *
          (((1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
            (asset.pensionRegularPeriod * yearsLast) -
            1) /
            (asset.growthRate / 100 / asset.pensionRegularPeriod)) *
          (1 + asset.growthRate / 100 / asset.pensionRegularPeriod)
        // console.log('initial subtotal8 proj =', subtotal8)

        // calculate compound interest for extra contributions
        let subtotal9 = 0
        if (asset.pensionExtraContribution) {
          Object.entries(asset.pensionExtraContribution).forEach(
            ([, contribution]) => {
              // check if extra contribution was in current year
              if (
                asset.maturityDate.substring(0, 4) ==
                contribution.date.substring(0, 4)
              ) {
                let yearsExtra =
                  SystemService.getNumberOfDays(
                    asset.maturityDate,
                    contribution.date
                  ) / 365
                let contributionTotal =
                  contribution.amount *
                  (1 + asset.growthRate / 100 / asset.pensionRegularPeriod) **
                    (asset.pensionRegularPeriod * yearsExtra)

                subtotal9 += contributionTotal
              }
            }
          )
        }
        // console.log('valuation subtotal9 proj =', subtotal9)

        // add valuations for last fractional year (maturity year)
        let total3 = subtotal7 + subtotal8 + subtotal9
        projection.data.push(Number(total3.toFixed(0)))
      } else {
        // calculate from inception to maturity
        projection.year = asset.pensionOpeningDate.substring(0, 4)
      }
      asset.projection = projection
    } else {
      // Valuation takes place on 31. December each and eyery year
      // first year projection is the year of price valuation, extracted out of priceDate
      let projection = { year: asset.priceDate.substring(0, 4), data: [] }

      // calculate asset price at the end of the first fractional year (valuation year is year of priceDate)
      let num1 =
        asset.priceValueLocal *
        Math.pow(
          1 + asset.growthRate / 100,
          SystemService.getNumberOfDays(
            asset.priceDate.substring(0, 4) + '-12-31',
            asset.priceDate
          ) / 365
        )
      projection.data.push(Number(num1.toFixed(0)))

      // calculate the following 99 years
      for (let years = 1; years < 100; years++) {
        let num2 = num1 * (1 + asset.growthRate / 100) ** years
        projection.data.push(Number(num2.toFixed(0)))
      }
      asset.projection = projection
    }
    resolve(asset)
  })
}

// Liability calculations
function calculateLiabilityAttributesToday(liability) {
  return new Promise(resolve => {
    // Calculate Liability Balance Due Today Local
    let repayedAmount = 0
    // frequent principal payments
    if (liability.repaymentOption == 'principal') {
      var days = SystemService.getNumberOfDays(
        SystemService.dateToday(),
        liability.closingDate
      )
      repayedAmount +=
        (days * liability.period * liability.principalValueLocal) / 365
    }
    // extra principal payments
    if (liability.extraPrincipal) {
      Object.entries(liability.extraPrincipal).forEach(([, principal]) => {
        repayedAmount += principal.amount
      })
    }
    // calculate balance
    liability.balanceDueTodayLocal =
      Math.floor((liability.closingValueLocal - repayedAmount) * 100) / 100

    // Calculate Liability repayed today local
    liability.repayedTodayLocal =
      liability.closingValueLocal - liability.balanceDueTodayLocal

    // Calculate Liability repayed today local in percent
    let num2 = (liability.repayedTodayLocal * 100) / liability.closingValueLocal
    liability.repayedTodayLocalPercent = Math.floor(num2 * 100) / 100

    // Calculate Liability Balance Due Today in App currency (1st into NZD and then from there in App currency)
    let num3 =
      (liability.balanceDueTodayLocal /
        store.getters.fxRateToday[liability.currencyLocal]) *
      store.getters.fxRateToday[store.getters.currencyApp]
    liability.balanceDueTodayApp = Math.floor(num3 * 100) / 100

    resolve(liability)
  })
}

function calculateLiabilityProjection(liability) {
  return new Promise(resolve => {
    // Calculate time series projection in local currency for the comming 100 years
    // Balancing takes place on 31. December each and eyery year
    // Time series calculation starts allways on closing date !!!
    liability.projection = {
      year: liability.closingDate.substring(0, 4),
      data: []
    }

    // loop through years (start from closing year until maturity year)
    for (
      let year = liability.closingDate.substring(0, 4);
      year <= liability.maturityDate.substring(0, 4);
      year++
    ) {
      // check for regular and extra repayments every year and push balance into projection data
      let repayedAmountPeriod = 0
      let daysUntilEndOfPeriod = 0
      // frequent principal payments
      if (liability.repaymentOption == 'principal') {
        if (year < liability.maturityDate.substring(0, 4)) {
          daysUntilEndOfPeriod = SystemService.getNumberOfDays(
            year.toString() + '-12-31',
            liability.closingDate // start of period is always closing date
          )
        } else {
          daysUntilEndOfPeriod = SystemService.getNumberOfDays(
            liability.maturityDate, // end of period is maturity date, so total period in this case
            liability.closingDate // start of period is always closing date
          )
        }
        repayedAmountPeriod +=
          (daysUntilEndOfPeriod *
            liability.period *
            liability.principalValueLocal) /
          365
      }
      // extra principal payments
      if (liability.extraPrincipal) {
        Object.entries(liability.extraPrincipal).forEach(([, principal]) => {
          // if date is in range from closing date untill the end of the period
          if (
            SystemService.getNumberOfDays(
              principal.date,
              liability.closingDate // start of period is always closing date
            ) < daysUntilEndOfPeriod
          ) {
            repayedAmountPeriod += principal.amount
          }
        })
      }
      liability.projection.data.push(
        liability.closingValueLocal - Number(repayedAmountPeriod.toFixed(0))
      )
    }

    resolve(liability)
  })
}

// IncomeSources calculations
function calculateIncomeSourceAttributesToday(incomeSource) {
  return new Promise(resolve => {
    // Calculate income per anno in local currency
    let num1 = incomeSource.incomeLocal * incomeSource.payoutPeriod
    incomeSource.incomePaLocal = Math.floor(num1 * 100) / 100

    // Calculate income per anno in App currency
    let num2 =
      (incomeSource.incomePaLocal /
        store.getters.fxRateToday[incomeSource.currencyLocal]) *
      store.getters.fxRateToday[store.getters.currencyApp]
    incomeSource.incomePaTodayApp = Math.floor(num2 * 100) / 100

    resolve(incomeSource)
  })
}

// SpendingStreams calculations
function calculateSpendingStreamAttributesToday(spendingStream) {
  return new Promise(resolve => {
    // Calculate spendingStream per anno in local currency
    let num1 = spendingStream.spendingLocal * spendingStream.spendingPeriod
    spendingStream.spendingPaLocal = Math.floor(num1 * 100) / 100

    // Calculate spendingStream per anno in App currency
    let num2 =
      (spendingStream.spendingPaLocal /
        store.getters.fxRateToday[spendingStream.currencyLocal]) *
      store.getters.fxRateToday[store.getters.currencyApp]
    spendingStream.spendingPaTodayApp = Math.floor(num2 * 100) / 100

    resolve(spendingStream)
  })
}

// Goal Attribute generation
function generateGoalAttributes(goal) {
  return new Promise(resolve => {
    // Process Emergency-Fund Goal
    if (goal.type == 'Emergency-Fund') {
      goal.headline = 'Allocate Emergency-Fund'
      goal.currentData.description =
        goal.currentData.value == 0
          ? "According to Sorted (Commission for Financial Capability), a general rule suggests that you put three months' worth of expenses into an emergency fund. This allows you to stay ahead and acts as a self-insurance."
          : goal.currentData.value.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) +
            ' ' +
            store.getters.currencyApp +
            ' for financial emergencies'
      goal.currentData.descriptionEnhanced =
        goal.currentData.value == 0
          ? "According to Sorted (Commission for Financial Capability), a general rule suggests that you put three months' worth of expenses into an emergency fund. This allows you to stay ahead and acts as a self-insurance."
          : 'allocate ' +
            goal.currentData.value.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) +
            ' ' +
            store.getters.currencyApp +
            ' to be used in a financial emergency situation'
    }

    // Process Retirement Goal
    else if (goal.id == 2) {
      // Process individual Retirement Goal
      if (store.getters.clientProfileType == 'Individual') {
        // set the goal headline
        goal.currentData.headlinePerson1 =
          store.getters.clientPerson1.firstName + "'s " + 'Retirement'
        // set the retirement year for the person according to retirement age
        goal.currentData.yearPerson1 =
          Number(store.getters.clientPerson1.birthDate.substring(0, 4)) +
          goal.currentData.retirementAgePerson1

        // set the post retirement risk type year to the retirement year
        goal.currentData.postRetirementRiskTypeFromYear =
          goal.currentData.yearPerson1

        if (goal.currentData.retirementExpenseType == 'rate') {
          // generate personalized simple description
          goal.currentData.description =
            'at age ' +
            goal.currentData.retirementAgePerson1 +
            ', maint. ' +
            goal.currentData.retirementExpenseRate +
            '% of expenses'

          // generate personalized enhanced (per person) description
          goal.currentData.descriptionEnhancedPerson1 =
            store.getters.clientPerson1.firstName +
            ' retires at age ' +
            goal.currentData.retirementAgePerson1 +
            ' and maintains ' +
            goal.currentData.retirementExpenseRate +
            '% of the current expenses in Retirement, adjusted for inflation'
        } else {
          // generate personalized simple description
          goal.currentData.description =
            'at age ' +
            goal.currentData.retirementAgePerson1 +
            ', recieve ' +
            goal.currentData.retirementExpenseValue.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) +
            ' ' +
            store.getters.currencyApp +
            ' p.a.'

          // generate personalized enhanced (per person) description
          goal.currentData.descriptionEnhancedPerson1 =
            store.getters.clientPerson1.firstName +
            ' retires at age ' +
            goal.currentData.retirementAgePerson1 +
            ', while receiving income of ' +
            goal.currentData.retirementExpenseValue.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) +
            ' ' +
            store.getters.currencyApp +
            ' p.a., adjusted for inflation'
        }
      }

      // Process joint Retirement Goal
      else if (store.getters.clientProfileType == 'Joint') {
        // set the goal headline
        goal.currentData.headlinePerson1 =
          store.getters.clientPerson1.firstName + "'s " + 'Retirement'
        goal.currentData.headlinePerson2 =
          store.getters.clientPerson2.firstName + "'s " + 'Retirement'
        // set the retirement year for the persons according to retirement age
        goal.currentData.yearPerson1 =
          Number(store.getters.clientPerson1.birthDate.substring(0, 4)) +
          goal.currentData.retirementAgePerson1
        goal.currentData.yearPerson2 =
          Number(store.getters.clientPerson2.birthDate.substring(0, 4)) +
          goal.currentData.retirementAgePerson2

        // set the post retirement risk type year to the retirement year of the person, who retires first (earliest)
        let retYears = [
          { year: goal.currentData.yearPerson1 },
          { year: goal.currentData.yearPerson2 }
        ]
        retYears.sort(function(a, b) {
          return a.year - b.year
        })
        goal.currentData.postRetirementRiskTypeFromYear = retYears[0].year

        // generate personalized simple description
        goal.currentData.description =
          store.getters.clientPerson1.firstName +
          ' at age ' +
          goal.currentData.retirementAgePerson1 +
          ' / ' +
          store.getters.clientPerson2.firstName +
          ' at age ' +
          goal.currentData.retirementAgePerson2

        if (goal.currentData.retirementExpenseType == 'rate') {
          // generate personalized enhanced (per person) description for rated income
          goal.currentData.descriptionEnhancedPerson1 =
            store.getters.clientPerson1.firstName +
            ' retires at age ' +
            goal.currentData.retirementAgePerson1 +
            ', while maintaining ' +
            goal.currentData.retirementExpenseRate +
            '% of the current, combined expenses in Retirement, adjusted for inflation'
          goal.currentData.descriptionEnhancedPerson2 =
            store.getters.clientPerson2.firstName +
            ' retires at age ' +
            goal.currentData.retirementAgePerson2 +
            ', while maintaining ' +
            goal.currentData.retirementExpenseRate +
            '% of the current, combined expenses in Retirement, adjusted for inflation'
        } else {
          // generate personalized enhanced (per person) description for fixed income
          goal.currentData.descriptionEnhancedPerson1 =
            store.getters.clientPerson1.firstName +
            ' retires at age ' +
            goal.currentData.retirementAgePerson1 +
            ', while receiving a combined income of ' +
            goal.currentData.retirementExpenseValue.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) +
            ' ' +
            store.getters.currencyApp +
            ' p.a., adjusted for inflation'
          goal.currentData.descriptionEnhancedPerson2 =
            store.getters.clientPerson2.firstName +
            ' retires at age ' +
            goal.currentData.retirementAgePerson2 +
            ', while receiving a combined income of ' +
            goal.currentData.retirementExpenseValue.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) +
            ' ' +
            store.getters.currencyApp +
            ' p.a., adjusted for inflation'
        }
      }

      // in any case, if retirementExpenseType has changed in currentData, make sure to update proposedData as well!!
      if (
        goal.currentData.retirementExpenseType !=
        goal.proposedData.retirementExpenseType
      ) {
        // overwrite proposed Type
        goal.proposedData.retirementExpenseType =
          goal.currentData.retirementExpenseType
        // overwrite proposed ExpenseValue
        goal.proposedData.retirementExpenseValue =
          goal.currentData.retirementExpenseValue
        // overwrite proposed ExpenseRate
        goal.proposedData.retirementExpenseRate =
          goal.currentData.retirementExpenseRate
      }
    }

    // Process Purchase Goal
    else if (goal.type == 'Purchase' || goal.type == 'Education') {
      if (goal.currentData.recurring == true) {
        goal.headline = 'Recurring ' + goal.type + ' / ' + goal.name
        goal.currentData.description =
          'spend ' +
          goal.currentData.value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) +
          ' ' +
          store.getters.currencyApp +
          ' (recurring goal)'
      } else {
        goal.headline = goal.type + ' / ' + goal.name
        goal.currentData.description =
          'spend ' +
          goal.currentData.value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) +
          ' ' +
          store.getters.currencyApp
        goal.currentData.year = goal.targetYear
      }
    }

    // Process individual Upsize Goal
    else if (goal.type == 'Upsize') {
      Object.entries(store.getters.clientAssets).forEach(([, asset]) => {
        if (asset.id == goal.currentData.idRelated) {
          goal.name = asset.name
          goal.headline = goal.type + ' / ' + goal.name
          goal.currentData.description =
            'spend ' +
            goal.currentData.value.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) +
            ' ' +
            store.getters.currencyApp
          goal.currentData.year = goal.targetYear
        }
      })
    }

    // Process individual Downsize Goal
    else if (goal.type == 'Downsize' || goal.type == 'Sale') {
      Object.entries(store.getters.clientAssets).forEach(([, asset]) => {
        if (asset.id == goal.currentData.idRelated) {
          goal.name = asset.name
          goal.headline = goal.type + ' / ' + goal.name
          goal.currentData.description =
            'receive ' +
            goal.currentData.value.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) +
            ' ' +
            store.getters.currencyApp
          goal.currentData.year = goal.targetYear
        }
      })
    }

    // Process Debt Management Goal
    else if (goal.type == 'Debt Management') {
      Object.entries(store.getters.clientLiabilities).forEach(
        ([, liability]) => {
          if (liability.id == goal.currentData.idRelated)
            goal.name = liability.name
          if (goal.currentData.recurring == true) {
            goal.headline = 'Recurring ' + goal.type + ' / ' + goal.name
            goal.currentData.description =
              'spend ' +
              goal.currentData.value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) +
              ' ' +
              store.getters.currencyApp +
              ' on extra principal'
            goal.currentData.descriptionEnhanced =
              'spend ' +
              goal.currentData.value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) +
              ' ' +
              store.getters.currencyApp +
              ' on extra principal payment'
          } else {
            goal.headline = goal.type + ' / ' + goal.name
            goal.currentData.description =
              'spend ' +
              goal.currentData.value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) +
              ' ' +
              store.getters.currencyApp +
              ' on extra principal'
            goal.currentData.descriptionEnhanced =
              'spend ' +
              goal.currentData.value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) +
              ' ' +
              store.getters.currencyApp +
              ' on extra principal'
            goal.currentData.year = goal.targetYear
          }
        }
      )
    }

    // everything else (e.g. Custom Goal)
    else {
      goal.currentData.year = goal.targetYear
      goal.headline = goal.name
    }
    resolve(goal)
  })
}

// Process Handlers for the different Ledgers (Assets, Liabilities, IncomeSources and SpendingStreams)
function processAssets(ledger) {
  return new Promise(resolve => {
    Object.entries(ledger.response.assets).forEach(([, asset]) => {
      // add todays attributes to asset object
      asset.priceTodayLocal = 0
      asset.priceTodayApp = 0
      asset.priceTodayAppPercent = 0
      asset.gainTodayLocal = 0
      asset.gainTodayLocalPercent = 0
      // calculate todays basic attributes
      calculateAssetCost(asset)
        .then(asset => calculateAssetPrice(asset))
        .then(asset => calculateAssetAttributesToday(asset))
    })
    resolve(ledger)
  })
}

function processLiabilities(ledger) {
  return new Promise(resolve => {
    Object.entries(ledger.response.liabilities).forEach(([, liability]) => {
      // add todays attributes to liability object
      liability.balanceDueTodayLocal = 0
      liability.balanceDueTodayApp = 0
      liability.balanceDueTodayAppPercent = 0
      liability.repayedTodayLocal = 0
      liability.repayedTodayLocalPercent = 0
      // calculate todays basic attributes
      calculateLiabilityAttributesToday(liability)
    })
    resolve(ledger)
  })
}

function processIncomeSources(ledger) {
  return new Promise(resolve => {
    Object.entries(ledger.response.incomeSources).forEach(
      ([, incomeSource]) => {
        // add todays attributes to incomeSource object
        incomeSource.incomePaTodayApp = 0
        incomeSource.incomePaTodayAppPercent = 0
        // calculate todays basic attributes
        calculateIncomeSourceAttributesToday(incomeSource)
      }
    )
    resolve(ledger)
  })
}

function processSpendingStreams(ledger) {
  return new Promise(resolve => {
    Object.entries(ledger.response.spendingStreams).forEach(
      ([, spendingStream]) => {
        // add todays attributes to spendingStream object
        spendingStream.spendingPaTodayApp = 0
        spendingStream.spendingPaTodayAppPercent = 0
        // calculate todays basic attributes
        calculateSpendingStreamAttributesToday(spendingStream)
      }
    )
    resolve(ledger)
  })
}

export default {
  // Compute a single Asset
  computeAsset(asset) {
    return new Promise((resolve, reject) => {
      calculateAssetCost(asset)
        .then(asset => calculateAssetPrice(asset))
        .then(asset => calculateAssetAttributesToday(asset))
        .then(asset => calculateAssetProjection(asset))
        .then(asset => {
          resolve(asset)
        })
        .catch(err => {
          console.log(
            'ERROR - failed to compute asset Todays attributes',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          store.dispatch('addNotification', notification)
          reject()
        })
    })
  },

  // Compute a single Liability
  computeLiability(liability) {
    return new Promise((resolve, reject) => {
      calculateLiabilityAttributesToday(liability)
        .then(liability => calculateLiabilityProjection(liability))
        .then(liability => {
          resolve(liability)
        })
        .catch(err => {
          console.log(
            'ERROR - failed to compute liability Todays attributes',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          store.dispatch('addNotification', notification)
          reject()
        })
    })
  },

  // Compute a single Income Source
  computeIncomeSource(incomeSource) {
    return new Promise((resolve, reject) => {
      calculateIncomeSourceAttributesToday(incomeSource)
        .then(incomeSource => {
          resolve(incomeSource)
        })
        .catch(err => {
          console.log(
            'ERROR - failed to compute incomeSource Todays attributes',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          store.dispatch('addNotification', notification)
          reject()
        })
    })
  },

  // Compute a single Spending Stream
  computeSpendingStream(spendingStream) {
    return new Promise((resolve, reject) => {
      calculateSpendingStreamAttributesToday(spendingStream)
        .then(spendingStream => {
          resolve(spendingStream)
        })
        .catch(err => {
          console.log(
            'ERROR - failed to compute spendingStream Todays attributes',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          store.dispatch('addNotification', notification)
          reject()
        })
    })
  },

  // Compute a single Goal (on goal create and on goal update)
  computeGoal(goal) {
    return new Promise((resolve, reject) => {
      generateGoalAttributes(goal)
        .then(goal => {
          resolve(goal)
        })
        .catch(err => {
          console.log('ERROR - failed to compute goal attributes', err.response)
          const notification = {
            type: 'error',
            message: err.message
          }
          store.dispatch('addNotification', notification)
          reject()
        })
    })
  },

  // Compute complete Ledger (Assets, Liabilities, IncomeSources and SpendingStreams)
  computeLedger(ledger) {
    return new Promise((resolve, reject) => {
      processAssets(ledger)
        .then(ledger => processLiabilities(ledger))
        .then(ledger => processIncomeSources(ledger))
        .then(ledger => processSpendingStreams(ledger))
        .then(ledger => {
          resolve(ledger)
        })
        .catch(err => {
          console.log(
            'ERROR - failed to compute ledger Todays attributes',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          store.dispatch('addNotification', notification)
          reject()
        })
    })
  }
}
