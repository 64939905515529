import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import vuetify from './plugins/vuetify/vuetify'
import axios from 'axios'

import Default from './components/TheDefaultLayout.vue'
import Admin from './components/TheAdminLayout.vue'
import Advisor from './components/TheAdvisorLayout.vue'
import Client from './components/TheClientLayout.vue'
import Tour from './components/TheTourLayout.vue'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import { Auth } from 'aws-amplify'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import WCharts from 'vue-wcharts'
import VueApexCharts from 'vue-apexcharts'

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'ap-southeast-2:95b6e32e-985b-4ad8-9455-b61607082e50',

    // REQUIRED - Amazon Cognito Region
    region: 'ap-southeast-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'ap-southeast-2_Hh6xrHCkk',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6hmc3tdcj9skkqbtot8bso1i15',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    // domain: '.yourdomain.com',
    // OPTIONAL - Cookie path
    // path: '/',
    // OPTIONAL - Cookie expiration in days
    // expires: 365,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    // secure: true
    // },

    // OPTIONAL - customized storage object
    // storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      // domain: 'your_cognito_domain',
      // scope: [
      //   'phone',
      //   'email',
      //   'profile',
      //   'openid',
      //   'aws.cognito.signin.user.admin'
      // ],
      // redirectSignIn: 'http://localhost:3000/',
      // redirectSignOut: 'http://localhost:3000/',
      // responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  API: {
    endpoints: [
      {
        name: 'Falcon9API',
        endpoint:
          'https://lv83jmhmuf.execute-api.ap-southeast-2.amazonaws.com/dev',
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      }
    ]
  }
})

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.config.productionTip = false
Vue.component(VueQrcode.name, VueQrcode)
Vue.component('apexchart', VueApexCharts)
Vue.use(WCharts)

Vue.component('default-layout', Default)
Vue.component('admin-layout', Admin)
Vue.component('advisor-layout', Advisor)
Vue.component('client-layout', Client)
Vue.component('tour-layout', Tour)

new Vue({
  router,
  store,
  created() {
    const userString = localStorage.getItem('cognitoUser')
    if (userString) {
      const cognitoUserData = JSON.parse(userString)
      this.$store.commit('SET_COGNITO_USER', cognitoUserData)
    }
    axios.interceptors.response.use(
      response => response, // simply return the response
      error => {
        if (error.response.status === 401) {
          // if we catch a 401 error
          this.$store.dispatch('logout') // force a log out
        }
        return Promise.reject(error) // reject the Promise, with the error as the reason
      }
    )
  },
  vuetify,
  render: h => h(App)
}).$mount('#app')
