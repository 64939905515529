import { mapGetters } from 'vuex'

export const authComputed = {
  ...mapGetters([
    // auth
    'loggedIn',
    'role',
    'userName',
    'cognitoUserName',
    'cognitoUserPreferredMFA',
    'tenantName',
    'tenantId',
    'userId',
    'userEmail',
    'userEmailVerified',
    'userPhone',

    // advisor
    'advisorProfile',

    // advisorDefinitions
    'advisorDefinitionsAssets',
    'advisorDefinitionsAssetInvestmentCategories',
    'advisorDefinitionsLiabilities',
    'advisorDefinitionsIncome',
    'advisorDefinitionsSpending',
    'advisorDefinitionsModelPortfolios',

    // client
    'clientLoaded',
    'clientProfile',
    'clientProfileJoint',
    'clientProfileType',
    'clientName',
    'clientAccHolder1',
    'clientAccHolder2',
    'clientPerson1',
    'clientPerson2',
    'clientAccHolderItems',
    'clientId',
    'clientIdJoint',
    'clientIdPrimary',

    // clients
    'myActiveClients',
    'myActiveClientsCount',
    'myArchivedClients',
    'myArchivedClientsCount',
    'myDeletedClients',
    'myDeletedClientsCount',
    'myStandInClients',
    'myStandInClientsCount',
    'clientsSearch',
    'myStandInAdvisors',

    // adminAdvisorProfile
    'adminAdvisorId',

    // adminAdvisors
    'adminAdvisorList',
    'adminAdvisorCount',

    // adminDefinitions
    'adminAssetClass',
    'adminDefinitions',
    'adminDefinitionsAssets',
    'adminDefinitionsAssetInvestmentCategories',
    'adminDefinitionsLiabilities',
    'adminDefinitionsIncome',
    'adminDefinitionsSpending',
    'adminDefinitionsRiskTypes',
    'adminDefinitionsRiskTypeNames',
    'adminDefinitionsModelPortfolios',

    // system
    'fxRateToday',

    // clientLedger
    'clientLedgerGrouped',
    'clientAssets',
    'clientAssetsName',
    'clientAssetsSum',
    'clientLiabilities',
    'clientLiabilitiesSum',
    'clientIncomeSources',
    'clientIncomeSourcesSum',
    'clientSpendingStreams',
    'clientSpendingStreamsSum',
    'clientGoals',
    'clientAdvice',
    'clientExtraSavingsMaxSum',
    'currencyApp'

    // clientProjection
    // 'clientRetirement'
  ])
}
