import { API } from 'aws-amplify'

const getDefaultState = () => {
  return {
    list: [],
    count: ''
  }
}

export const state = getDefaultState()

export const mutations = {
  INIT_ADMIN_ADVISORS(state) {
    console.log('INIT_ADMIN_ADVISORS')
    Object.assign(state, getDefaultState())
  },

  SET_ADMIN_ADVISORS(state, advisors) {
    console.log('SET_ADMIN_ADVISORS', advisors)
    state.list = advisors
  },

  SET_ADMIN_ADVISORS_COUNT(state, value) {
    console.log('SET_ADMIN_ADVISORS_COUNT', value)
    state.count = value
  }
}

export const actions = {
  initAdminAdvisors({ commit }) {
    commit('INIT_ADMIN_ADVISORS')
  },

  fetchAdvisors({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      let path = '/admin/advisors/all'
      API.get('Falcon9API', path)
        .then(event => {
          var item = {}
          var advisorList = []
          for (item of event.response.Items) {
            if (item.role == 'TenAdm') {
              item.role = 'Administrator'
            } else if (item.role == 'TenAdv') {
              item.role = 'Advisor'
            }
            advisorList.push(item)
          }
          commit('SET_ADMIN_ADVISORS', advisorList)
          commit('SET_ADMIN_ADVISORS_COUNT', advisorList.length)
          dispatch('initAdminAdvisorProfile')
          console.log(
            'SUCCESS - API admin retrieved advisor list',
            event.response.Items
          )
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API admin failed to retrieve advisor list',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  }
}

export const getters = {
  adminAdvisorList(state) {
    return state.list
  },

  adminAdvisorCount(state) {
    return state.count
  }
}
