import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from '@/vuex/modules/auth.js'
import * as advisor from '@/vuex/modules/advisor.js'
import * as advisorDefinitions from '@/vuex/modules/advisorDefinitions.js'
import * as client from '@/vuex/modules/client.js'
import * as clientLedger from '@/vuex/modules/clientLedger.js'
import * as clientProjection from '@/vuex/modules/clientProjection.js'
import * as clients from '@/vuex/modules/clients.js'
import * as adminDefinitions from '@/vuex/modules/adminDefinitions.js'
import * as adminAdvisorProfile from '@/vuex/modules/adminAdvisorProfile.js'
import * as adminAdvisors from '@/vuex/modules/adminAdvisors.js'
import * as system from '@/vuex/modules/system.js'
import * as notification from '@/vuex/modules/notification.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    advisor,
    advisorDefinitions,
    client,
    clientLedger,
    clientProjection,
    clients,
    adminAdvisors,
    adminAdvisorProfile,
    adminDefinitions,
    system,
    notification
  },

  state: {
    riskTypeItems: [
      'Conservative',
      'Moderate',
      'Balanced',
      'Growth',
      'Aggressive',
      'Very Aggressive'
    ]
  }
})
