<template>
  <div>
    <p></p>
    <div>
      <v-main>
        <v-container class="fill-height" fluid>
          <slot></slot>
        </v-container>
      </v-main>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TheDefaultLayout',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped></style>
