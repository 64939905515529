<template>
  <div>
    <div v-if="activeUser">
      <v-app-bar color="primary" dark fixed flat height="90px">
        <v-toolbar-title class="mx-auto display-1"
          >Wealth Tracker /
        </v-toolbar-title>
        <v-btn color="primary" depressed @click="dialog = !dialog"
          ><v-toolbar-title class="mx-auto display-1 success--text"
            >TAKE THE TOUR ...
          </v-toolbar-title></v-btn
        >
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <template v-slot:extension>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-tabs
                  align-with-title
                  color="success"
                  background-color="transparent"
                  slider-color="success"
                >
                  <v-tab
                    v-for="(item, i) in items.tour"
                    :key="i"
                    :to="item.ref"
                  >
                    {{ item.text }}
                  </v-tab>
                </v-tabs>
                <v-divider class="success"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col><br /></v-col>
            </v-row>
          </v-container>
        </template>
      </v-app-bar>

      <v-sheet class="overflow-y-auto">
        <v-container>
          <v-row style="height: 100px;"></v-row>
          <v-row align="center" justify="center">
            <v-col>
              <slot></slot>
            </v-col>
          </v-row>
          <v-row style="height: 60px;"></v-row>
        </v-container>
        <v-footer color="primary" dark fixed flat style="height: 60px;">
          <div>&copy; {{ new Date().getFullYear() }}</div>
          <div>---v0.2.7</div>
          <div class="subtitle-1">---powered by Ledger5</div>
          <v-divider class="success"></v-divider>

          <v-divider class="success"></v-divider>

          <v-divider class="success"></v-divider>
          <v-divider class="success"></v-divider>
          <div class="font-weight-normal">Advisor--</div>
          <div class="font-weight-medium">{{ name }}</div>
        </v-footer>
      </v-sheet>
      <v-btn
        color="success"
        bottom
        right
        fixed
        dark
        fab
        class="ma-10"
        @click="dialog = !dialog"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>

      <v-dialog v-model="dialog" width="280px">
        <v-card>
          <v-card-title class="primary headline white--text"
            >Choose mode ...
          </v-card-title>
          <v-card flat>
            <v-container>
              <v-row align="center" justify="center">
                <v-col class="text-center" cols="12" sm="9">
                  <div class="my-2">
                    <v-btn
                      block
                      text
                      color="primary lighten-1"
                      :to="{ name: 'advisorDashboard' }"
                      >Advisor</v-btn
                    >
                  </div>
                  <div class="my-2">
                    <v-btn
                      block
                      text
                      color="primary lighten-1"
                      :to="{ name: 'clientDashboard' }"
                      >Client</v-btn
                    >
                  </div>
                  <div class="my-2">
                    <v-btn
                      block
                      text
                      color="primary lighten-1"
                      :to="{ name: 'tourDashboard' }"
                      >Take the Tour</v-btn
                    >
                  </div>
                  <div class="my-2">
                    <v-btn
                      block
                      text
                      color="primary lighten-1"
                      :to="{ name: 'adminDashboard' }"
                      >Administration</v-btn
                    >
                  </div>
                  <div>or</div>
                  <v-card-actions>
                    <v-btn block text color="red" @click="logoutUser"
                      >Sign Out</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-btn text color="primary" :to="{ name: 'advisorMode' }"
                  >More</v-btn
                >
                <v-spacer />
                <v-btn text color="primary" @click="dialog = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-card>
        </v-card>
      </v-dialog>
    </div>

    <div v-else>
      <template>
        <v-card width="400" class="mx-auto mt-10">
          <v-toolbar color="primary lighten-1 white--text">
            <v-toolbar-title>
              <h1 class="display-1">
                Welcome ...
              </h1>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-card-actions>
                <v-btn tile outlined color="info" :to="{ name: 'login' }"
                  >Login</v-btn
                >
                <v-spacer></v-spacer>
                <!-- <v-btn tile outlined color="success" :to="{ name: 'register' }"
                  >Register</v-btn
                > -->
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'TheTourLayout',
  data() {
    return {
      dialog: false,
      items: {
        tour: [
          { text: 'Risk Tolerance', ref: '/', model: 'tab-1' },
          { text: 'Longterm Cashflow', ref: '/', model: 'tab-2' },
          { text: 'Retirement', ref: '/', model: 'tab-3' }
        ]
      }
    }
  },
  methods: {
    logoutUser() {
      Auth.signOut({ global: true }) // revokes all JWT tokens for the user
        .then(data => {
          console.log('LOGOUT:', data)
          this.$store.dispatch('logout')
        })
        .catch(err => {
          console.log('LOGOUT FAILED:', err)
        })
    },
    showAccountData() {},
    showAdvisorDashboard() {}
  },
  computed: {
    activeUser() {
      return (
        (this.loggedIn && !!(this.userRole === 'TenAdm')) ||
        (this.loggedIn && !!(this.userRole === 'TenAdv'))
      )
    },
    ...mapState(['auth']), // mapState helper can access different parts of "falcon9 state" in an array
    ...authComputed // authComputed helper holds predefined state variables like loggedIn, userRole and name
  }
}
</script>

<style lang="scss" scoped></style>
