<template>
  <div>
    <v-alert :type="notification.type" dismissible light>
      {{ notification.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      timeout: null
    }
  },

  mounted() {
    this.timeout = setTimeout(
      () => this.$store.dispatch('removeNotification', this.notification),
      5000
    )
  },

  beforeDestroy() {
    clearTimeout(this.timeout)
  }
}
</script>

<style lang="scss" scoped></style>
