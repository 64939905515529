function lifeExpectancy(gender, currentAge, birthYear) {
  let data = {}
  data.male = {
    1921: [
      63.4,
      67.4,
      68.0,
      68.3,
      68.5,
      68.7,
      68.9,
      69.0,
      69.2,
      69.3,
      69.4,
      69.5,
      69.5,
      69.6,
      69.7,
      69.8,
      69.8,
      69.9,
      70.1,
      70.2,
      70.4,
      70.8,
      71.5,
      72.6,
      73.4,
      73.6,
      73.8,
      73.8,
      73.9,
      74.0,
      74.1,
      74.1,
      74.2,
      74.3,
      74.4,
      74.4,
      74.5,
      74.6,
      74.6,
      74.7,
      74.8,
      74.9,
      75.0,
      75.1,
      75.3,
      75.4,
      75.5,
      75.7,
      75.9,
      76.0,
      76.2,
      76.4,
      76.6,
      76.8,
      77.1,
      77.3,
      77.6,
      77.9,
      78.1,
      78.4,
      78.7,
      79.1,
      79.4,
      79.7,
      80.1,
      80.5,
      80.9,
      81.3,
      81.7,
      82.2,
      82.6,
      83.1,
      83.5,
      84.0,
      84.5,
      85.0,
      85.5,
      86.0,
      86.5,
      87.1,
      87.7,
      88.2,
      88.8,
      89.4,
      90.0,
      90.7,
      91.3,
      92.0,
      92.7,
      93.3,
      94.0,
      94.7,
      95.5,
      96.1,
      96.9,
      97.7
    ],
    1922: [
      64.4,
      68.3,
      68.9,
      69.2,
      69.4,
      69.6,
      69.8,
      69.9,
      70.1,
      70.1,
      70.2,
      70.3,
      70.4,
      70.5,
      70.5,
      70.6,
      70.7,
      70.8,
      70.9,
      71.1,
      71.3,
      71.8,
      72.6,
      73.6,
      73.8,
      73.9,
      74.0,
      74.1,
      74.1,
      74.2,
      74.3,
      74.4,
      74.4,
      74.5,
      74.6,
      74.7,
      74.7,
      74.8,
      74.9,
      75.0,
      75.1,
      75.2,
      75.3,
      75.4,
      75.5,
      75.6,
      75.7,
      75.9,
      76.1,
      76.2,
      76.4,
      76.6,
      76.8,
      77.0,
      77.3,
      77.5,
      77.8,
      78.0,
      78.3,
      78.6,
      78.9,
      79.2,
      79.5,
      79.8,
      80.2,
      80.6,
      81.0,
      81.4,
      81.9,
      82.2,
      82.7,
      83.1,
      83.6,
      84.1,
      84.6,
      85.1,
      85.6,
      86.1,
      86.7,
      87.2,
      87.7,
      88.3,
      88.9,
      89.5,
      90.0,
      90.7,
      91.3,
      91.9,
      92.6,
      93.2,
      94.0,
      94.7,
      95.4,
      96.1,
      96.9,
      97.7
    ],
    1923: [
      65.7,
      69.7,
      70.3,
      70.7,
      70.9,
      71.1,
      71.2,
      71.4,
      71.5,
      71.6,
      71.7,
      71.7,
      71.8,
      71.9,
      72.0,
      72.1,
      72.2,
      72.3,
      72.4,
      72.5,
      72.7,
      73.1,
      73.8,
      74.0,
      74.2,
      74.2,
      74.3,
      74.4,
      74.4,
      74.5,
      74.6,
      74.7,
      74.8,
      74.8,
      74.9,
      75.0,
      75.1,
      75.1,
      75.2,
      75.3,
      75.4,
      75.5,
      75.6,
      75.7,
      75.8,
      75.9,
      76.0,
      76.2,
      76.4,
      76.5,
      76.7,
      77.0,
      77.2,
      77.4,
      77.6,
      77.9,
      78.1,
      78.4,
      78.7,
      79.0,
      79.3,
      79.7,
      80.0,
      80.3,
      80.7,
      81.1,
      81.5,
      81.8,
      82.2,
      82.6,
      83.1,
      83.5,
      84.0,
      84.4,
      84.9,
      85.4,
      85.8,
      86.4,
      86.9,
      87.4,
      87.9,
      88.5,
      89.1,
      89.6,
      90.1,
      90.7,
      91.3,
      91.9,
      92.5,
      93.3,
      94.0,
      94.7,
      95.5,
      96.2,
      97.0,
      97.8
    ],
    1924: [
      66.6,
      70.4,
      71.0,
      71.4,
      71.6,
      71.8,
      72.0,
      72.1,
      72.2,
      72.3,
      72.4,
      72.4,
      72.5,
      72.6,
      72.7,
      72.8,
      72.9,
      73.0,
      73.1,
      73.2,
      73.4,
      73.8,
      74.0,
      74.1,
      74.2,
      74.3,
      74.4,
      74.5,
      74.6,
      74.6,
      74.7,
      74.8,
      74.9,
      74.9,
      75.0,
      75.1,
      75.2,
      75.2,
      75.3,
      75.4,
      75.5,
      75.6,
      75.7,
      75.8,
      75.9,
      76.0,
      76.2,
      76.3,
      76.4,
      76.6,
      76.8,
      77.1,
      77.2,
      77.5,
      77.7,
      78.0,
      78.2,
      78.5,
      78.8,
      79.1,
      79.4,
      79.7,
      80.0,
      80.4,
      80.8,
      81.2,
      81.5,
      81.9,
      82.3,
      82.8,
      83.2,
      83.6,
      84.1,
      84.5,
      85.0,
      85.5,
      85.9,
      86.4,
      86.9,
      87.4,
      87.9,
      88.4,
      89.0,
      89.5,
      90.1,
      90.7,
      91.3,
      91.9,
      92.5,
      93.3,
      93.9,
      94.7,
      95.4,
      96.2,
      97.0,
      97.8
    ],
    1925: [
      67.4,
      71.1,
      71.6,
      72.0,
      72.2,
      72.4,
      72.6,
      72.7,
      72.8,
      72.9,
      73.0,
      73.1,
      73.2,
      73.2,
      73.3,
      73.4,
      73.5,
      73.6,
      73.7,
      73.8,
      74.0,
      74.1,
      74.3,
      74.4,
      74.5,
      74.6,
      74.7,
      74.8,
      74.9,
      75.0,
      75.0,
      75.1,
      75.2,
      75.3,
      75.3,
      75.4,
      75.5,
      75.6,
      75.7,
      75.7,
      75.8,
      75.9,
      76.0,
      76.1,
      76.2,
      76.4,
      76.5,
      76.7,
      76.8,
      77.0,
      77.2,
      77.4,
      77.6,
      77.8,
      78.0,
      78.2,
      78.5,
      78.8,
      79.1,
      79.3,
      79.6,
      80.0,
      80.3,
      80.6,
      81.0,
      81.3,
      81.7,
      82.1,
      82.4,
      82.8,
      83.3,
      83.7,
      84.2,
      84.6,
      85.1,
      85.6,
      86.0,
      86.5,
      87.0,
      87.5,
      88.0,
      88.6,
      89.1,
      89.7,
      90.3,
      90.8,
      91.4,
      92.0,
      92.7,
      93.4,
      94.0,
      94.7,
      95.5,
      96.2,
      97.0,
      97.8
    ],
    1926: [
      67.9,
      71.6,
      72.1,
      72.5,
      72.7,
      72.9,
      73.0,
      73.1,
      73.2,
      73.3,
      73.4,
      73.5,
      73.6,
      73.7,
      73.7,
      73.8,
      73.9,
      74.0,
      74.2,
      74.3,
      74.4,
      74.5,
      74.7,
      74.8,
      74.9,
      74.9,
      75.0,
      75.1,
      75.2,
      75.3,
      75.3,
      75.4,
      75.5,
      75.6,
      75.6,
      75.7,
      75.8,
      75.8,
      75.9,
      76.0,
      76.1,
      76.2,
      76.3,
      76.4,
      76.5,
      76.6,
      76.8,
      76.9,
      77.1,
      77.2,
      77.4,
      77.6,
      77.8,
      78.0,
      78.2,
      78.5,
      78.7,
      79.0,
      79.2,
      79.5,
      79.8,
      80.2,
      80.5,
      80.8,
      81.1,
      81.5,
      81.9,
      82.3,
      82.6,
      83.0,
      83.5,
      83.9,
      84.4,
      84.8,
      85.3,
      85.7,
      86.2,
      86.7,
      87.1,
      87.7,
      88.1,
      88.6,
      89.2,
      89.7,
      90.3,
      90.9,
      91.4,
      92.1,
      92.7,
      93.3,
      94.0,
      94.7,
      95.5,
      96.2,
      97.0,
      97.8
    ],
    1927: [
      68.2,
      72.0,
      72.6,
      72.9,
      73.1,
      73.3,
      73.4,
      73.5,
      73.6,
      73.7,
      73.8,
      73.9,
      74.0,
      74.0,
      74.1,
      74.2,
      74.3,
      74.4,
      74.5,
      74.7,
      74.8,
      74.9,
      75.0,
      75.1,
      75.2,
      75.4,
      75.4,
      75.5,
      75.6,
      75.7,
      75.7,
      75.8,
      75.9,
      75.9,
      76.0,
      76.0,
      76.1,
      76.2,
      76.3,
      76.3,
      76.4,
      76.5,
      76.6,
      76.8,
      76.9,
      77.0,
      77.1,
      77.3,
      77.4,
      77.6,
      77.8,
      78.0,
      78.2,
      78.4,
      78.7,
      78.9,
      79.1,
      79.4,
      79.6,
      79.9,
      80.2,
      80.5,
      80.8,
      81.2,
      81.5,
      81.9,
      82.3,
      82.6,
      83.1,
      83.5,
      83.9,
      84.3,
      84.6,
      85.1,
      85.5,
      86.0,
      86.4,
      86.9,
      87.3,
      87.7,
      88.2,
      88.8,
      89.3,
      89.8,
      90.3,
      90.9,
      91.5,
      92.1,
      92.8,
      93.4,
      94.1,
      94.8,
      95.5,
      96.2,
      97.0,
      97.8
    ],
    1928: [
      68.5,
      72.3,
      72.9,
      73.2,
      73.4,
      73.6,
      73.7,
      73.8,
      73.9,
      74.0,
      74.1,
      74.2,
      74.3,
      74.4,
      74.5,
      74.6,
      74.7,
      74.8,
      74.9,
      75.0,
      75.1,
      75.2,
      75.3,
      75.4,
      75.5,
      75.6,
      75.7,
      75.8,
      75.9,
      75.9,
      76.0,
      76.1,
      76.1,
      76.2,
      76.3,
      76.4,
      76.4,
      76.5,
      76.6,
      76.7,
      76.8,
      76.9,
      77.0,
      77.1,
      77.3,
      77.4,
      77.5,
      77.6,
      77.8,
      78.0,
      78.2,
      78.3,
      78.5,
      78.7,
      78.9,
      79.1,
      79.4,
      79.6,
      79.8,
      80.1,
      80.4,
      80.7,
      81.0,
      81.3,
      81.6,
      82.0,
      82.3,
      82.7,
      83.1,
      83.4,
      83.9,
      84.3,
      84.7,
      85.1,
      85.5,
      85.9,
      86.4,
      86.8,
      87.3,
      87.7,
      88.2,
      88.7,
      89.2,
      89.7,
      90.3,
      90.8,
      91.4,
      92.1,
      92.8,
      93.4,
      94.1,
      94.8,
      95.5,
      96.2,
      97.0,
      97.8
    ],
    1929: [
      68.9,
      72.6,
      73.1,
      73.4,
      73.6,
      73.9,
      74.0,
      74.1,
      74.2,
      74.4,
      74.5,
      74.6,
      74.6,
      74.7,
      74.8,
      74.9,
      75.0,
      75.1,
      75.2,
      75.3,
      75.4,
      75.5,
      75.6,
      75.7,
      75.8,
      75.9,
      76.0,
      76.1,
      76.2,
      76.3,
      76.3,
      76.4,
      76.5,
      76.5,
      76.6,
      76.7,
      76.7,
      76.8,
      76.9,
      77.0,
      77.1,
      77.2,
      77.3,
      77.4,
      77.6,
      77.7,
      77.8,
      78.0,
      78.1,
      78.3,
      78.5,
      78.7,
      78.8,
      79.0,
      79.2,
      79.4,
      79.6,
      79.9,
      80.1,
      80.4,
      80.7,
      81.0,
      81.2,
      81.6,
      81.9,
      82.2,
      82.6,
      83.0,
      83.4,
      83.8,
      84.1,
      84.5,
      85.0,
      85.4,
      85.9,
      86.2,
      86.7,
      87.1,
      87.5,
      88.0,
      88.5,
      89.0,
      89.5,
      90.0,
      90.5,
      91.1,
      91.6,
      92.2,
      92.8,
      93.4,
      94.1,
      94.8,
      95.5,
      96.2,
      97.0,
      97.8
    ],
    1930: [
      69.4,
      73.0,
      73.6,
      73.9,
      74.1,
      74.3,
      74.5,
      74.6,
      74.7,
      74.9,
      75.0,
      75.1,
      75.1,
      75.2,
      75.3,
      75.4,
      75.5,
      75.5,
      75.6,
      75.7,
      75.9,
      76.0,
      76.1,
      76.2,
      76.3,
      76.4,
      76.5,
      76.5,
      76.6,
      76.7,
      76.7,
      76.8,
      76.9,
      76.9,
      77.0,
      77.0,
      77.1,
      77.2,
      77.3,
      77.4,
      77.5,
      77.6,
      77.7,
      77.8,
      77.9,
      78.0,
      78.2,
      78.3,
      78.5,
      78.6,
      78.8,
      79.0,
      79.2,
      79.4,
      79.6,
      79.8,
      80.0,
      80.3,
      80.6,
      80.8,
      81.1,
      81.4,
      81.6,
      81.9,
      82.2,
      82.5,
      82.9,
      83.3,
      83.6,
      83.9,
      84.3,
      84.7,
      85.1,
      85.5,
      85.9,
      86.3,
      86.7,
      87.1,
      87.5,
      88.0,
      88.5,
      88.9,
      89.4,
      89.9,
      90.4,
      91.0,
      91.6,
      92.2,
      92.8,
      93.5,
      94.1,
      94.8,
      95.5,
      96.3,
      97.0,
      97.8
    ],
    1931: [
      69.6,
      73.2,
      73.7,
      74.1,
      74.4,
      74.5,
      74.7,
      74.8,
      75.0,
      75.1,
      75.2,
      75.3,
      75.4,
      75.4,
      75.5,
      75.6,
      75.7,
      75.7,
      75.8,
      75.9,
      76.0,
      76.1,
      76.3,
      76.3,
      76.4,
      76.5,
      76.6,
      76.7,
      76.7,
      76.8,
      76.9,
      76.9,
      77.0,
      77.1,
      77.1,
      77.2,
      77.3,
      77.4,
      77.5,
      77.6,
      77.7,
      77.8,
      77.9,
      78.0,
      78.1,
      78.2,
      78.3,
      78.5,
      78.7,
      78.8,
      79.0,
      79.2,
      79.3,
      79.5,
      79.7,
      80.0,
      80.2,
      80.4,
      80.6,
      80.9,
      81.2,
      81.5,
      81.8,
      82.1,
      82.4,
      82.7,
      83.1,
      83.4,
      83.8,
      84.2,
      84.6,
      85.0,
      85.4,
      85.7,
      86.1,
      86.5,
      86.9,
      87.3,
      87.8,
      88.2,
      88.6,
      89.0,
      89.5,
      90.0,
      90.5,
      91.0,
      91.6,
      92.2,
      92.8,
      93.5,
      94.1,
      94.8,
      95.5,
      96.3,
      97.0,
      97.8
    ],
    1932: [
      70,
      73.5,
      74.0,
      74.3,
      74.7,
      74.8,
      75.0,
      75.1,
      75.2,
      75.3,
      75.4,
      75.5,
      75.6,
      75.7,
      75.7,
      75.8,
      75.9,
      76.0,
      76.1,
      76.2,
      76.3,
      76.4,
      76.5,
      76.6,
      76.7,
      76.8,
      76.9,
      77.0,
      77.1,
      77.1,
      77.2,
      77.3,
      77.3,
      77.4,
      77.5,
      77.6,
      77.6,
      77.7,
      77.8,
      77.9,
      78.0,
      78.1,
      78.2,
      78.3,
      78.4,
      78.5,
      78.7,
      78.8,
      79.0,
      79.1,
      79.3,
      79.4,
      79.6,
      79.8,
      80.0,
      80.3,
      80.5,
      80.7,
      81.0,
      81.2,
      81.5,
      81.8,
      82.0,
      82.3,
      82.7,
      83.0,
      83.3,
      83.6,
      84.0,
      84.3,
      84.7,
      85.0,
      85.4,
      85.8,
      86.2,
      86.5,
      86.9,
      87.3,
      87.7,
      88.1,
      88.6,
      89.1,
      89.5,
      90.0,
      90.5,
      91.1,
      91.7,
      92.2,
      92.9,
      93.5,
      94.2,
      94.8,
      95.5,
      96.3,
      97.0,
      97.8
    ],
    1933: [
      70.2,
      73.9,
      74.4,
      74.7,
      74.9,
      75.2,
      75.4,
      75.5,
      75.5,
      75.6,
      75.7,
      75.8,
      75.9,
      75.9,
      76.0,
      76.1,
      76.1,
      76.2,
      76.3,
      76.4,
      76.5,
      76.7,
      76.8,
      76.9,
      77.0,
      77.1,
      77.2,
      77.2,
      77.3,
      77.4,
      77.4,
      77.5,
      77.6,
      77.6,
      77.7,
      77.8,
      77.9,
      77.9,
      78.0,
      78.1,
      78.2,
      78.3,
      78.4,
      78.5,
      78.7,
      78.8,
      78.9,
      79.1,
      79.2,
      79.3,
      79.5,
      79.7,
      79.8,
      80.0,
      80.2,
      80.4,
      80.6,
      80.8,
      81.1,
      81.3,
      81.6,
      81.8,
      82.1,
      82.4,
      82.7,
      83.1,
      83.4,
      83.7,
      84.0,
      84.4,
      84.7,
      85.1,
      85.5,
      85.9,
      86.3,
      86.6,
      87.0,
      87.4,
      87.9,
      88.3,
      88.7,
      89.2,
      89.6,
      90.1,
      90.6,
      91.1,
      91.7,
      92.3,
      92.9,
      93.5,
      94.2,
      94.9,
      95.6,
      96.3,
      97.0,
      97.8
    ],
    1934: [
      70.6,
      74.3,
      74.8,
      75.1,
      75.4,
      75.7,
      75.8,
      75.9,
      76.0,
      76.1,
      76.2,
      76.3,
      76.3,
      76.4,
      76.4,
      76.5,
      76.6,
      76.6,
      76.7,
      76.8,
      76.9,
      77.1,
      77.2,
      77.3,
      77.4,
      77.5,
      77.5,
      77.6,
      77.7,
      77.7,
      77.8,
      77.9,
      78.0,
      78.0,
      78.1,
      78.2,
      78.3,
      78.3,
      78.4,
      78.5,
      78.6,
      78.7,
      78.8,
      78.9,
      79.0,
      79.2,
      79.3,
      79.4,
      79.6,
      79.8,
      79.9,
      80.1,
      80.3,
      80.5,
      80.7,
      80.8,
      81.1,
      81.3,
      81.5,
      81.8,
      82.0,
      82.2,
      82.5,
      82.8,
      83.1,
      83.4,
      83.7,
      84.0,
      84.3,
      84.6,
      84.9,
      85.2,
      85.5,
      85.9,
      86.3,
      86.7,
      87.1,
      87.4,
      87.9,
      88.3,
      88.7,
      89.2,
      89.7,
      90.2,
      90.7,
      91.2,
      91.7,
      92.3,
      92.9,
      93.5,
      94.2,
      94.9,
      95.6,
      96.3,
      97.1,
      97.8
    ],
    1935: [
      70.6,
      74.3,
      74.9,
      75.4,
      75.7,
      75.9,
      76.0,
      76.1,
      76.2,
      76.3,
      76.4,
      76.5,
      76.6,
      76.6,
      76.7,
      76.8,
      76.8,
      76.9,
      77.0,
      77.1,
      77.2,
      77.3,
      77.4,
      77.5,
      77.6,
      77.7,
      77.7,
      77.8,
      77.9,
      77.9,
      78.0,
      78.1,
      78.2,
      78.3,
      78.4,
      78.5,
      78.5,
      78.6,
      78.7,
      78.8,
      78.9,
      79.0,
      79.1,
      79.2,
      79.3,
      79.4,
      79.5,
      79.7,
      79.8,
      80.0,
      80.1,
      80.3,
      80.5,
      80.7,
      80.9,
      81.1,
      81.3,
      81.5,
      81.7,
      82.0,
      82.2,
      82.5,
      82.7,
      83.0,
      83.3,
      83.6,
      83.9,
      84.2,
      84.5,
      84.8,
      85.1,
      85.4,
      85.8,
      86.1,
      86.4,
      86.8,
      87.2,
      87.6,
      88.0,
      88.4,
      88.8,
      89.2,
      89.7,
      90.2,
      90.7,
      91.2,
      91.8,
      92.3,
      92.9,
      93.6,
      94.2,
      94.9,
      95.6,
      96.3,
      97.1,
      97.8
    ],
    1936: [
      70.9,
      74.6,
      75.3,
      75.8,
      76.0,
      76.2,
      76.3,
      76.4,
      76.6,
      76.7,
      76.8,
      76.8,
      76.9,
      77.0,
      77.0,
      77.1,
      77.1,
      77.2,
      77.3,
      77.4,
      77.5,
      77.6,
      77.7,
      77.8,
      77.9,
      77.9,
      78.0,
      78.1,
      78.1,
      78.2,
      78.3,
      78.4,
      78.5,
      78.5,
      78.6,
      78.7,
      78.8,
      78.9,
      78.9,
      79.0,
      79.1,
      79.2,
      79.3,
      79.4,
      79.5,
      79.6,
      79.7,
      79.9,
      80.1,
      80.2,
      80.3,
      80.5,
      80.7,
      80.9,
      81.1,
      81.3,
      81.5,
      81.8,
      82.0,
      82.2,
      82.5,
      82.7,
      83.0,
      83.3,
      83.6,
      83.9,
      84.1,
      84.4,
      84.8,
      85.1,
      85.3,
      85.7,
      86.0,
      86.3,
      86.7,
      87.0,
      87.3,
      87.7,
      88.1,
      88.5,
      88.9,
      89.3,
      89.8,
      90.3,
      90.7,
      91.3,
      91.8,
      92.4,
      93.0,
      93.6,
      94.2,
      94.9,
      95.6,
      96.3,
      97.1,
      97.8
    ],
    1937: [
      71.4,
      75.2,
      75.9,
      76.2,
      76.4,
      76.6,
      76.7,
      76.8,
      76.9,
      77.0,
      77.1,
      77.1,
      77.2,
      77.3,
      77.3,
      77.3,
      77.4,
      77.5,
      77.6,
      77.7,
      77.8,
      77.9,
      78.0,
      78.1,
      78.2,
      78.3,
      78.3,
      78.4,
      78.5,
      78.5,
      78.6,
      78.7,
      78.7,
      78.8,
      78.9,
      79.0,
      79.0,
      79.1,
      79.2,
      79.3,
      79.3,
      79.5,
      79.5,
      79.6,
      79.8,
      79.9,
      80.0,
      80.1,
      80.3,
      80.4,
      80.5,
      80.7,
      80.9,
      81.0,
      81.2,
      81.4,
      81.6,
      81.8,
      82.1,
      82.3,
      82.5,
      82.8,
      83.1,
      83.3,
      83.6,
      83.9,
      84.2,
      84.5,
      84.7,
      85.0,
      85.3,
      85.7,
      86.0,
      86.3,
      86.6,
      87.0,
      87.3,
      87.7,
      88.1,
      88.5,
      89.0,
      89.4,
      89.8,
      90.3,
      90.8,
      91.3,
      91.8,
      92.4,
      93.0,
      93.6,
      94.2,
      94.9,
      95.6,
      96.3,
      97.1,
      97.9
    ],
    1938: [
      71.4,
      75.8,
      76.4,
      76.6,
      76.8,
      77.0,
      77.1,
      77.2,
      77.3,
      77.3,
      77.4,
      77.4,
      77.5,
      77.5,
      77.6,
      77.6,
      77.7,
      77.8,
      77.9,
      78.0,
      78.1,
      78.2,
      78.3,
      78.4,
      78.5,
      78.6,
      78.6,
      78.7,
      78.7,
      78.8,
      78.9,
      78.9,
      79.0,
      79.1,
      79.2,
      79.2,
      79.3,
      79.4,
      79.4,
      79.5,
      79.6,
      79.7,
      79.8,
      79.9,
      80.0,
      80.1,
      80.2,
      80.4,
      80.5,
      80.7,
      80.8,
      81.0,
      81.1,
      81.3,
      81.5,
      81.7,
      81.9,
      82.1,
      82.3,
      82.6,
      82.8,
      83.0,
      83.3,
      83.5,
      83.8,
      84.1,
      84.3,
      84.6,
      84.9,
      85.2,
      85.4,
      85.7,
      86.1,
      86.4,
      86.7,
      87.1,
      87.4,
      87.8,
      88.2,
      88.6,
      89.0,
      89.5,
      89.9,
      90.4,
      90.8,
      91.3,
      91.9,
      92.4,
      93.0,
      93.6,
      94.3,
      94.9,
      95.6,
      96.3,
      97.1,
      97.9
    ],
    1939: [
      72.5,
      76.3,
      76.8,
      77.2,
      77.4,
      77.6,
      77.7,
      77.8,
      77.9,
      77.9,
      77.9,
      78.0,
      78.0,
      78.1,
      78.1,
      78.2,
      78.2,
      78.3,
      78.4,
      78.5,
      78.6,
      78.7,
      78.8,
      78.9,
      78.9,
      79.0,
      79.1,
      79.2,
      79.2,
      79.3,
      79.3,
      79.4,
      79.5,
      79.5,
      79.6,
      79.7,
      79.8,
      79.8,
      79.9,
      80.0,
      80.1,
      80.2,
      80.2,
      80.3,
      80.4,
      80.6,
      80.7,
      80.8,
      80.9,
      81.1,
      81.2,
      81.4,
      81.5,
      81.7,
      81.8,
      82.0,
      82.2,
      82.4,
      82.6,
      82.9,
      83.1,
      83.3,
      83.6,
      83.8,
      84.1,
      84.3,
      84.6,
      84.9,
      85.1,
      85.4,
      85.7,
      86.0,
      86.3,
      86.6,
      87.0,
      87.3,
      87.6,
      88.0,
      88.3,
      88.7,
      89.1,
      89.5,
      90.0,
      90.4,
      90.9,
      91.4,
      91.9,
      92.5,
      93.0,
      93.6,
      94.3,
      94.9,
      95.6,
      96.4,
      97.1,
      97.9
    ],
    1940: [
      73.3,
      76.7,
      77.2,
      77.5,
      77.7,
      77.9,
      78.0,
      78.1,
      78.1,
      78.2,
      78.2,
      78.3,
      78.3,
      78.4,
      78.4,
      78.5,
      78.5,
      78.6,
      78.6,
      78.8,
      78.8,
      78.9,
      79.0,
      79.1,
      79.2,
      79.2,
      79.3,
      79.4,
      79.5,
      79.5,
      79.6,
      79.7,
      79.7,
      79.8,
      79.9,
      79.9,
      80.0,
      80.1,
      80.1,
      80.2,
      80.3,
      80.4,
      80.5,
      80.6,
      80.6,
      80.8,
      80.9,
      81.0,
      81.1,
      81.2,
      81.4,
      81.6,
      81.7,
      81.9,
      82.1,
      82.3,
      82.4,
      82.6,
      82.9,
      83.1,
      83.3,
      83.5,
      83.7,
      84.0,
      84.2,
      84.4,
      84.7,
      84.9,
      85.2,
      85.4,
      85.7,
      86.0,
      86.3,
      86.6,
      87.0,
      87.3,
      87.7,
      88.0,
      88.4,
      88.8,
      89.2,
      89.6,
      90.0,
      90.5,
      90.9,
      91.4,
      91.9,
      92.5,
      93.1,
      93.7,
      94.3,
      95.0,
      95.6,
      96.4,
      97.1,
      97.9
    ],
    1941: [
      73.7,
      77.2,
      77.7,
      78.0,
      78.1,
      78.3,
      78.4,
      78.5,
      78.5,
      78.6,
      78.6,
      78.7,
      78.7,
      78.8,
      78.8,
      78.8,
      78.9,
      79.0,
      79.0,
      79.1,
      79.2,
      79.3,
      79.4,
      79.5,
      79.5,
      79.6,
      79.7,
      79.8,
      79.9,
      79.9,
      80.0,
      80.1,
      80.1,
      80.2,
      80.3,
      80.3,
      80.4,
      80.5,
      80.6,
      80.6,
      80.7,
      80.8,
      80.9,
      81.0,
      81.1,
      81.2,
      81.3,
      81.4,
      81.5,
      81.7,
      81.8,
      81.9,
      82.1,
      82.2,
      82.4,
      82.6,
      82.8,
      82.9,
      83.1,
      83.3,
      83.5,
      83.7,
      83.9,
      84.1,
      84.3,
      84.5,
      84.8,
      85.1,
      85.3,
      85.6,
      85.9,
      86.1,
      86.5,
      86.8,
      87.1,
      87.5,
      87.8,
      88.1,
      88.5,
      88.9,
      89.2,
      89.6,
      90.1,
      90.5,
      91.0,
      91.5,
      92.0,
      92.5,
      93.1,
      93.7,
      94.3,
      95.0,
      95.7,
      96.4,
      97.1,
      97.9
    ],
    1942: [
      74,
      77.4,
      77.8,
      78.1,
      78.3,
      78.4,
      78.5,
      78.6,
      78.6,
      78.7,
      78.7,
      78.8,
      78.8,
      78.9,
      78.9,
      78.9,
      79.0,
      79.1,
      79.1,
      79.2,
      79.3,
      79.4,
      79.5,
      79.5,
      79.6,
      79.7,
      79.8,
      79.8,
      79.9,
      80.0,
      80.0,
      80.1,
      80.2,
      80.3,
      80.3,
      80.4,
      80.5,
      80.6,
      80.6,
      80.7,
      80.8,
      80.9,
      80.9,
      81.0,
      81.2,
      81.3,
      81.3,
      81.5,
      81.6,
      81.7,
      81.9,
      82.0,
      82.1,
      82.3,
      82.5,
      82.6,
      82.8,
      83.0,
      83.2,
      83.4,
      83.6,
      83.8,
      84.0,
      84.3,
      84.5,
      84.7,
      85.0,
      85.2,
      85.4,
      85.7,
      86.0,
      86.3,
      86.6,
      86.9,
      87.2,
      87.6,
      87.9,
      88.2,
      88.6,
      88.9,
      89.3,
      89.7,
      90.1,
      90.6,
      91.0,
      91.5,
      92.0,
      92.6,
      93.1,
      93.7,
      94.3,
      95.0,
      95.7,
      96.4,
      97.1,
      97.9
    ],
    1943: [
      73.9,
      77.4,
      77.8,
      78.1,
      78.3,
      78.4,
      78.5,
      78.6,
      78.6,
      78.7,
      78.7,
      78.8,
      78.8,
      78.9,
      78.9,
      78.9,
      79.0,
      79.0,
      79.1,
      79.2,
      79.3,
      79.4,
      79.5,
      79.6,
      79.7,
      79.7,
      79.8,
      79.9,
      80.0,
      80.0,
      80.1,
      80.2,
      80.2,
      80.3,
      80.4,
      80.5,
      80.5,
      80.6,
      80.7,
      80.8,
      80.9,
      81.0,
      81.0,
      81.1,
      81.2,
      81.3,
      81.4,
      81.5,
      81.7,
      81.8,
      82.0,
      82.1,
      82.2,
      82.4,
      82.6,
      82.8,
      83.0,
      83.2,
      83.3,
      83.5,
      83.7,
      83.9,
      84.2,
      84.4,
      84.6,
      84.9,
      85.1,
      85.4,
      85.6,
      85.9,
      86.2,
      86.5,
      86.7,
      87.0,
      87.3,
      87.7,
      88.0,
      88.3,
      88.7,
      89.0,
      89.4,
      89.8,
      90.2,
      90.6,
      91.1,
      91.5,
      92.1,
      92.6,
      93.1,
      93.7,
      94.4,
      95.0,
      95.7,
      96.4,
      97.1,
      97.9
    ],
    1944: [
      74.6,
      78.1,
      78.5,
      78.7,
      78.8,
      78.9,
      79.0,
      79.0,
      79.1,
      79.2,
      79.2,
      79.2,
      79.3,
      79.3,
      79.4,
      79.4,
      79.5,
      79.5,
      79.6,
      79.7,
      79.8,
      79.9,
      80.0,
      80.1,
      80.1,
      80.2,
      80.3,
      80.4,
      80.5,
      80.5,
      80.6,
      80.7,
      80.7,
      80.8,
      80.8,
      80.9,
      81.0,
      81.0,
      81.1,
      81.2,
      81.3,
      81.4,
      81.4,
      81.5,
      81.6,
      81.8,
      81.9,
      82.0,
      82.1,
      82.2,
      82.3,
      82.5,
      82.6,
      82.8,
      82.9,
      83.1,
      83.2,
      83.4,
      83.6,
      83.8,
      84.0,
      84.2,
      84.4,
      84.6,
      84.8,
      85.0,
      85.3,
      85.5,
      85.8,
      86.0,
      86.3,
      86.6,
      86.9,
      87.2,
      87.4,
      87.8,
      88.1,
      88.4,
      88.7,
      89.1,
      89.5,
      89.8,
      90.2,
      90.7,
      91.1,
      91.6,
      92.1,
      92.6,
      93.2,
      93.8,
      94.4,
      95.0,
      95.7,
      96.4,
      97.1,
      97.9
    ],
    1945: [
      75.8,
      79.0,
      79.3,
      79.5,
      79.6,
      79.7,
      79.8,
      79.9,
      79.9,
      80.0,
      80.0,
      80.0,
      80.1,
      80.1,
      80.1,
      80.2,
      80.2,
      80.3,
      80.4,
      80.5,
      80.5,
      80.6,
      80.8,
      80.8,
      80.9,
      81.0,
      81.1,
      81.2,
      81.2,
      81.3,
      81.4,
      81.4,
      81.5,
      81.6,
      81.6,
      81.7,
      81.7,
      81.8,
      81.8,
      81.9,
      82.0,
      82.1,
      82.2,
      82.3,
      82.4,
      82.4,
      82.5,
      82.6,
      82.7,
      82.8,
      83.0,
      83.1,
      83.2,
      83.4,
      83.5,
      83.6,
      83.8,
      83.9,
      84.1,
      84.2,
      84.4,
      84.6,
      84.8,
      85.0,
      85.1,
      85.4,
      85.6,
      85.8,
      86.0,
      86.3,
      86.5,
      86.7,
      87.0,
      87.3,
      87.6,
      87.8,
      88.2,
      88.5,
      88.8,
      89.2,
      89.5,
      89.9,
      90.3,
      90.7,
      91.2,
      91.6,
      92.1,
      92.6,
      93.2,
      93.8,
      94.4,
      95.0,
      95.7,
      96.4,
      97.2,
      97.9
    ],
    1946: [
      76.3,
      79.3,
      79.6,
      79.8,
      79.9,
      80.0,
      80.1,
      80.1,
      80.1,
      80.2,
      80.2,
      80.2,
      80.3,
      80.3,
      80.3,
      80.4,
      80.4,
      80.5,
      80.6,
      80.6,
      80.7,
      80.8,
      80.9,
      81.0,
      81.1,
      81.2,
      81.3,
      81.4,
      81.4,
      81.5,
      81.6,
      81.6,
      81.7,
      81.8,
      81.8,
      81.9,
      81.9,
      82.0,
      82.1,
      82.1,
      82.2,
      82.2,
      82.3,
      82.4,
      82.5,
      82.6,
      82.7,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.5,
      83.6,
      83.8,
      83.9,
      84.1,
      84.2,
      84.4,
      84.6,
      84.7,
      84.9,
      85.1,
      85.3,
      85.5,
      85.7,
      85.9,
      86.1,
      86.4,
      86.6,
      86.8,
      87.1,
      87.4,
      87.7,
      87.9,
      88.3,
      88.6,
      88.9,
      89.2,
      89.6,
      90.0,
      90.4,
      90.8,
      91.2,
      91.7,
      92.2,
      92.7,
      93.2,
      93.8,
      94.4,
      95.1,
      95.7,
      96.4,
      97.2,
      97.9
    ],
    1947: [
      76.8,
      79.5,
      79.9,
      80.0,
      80.1,
      80.2,
      80.2,
      80.3,
      80.3,
      80.3,
      80.4,
      80.4,
      80.4,
      80.5,
      80.5,
      80.5,
      80.6,
      80.6,
      80.7,
      80.8,
      80.9,
      81.0,
      81.1,
      81.2,
      81.3,
      81.4,
      81.5,
      81.5,
      81.6,
      81.7,
      81.7,
      81.8,
      81.8,
      81.9,
      82.0,
      82.0,
      82.1,
      82.1,
      82.2,
      82.3,
      82.4,
      82.4,
      82.5,
      82.6,
      82.7,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.7,
      83.8,
      83.9,
      84.1,
      84.2,
      84.3,
      84.5,
      84.7,
      84.8,
      85.0,
      85.2,
      85.4,
      85.6,
      85.8,
      86.0,
      86.2,
      86.5,
      86.7,
      87.0,
      87.2,
      87.5,
      87.8,
      88.0,
      88.3,
      88.7,
      89.0,
      89.3,
      89.7,
      90.0,
      90.4,
      90.8,
      91.3,
      91.7,
      92.2,
      92.7,
      93.3,
      93.8,
      94.4,
      95.1,
      95.7,
      96.4,
      97.2,
      97.9
    ],
    1948: [
      76.7,
      79.4,
      79.7,
      79.9,
      79.9,
      80.0,
      80.1,
      80.1,
      80.2,
      80.2,
      80.3,
      80.3,
      80.3,
      80.3,
      80.4,
      80.4,
      80.5,
      80.5,
      80.6,
      80.7,
      80.8,
      81.0,
      81.1,
      81.2,
      81.3,
      81.3,
      81.4,
      81.5,
      81.6,
      81.6,
      81.7,
      81.8,
      81.8,
      81.9,
      81.9,
      82.0,
      82.1,
      82.1,
      82.2,
      82.3,
      82.4,
      82.4,
      82.5,
      82.6,
      82.7,
      82.8,
      82.8,
      82.9,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.8,
      83.9,
      84.1,
      84.2,
      84.4,
      84.5,
      84.7,
      84.9,
      85.1,
      85.3,
      85.5,
      85.7,
      85.9,
      86.1,
      86.3,
      86.6,
      86.8,
      87.1,
      87.3,
      87.6,
      87.9,
      88.1,
      88.4,
      88.7,
      89.1,
      89.4,
      89.7,
      90.1,
      90.5,
      90.9,
      91.3,
      91.7,
      92.2,
      92.7,
      93.3,
      93.8,
      94.5,
      95.1,
      95.8,
      96.5,
      97.2,
      97.9
    ],
    1949: [
      77.1,
      79.9,
      80.1,
      80.3,
      80.4,
      80.5,
      80.5,
      80.6,
      80.6,
      80.7,
      80.7,
      80.7,
      80.8,
      80.8,
      80.8,
      80.9,
      80.9,
      81.0,
      81.1,
      81.2,
      81.3,
      81.4,
      81.5,
      81.6,
      81.7,
      81.7,
      81.8,
      81.9,
      82.0,
      82.0,
      82.1,
      82.2,
      82.2,
      82.3,
      82.3,
      82.4,
      82.5,
      82.5,
      82.6,
      82.7,
      82.7,
      82.8,
      82.9,
      83.0,
      83.1,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.9,
      84.0,
      84.1,
      84.3,
      84.4,
      84.6,
      84.7,
      84.9,
      85.0,
      85.2,
      85.3,
      85.5,
      85.7,
      85.9,
      86.1,
      86.3,
      86.5,
      86.7,
      86.9,
      87.2,
      87.4,
      87.7,
      88.0,
      88.2,
      88.5,
      88.8,
      89.1,
      89.5,
      89.8,
      90.1,
      90.5,
      90.9,
      91.3,
      91.8,
      92.3,
      92.8,
      93.3,
      93.9,
      94.5,
      95.1,
      95.8,
      96.5,
      97.2,
      97.9
    ],
    1950: [
      77.2,
      79.8,
      80.1,
      80.3,
      80.4,
      80.4,
      80.5,
      80.5,
      80.6,
      80.6,
      80.7,
      80.7,
      80.8,
      80.8,
      80.8,
      80.9,
      80.9,
      81.0,
      81.1,
      81.2,
      81.3,
      81.4,
      81.5,
      81.6,
      81.7,
      81.7,
      81.8,
      81.9,
      81.9,
      82.0,
      82.1,
      82.1,
      82.2,
      82.3,
      82.3,
      82.4,
      82.5,
      82.5,
      82.6,
      82.7,
      82.8,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.8,
      84.0,
      84.1,
      84.2,
      84.3,
      84.5,
      84.6,
      84.8,
      84.9,
      85.1,
      85.2,
      85.4,
      85.6,
      85.8,
      86.0,
      86.2,
      86.4,
      86.6,
      86.8,
      87.1,
      87.3,
      87.5,
      87.8,
      88.1,
      88.3,
      88.6,
      88.9,
      89.2,
      89.5,
      89.9,
      90.2,
      90.6,
      91.0,
      91.4,
      91.8,
      92.3,
      92.8,
      93.3,
      93.9,
      94.5,
      95.1,
      95.8,
      96.5,
      97.2,
      98.0
    ],
    1951: [
      77.5,
      80.1,
      80.4,
      80.5,
      80.6,
      80.7,
      80.7,
      80.8,
      80.8,
      80.9,
      80.9,
      81.0,
      81.0,
      81.0,
      81.1,
      81.1,
      81.2,
      81.2,
      81.3,
      81.4,
      81.6,
      81.7,
      81.8,
      81.9,
      82.0,
      82.1,
      82.1,
      82.2,
      82.3,
      82.4,
      82.4,
      82.5,
      82.6,
      82.6,
      82.7,
      82.7,
      82.8,
      82.9,
      82.9,
      83.0,
      83.1,
      83.2,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.7,
      83.8,
      83.9,
      84.1,
      84.2,
      84.3,
      84.4,
      84.5,
      84.7,
      84.8,
      85.0,
      85.1,
      85.3,
      85.4,
      85.6,
      85.8,
      86.0,
      86.1,
      86.4,
      86.6,
      86.8,
      87.0,
      87.2,
      87.4,
      87.7,
      87.9,
      88.2,
      88.4,
      88.7,
      89.0,
      89.3,
      89.6,
      89.9,
      90.3,
      90.6,
      91.0,
      91.4,
      91.9,
      92.3,
      92.8,
      93.4,
      93.9,
      94.5,
      95.1,
      95.8,
      96.5,
      97.2,
      98.0
    ],
    1952: [
      77.7,
      80.2,
      80.4,
      80.5,
      80.6,
      80.7,
      80.7,
      80.8,
      80.8,
      80.8,
      80.9,
      80.9,
      80.9,
      81.0,
      81.0,
      81.0,
      81.1,
      81.1,
      81.2,
      81.4,
      81.5,
      81.6,
      81.7,
      81.8,
      81.9,
      82.0,
      82.1,
      82.2,
      82.3,
      82.3,
      82.4,
      82.5,
      82.6,
      82.6,
      82.7,
      82.8,
      82.8,
      82.9,
      83.0,
      83.1,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.5,
      83.6,
      83.7,
      83.8,
      83.9,
      84.1,
      84.1,
      84.2,
      84.4,
      84.5,
      84.6,
      84.8,
      84.9,
      85.1,
      85.2,
      85.4,
      85.6,
      85.7,
      85.9,
      86.1,
      86.3,
      86.5,
      86.7,
      86.9,
      87.1,
      87.3,
      87.5,
      87.8,
      88.0,
      88.3,
      88.5,
      88.8,
      89.1,
      89.4,
      89.7,
      90.0,
      90.3,
      90.7,
      91.1,
      91.5,
      91.9,
      92.4,
      92.9,
      93.4,
      93.9,
      94.5,
      95.1,
      95.8,
      96.5,
      97.2,
      98.0
    ],
    1953: [
      78.2,
      80.6,
      80.8,
      80.9,
      81.0,
      81.1,
      81.1,
      81.2,
      81.2,
      81.2,
      81.3,
      81.3,
      81.3,
      81.4,
      81.4,
      81.4,
      81.5,
      81.5,
      81.6,
      81.8,
      81.9,
      82.0,
      82.1,
      82.2,
      82.3,
      82.4,
      82.5,
      82.5,
      82.6,
      82.7,
      82.8,
      82.8,
      82.9,
      82.9,
      83.0,
      83.1,
      83.1,
      83.2,
      83.3,
      83.4,
      83.4,
      83.5,
      83.6,
      83.6,
      83.7,
      83.8,
      83.9,
      84.0,
      84.1,
      84.1,
      84.3,
      84.4,
      84.5,
      84.6,
      84.7,
      84.8,
      85.0,
      85.1,
      85.2,
      85.4,
      85.6,
      85.7,
      85.9,
      86.1,
      86.3,
      86.4,
      86.6,
      86.8,
      87.0,
      87.2,
      87.4,
      87.7,
      87.9,
      88.1,
      88.4,
      88.6,
      88.9,
      89.2,
      89.4,
      89.7,
      90.1,
      90.4,
      90.7,
      91.1,
      91.5,
      91.9,
      92.4,
      92.9,
      93.4,
      94.0,
      94.5,
      95.2,
      95.8,
      96.5,
      97.2,
      98.0
    ],
    1954: [
      78.5,
      80.8,
      81.0,
      81.1,
      81.2,
      81.3,
      81.3,
      81.3,
      81.4,
      81.4,
      81.4,
      81.5,
      81.5,
      81.5,
      81.6,
      81.6,
      81.6,
      81.7,
      81.8,
      81.9,
      82.1,
      82.2,
      82.3,
      82.4,
      82.5,
      82.5,
      82.6,
      82.7,
      82.8,
      82.8,
      82.9,
      82.9,
      83.0,
      83.1,
      83.1,
      83.2,
      83.3,
      83.3,
      83.4,
      83.5,
      83.6,
      83.6,
      83.7,
      83.8,
      83.9,
      84.0,
      84.1,
      84.2,
      84.3,
      84.3,
      84.4,
      84.5,
      84.7,
      84.8,
      84.9,
      85.0,
      85.2,
      85.3,
      85.4,
      85.6,
      85.7,
      85.9,
      86.1,
      86.3,
      86.4,
      86.6,
      86.8,
      86.9,
      87.1,
      87.3,
      87.5,
      87.8,
      88.0,
      88.2,
      88.5,
      88.7,
      89.0,
      89.2,
      89.5,
      89.8,
      90.1,
      90.5,
      90.8,
      91.2,
      91.6,
      92.0,
      92.4,
      92.9,
      93.4,
      94.0,
      94.6,
      95.2,
      95.8,
      96.5,
      97.2,
      98.0
    ],
    1955: [
      78.6,
      81.0,
      81.1,
      81.3,
      81.4,
      81.4,
      81.5,
      81.5,
      81.6,
      81.6,
      81.6,
      81.6,
      81.7,
      81.7,
      81.7,
      81.8,
      81.8,
      81.9,
      82.0,
      82.2,
      82.3,
      82.4,
      82.5,
      82.6,
      82.7,
      82.8,
      82.9,
      82.9,
      83.0,
      83.0,
      83.1,
      83.1,
      83.2,
      83.3,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.7,
      83.8,
      83.9,
      83.9,
      84.0,
      84.1,
      84.2,
      84.3,
      84.3,
      84.4,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.1,
      85.2,
      85.3,
      85.5,
      85.6,
      85.8,
      85.9,
      86.1,
      86.2,
      86.4,
      86.6,
      86.7,
      86.9,
      87.1,
      87.3,
      87.5,
      87.7,
      87.9,
      88.1,
      88.3,
      88.6,
      88.8,
      89.1,
      89.3,
      89.6,
      89.9,
      90.2,
      90.5,
      90.9,
      91.2,
      91.6,
      92.0,
      92.5,
      92.9,
      93.5,
      94.0,
      94.6,
      95.2,
      95.8,
      96.5,
      97.2,
      98.0
    ],
    1956: [
      78.9,
      80.9,
      81.1,
      81.3,
      81.3,
      81.4,
      81.5,
      81.5,
      81.5,
      81.5,
      81.6,
      81.6,
      81.6,
      81.7,
      81.7,
      81.8,
      81.8,
      81.9,
      82.0,
      82.1,
      82.2,
      82.4,
      82.5,
      82.6,
      82.7,
      82.8,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.2,
      83.3,
      83.4,
      83.4,
      83.5,
      83.6,
      83.7,
      83.7,
      83.8,
      83.9,
      84.0,
      84.0,
      84.1,
      84.2,
      84.3,
      84.4,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.1,
      85.2,
      85.3,
      85.5,
      85.6,
      85.8,
      85.9,
      86.1,
      86.2,
      86.4,
      86.5,
      86.7,
      86.9,
      87.0,
      87.2,
      87.4,
      87.6,
      87.8,
      88.0,
      88.2,
      88.4,
      88.7,
      88.9,
      89.1,
      89.4,
      89.7,
      90.0,
      90.3,
      90.6,
      90.9,
      91.3,
      91.7,
      92.1,
      92.5,
      93.0,
      93.5,
      94.0,
      94.6,
      95.2,
      95.9,
      96.5,
      97.3,
      98.0
    ],
    1957: [
      78.7,
      81.0,
      81.2,
      81.3,
      81.4,
      81.4,
      81.5,
      81.5,
      81.5,
      81.6,
      81.6,
      81.7,
      81.7,
      81.7,
      81.8,
      81.8,
      81.9,
      82.0,
      82.1,
      82.2,
      82.3,
      82.4,
      82.5,
      82.6,
      82.7,
      82.8,
      82.9,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.3,
      83.4,
      83.5,
      83.5,
      83.6,
      83.7,
      83.8,
      83.8,
      83.9,
      84.0,
      84.1,
      84.1,
      84.2,
      84.3,
      84.4,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.1,
      85.2,
      85.3,
      85.5,
      85.6,
      85.7,
      85.9,
      86.1,
      86.2,
      86.4,
      86.5,
      86.7,
      86.8,
      87.0,
      87.2,
      87.3,
      87.5,
      87.7,
      87.9,
      88.1,
      88.3,
      88.5,
      88.7,
      89.0,
      89.2,
      89.5,
      89.7,
      90.0,
      90.3,
      90.6,
      91.0,
      91.3,
      91.7,
      92.1,
      92.5,
      93.0,
      93.5,
      94.0,
      94.6,
      95.2,
      95.9,
      96.6,
      97.3,
      98.0
    ],
    1958: [
      79.1,
      81.3,
      81.5,
      81.6,
      81.6,
      81.7,
      81.7,
      81.8,
      81.8,
      81.9,
      81.9,
      81.9,
      81.9,
      82.0,
      82.0,
      82.1,
      82.1,
      82.2,
      82.3,
      82.5,
      82.6,
      82.7,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.6,
      83.7,
      83.8,
      83.8,
      83.9,
      84.0,
      84.0,
      84.1,
      84.2,
      84.2,
      84.3,
      84.4,
      84.4,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.1,
      85.2,
      85.3,
      85.4,
      85.5,
      85.7,
      85.8,
      85.9,
      86.1,
      86.3,
      86.4,
      86.5,
      86.7,
      86.8,
      87.0,
      87.1,
      87.3,
      87.5,
      87.6,
      87.8,
      88.0,
      88.2,
      88.4,
      88.6,
      88.8,
      89.1,
      89.3,
      89.6,
      89.8,
      90.1,
      90.4,
      90.7,
      91.0,
      91.4,
      91.7,
      92.1,
      92.6,
      93.0,
      93.5,
      94.1,
      94.6,
      95.2,
      95.9,
      96.6,
      97.3,
      98.0
    ],
    1959: [
      79.5,
      81.7,
      81.9,
      82.0,
      82.0,
      82.1,
      82.1,
      82.2,
      82.2,
      82.2,
      82.3,
      82.3,
      82.3,
      82.3,
      82.4,
      82.4,
      82.5,
      82.6,
      82.7,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.6,
      83.7,
      83.8,
      83.9,
      84.0,
      84.0,
      84.1,
      84.2,
      84.2,
      84.3,
      84.3,
      84.4,
      84.5,
      84.6,
      84.6,
      84.7,
      84.8,
      84.9,
      84.9,
      85.0,
      85.1,
      85.2,
      85.3,
      85.4,
      85.5,
      85.6,
      85.7,
      85.9,
      86.0,
      86.2,
      86.3,
      86.4,
      86.5,
      86.7,
      86.8,
      87.0,
      87.1,
      87.3,
      87.4,
      87.6,
      87.8,
      87.9,
      88.1,
      88.3,
      88.5,
      88.7,
      88.9,
      89.2,
      89.4,
      89.6,
      89.9,
      90.2,
      90.4,
      90.7,
      91.1,
      91.4,
      91.8,
      92.2,
      92.6,
      93.1,
      93.6,
      94.1,
      94.7,
      95.3,
      95.9,
      96.6,
      97.3,
      98.0
    ],
    1960: [
      79.8,
      81.9,
      82.1,
      82.2,
      82.2,
      82.3,
      82.3,
      82.4,
      82.4,
      82.4,
      82.5,
      82.5,
      82.5,
      82.5,
      82.6,
      82.6,
      82.7,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.8,
      83.8,
      83.9,
      84.0,
      84.1,
      84.1,
      84.2,
      84.2,
      84.3,
      84.4,
      84.4,
      84.5,
      84.6,
      84.6,
      84.7,
      84.8,
      84.9,
      84.9,
      85.0,
      85.1,
      85.2,
      85.3,
      85.3,
      85.4,
      85.6,
      85.7,
      85.8,
      85.9,
      86.0,
      86.2,
      86.3,
      86.4,
      86.6,
      86.7,
      86.8,
      87.0,
      87.1,
      87.2,
      87.4,
      87.5,
      87.7,
      87.9,
      88.1,
      88.2,
      88.4,
      88.6,
      88.8,
      89.0,
      89.3,
      89.5,
      89.7,
      90.0,
      90.2,
      90.5,
      90.8,
      91.1,
      91.5,
      91.8,
      92.2,
      92.6,
      93.1,
      93.6,
      94.1,
      94.7,
      95.3,
      95.9,
      96.6,
      97.3,
      98.0
    ],
    1961: [
      80,
      82.1,
      82.3,
      82.4,
      82.5,
      82.5,
      82.6,
      82.6,
      82.7,
      82.7,
      82.7,
      82.7,
      82.8,
      82.8,
      82.8,
      82.9,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.4,
      83.6,
      83.7,
      83.8,
      83.8,
      83.9,
      84.0,
      84.1,
      84.2,
      84.2,
      84.3,
      84.4,
      84.4,
      84.5,
      84.6,
      84.6,
      84.7,
      84.8,
      84.8,
      84.9,
      85.0,
      85.0,
      85.1,
      85.2,
      85.2,
      85.3,
      85.4,
      85.5,
      85.6,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.2,
      86.4,
      86.5,
      86.6,
      86.7,
      86.8,
      87.0,
      87.1,
      87.2,
      87.4,
      87.5,
      87.7,
      87.8,
      88.0,
      88.2,
      88.3,
      88.5,
      88.7,
      88.9,
      89.1,
      89.3,
      89.6,
      89.8,
      90.0,
      90.3,
      90.6,
      90.9,
      91.2,
      91.5,
      91.9,
      92.3,
      92.7,
      93.1,
      93.6,
      94.1,
      94.7,
      95.3,
      95.9,
      96.6,
      97.3,
      98.0
    ],
    1962: [
      80.4,
      82.3,
      82.5,
      82.5,
      82.6,
      82.7,
      82.7,
      82.8,
      82.8,
      82.8,
      82.9,
      82.9,
      82.9,
      83.0,
      83.0,
      83.0,
      83.1,
      83.1,
      83.2,
      83.4,
      83.5,
      83.6,
      83.7,
      83.7,
      83.8,
      83.9,
      84.0,
      84.1,
      84.2,
      84.3,
      84.3,
      84.4,
      84.5,
      84.5,
      84.6,
      84.7,
      84.8,
      84.8,
      84.9,
      84.9,
      85.0,
      85.1,
      85.2,
      85.2,
      85.3,
      85.4,
      85.5,
      85.5,
      85.6,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.9,
      87.0,
      87.1,
      87.2,
      87.4,
      87.5,
      87.7,
      87.8,
      88.0,
      88.1,
      88.3,
      88.5,
      88.6,
      88.8,
      89.0,
      89.2,
      89.4,
      89.6,
      89.9,
      90.1,
      90.4,
      90.6,
      90.9,
      91.2,
      91.6,
      91.9,
      92.3,
      92.7,
      93.2,
      93.6,
      94.2,
      94.7,
      95.3,
      95.9,
      96.6,
      97.3,
      98.0
    ],
    1963: [
      80.5,
      82.4,
      82.5,
      82.6,
      82.7,
      82.7,
      82.8,
      82.9,
      82.9,
      82.9,
      83.0,
      83.0,
      83.0,
      83.0,
      83.1,
      83.1,
      83.2,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.9,
      84.0,
      84.1,
      84.2,
      84.3,
      84.4,
      84.4,
      84.5,
      84.6,
      84.7,
      84.7,
      84.8,
      84.9,
      84.9,
      85.0,
      85.0,
      85.1,
      85.2,
      85.2,
      85.3,
      85.4,
      85.4,
      85.5,
      85.6,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.7,
      86.8,
      86.9,
      87.0,
      87.1,
      87.3,
      87.4,
      87.5,
      87.6,
      87.8,
      87.9,
      88.1,
      88.2,
      88.4,
      88.6,
      88.7,
      88.9,
      89.1,
      89.3,
      89.5,
      89.7,
      90.0,
      90.2,
      90.4,
      90.7,
      91.0,
      91.3,
      91.6,
      92.0,
      92.3,
      92.7,
      93.2,
      93.7,
      94.2,
      94.7,
      95.3,
      96.0,
      96.6,
      97.3,
      98.1
    ],
    1964: [
      80.8,
      82.6,
      82.7,
      82.8,
      82.9,
      83.0,
      83.0,
      83.1,
      83.1,
      83.1,
      83.2,
      83.2,
      83.2,
      83.2,
      83.3,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.8,
      83.9,
      84.0,
      84.1,
      84.2,
      84.3,
      84.4,
      84.5,
      84.6,
      84.7,
      84.7,
      84.8,
      84.9,
      84.9,
      85.0,
      85.1,
      85.1,
      85.2,
      85.2,
      85.3,
      85.4,
      85.4,
      85.5,
      85.6,
      85.6,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      87.0,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.8,
      87.9,
      88.0,
      88.2,
      88.3,
      88.5,
      88.7,
      88.8,
      89.0,
      89.2,
      89.4,
      89.6,
      89.8,
      90.0,
      90.3,
      90.5,
      90.8,
      91.0,
      91.3,
      91.7,
      92.0,
      92.4,
      92.8,
      93.2,
      93.7,
      94.2,
      94.8,
      95.3,
      96.0,
      96.6,
      97.3,
      98.1
    ],
    1965: [
      81,
      82.9,
      83.0,
      83.1,
      83.2,
      83.2,
      83.3,
      83.3,
      83.4,
      83.4,
      83.4,
      83.4,
      83.5,
      83.5,
      83.5,
      83.6,
      83.6,
      83.7,
      83.7,
      83.8,
      83.9,
      84.0,
      84.2,
      84.3,
      84.4,
      84.5,
      84.6,
      84.7,
      84.7,
      84.8,
      84.9,
      85.0,
      85.1,
      85.1,
      85.2,
      85.3,
      85.3,
      85.4,
      85.4,
      85.5,
      85.6,
      85.6,
      85.7,
      85.8,
      85.8,
      85.9,
      86.0,
      86.1,
      86.2,
      86.2,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.9,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.8,
      87.9,
      88.0,
      88.2,
      88.3,
      88.5,
      88.6,
      88.8,
      88.9,
      89.1,
      89.3,
      89.5,
      89.7,
      89.9,
      90.1,
      90.3,
      90.6,
      90.8,
      91.1,
      91.4,
      91.7,
      92.0,
      92.4,
      92.8,
      93.3,
      93.7,
      94.2,
      94.8,
      95.4,
      96.0,
      96.6,
      97.3,
      98.1
    ],
    1966: [
      81.2,
      82.9,
      83.1,
      83.2,
      83.3,
      83.3,
      83.3,
      83.4,
      83.4,
      83.4,
      83.5,
      83.5,
      83.5,
      83.5,
      83.6,
      83.6,
      83.6,
      83.7,
      83.8,
      83.9,
      84.1,
      84.2,
      84.3,
      84.4,
      84.5,
      84.7,
      84.8,
      84.8,
      84.9,
      85.0,
      85.0,
      85.1,
      85.2,
      85.2,
      85.3,
      85.4,
      85.4,
      85.5,
      85.6,
      85.6,
      85.7,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.3,
      88.4,
      88.6,
      88.7,
      88.9,
      89.0,
      89.2,
      89.4,
      89.6,
      89.8,
      90.0,
      90.2,
      90.4,
      90.6,
      90.9,
      91.2,
      91.4,
      91.8,
      92.1,
      92.5,
      92.9,
      93.3,
      93.7,
      94.2,
      94.8,
      95.4,
      96.0,
      96.7,
      97.3,
      98.1
    ],
    1967: [
      81.4,
      83.2,
      83.3,
      83.4,
      83.5,
      83.5,
      83.5,
      83.6,
      83.6,
      83.7,
      83.7,
      83.7,
      83.7,
      83.7,
      83.8,
      83.8,
      83.9,
      83.9,
      84.0,
      84.2,
      84.3,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.1,
      85.1,
      85.2,
      85.3,
      85.3,
      85.4,
      85.5,
      85.5,
      85.6,
      85.6,
      85.7,
      85.8,
      85.8,
      85.9,
      85.9,
      86.0,
      86.1,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.3,
      88.4,
      88.5,
      88.7,
      88.8,
      89.0,
      89.1,
      89.3,
      89.5,
      89.7,
      89.9,
      90.0,
      90.3,
      90.5,
      90.7,
      90.9,
      91.2,
      91.5,
      91.8,
      92.1,
      92.5,
      92.9,
      93.3,
      93.8,
      94.3,
      94.8,
      95.4,
      96.0,
      96.7,
      97.4,
      98.1
    ],
    1968: [
      81.6,
      83.4,
      83.5,
      83.6,
      83.7,
      83.7,
      83.7,
      83.8,
      83.8,
      83.8,
      83.9,
      83.9,
      83.9,
      83.9,
      84.0,
      84.0,
      84.0,
      84.1,
      84.2,
      84.3,
      84.4,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.1,
      85.2,
      85.3,
      85.3,
      85.4,
      85.4,
      85.5,
      85.6,
      85.7,
      85.7,
      85.8,
      85.9,
      86.0,
      86.0,
      86.1,
      86.1,
      86.2,
      86.3,
      86.3,
      86.4,
      86.5,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.3,
      88.4,
      88.5,
      88.6,
      88.8,
      88.9,
      89.1,
      89.2,
      89.4,
      89.6,
      89.8,
      89.9,
      90.1,
      90.3,
      90.5,
      90.8,
      91.0,
      91.3,
      91.5,
      91.8,
      92.2,
      92.5,
      92.9,
      93.3,
      93.8,
      94.3,
      94.8,
      95.4,
      96.0,
      96.7,
      97.4,
      98.1
    ],
    1969: [
      81.9,
      83.5,
      83.7,
      83.8,
      83.9,
      83.9,
      84.0,
      84.0,
      84.0,
      84.1,
      84.1,
      84.1,
      84.1,
      84.1,
      84.2,
      84.2,
      84.3,
      84.3,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.2,
      85.2,
      85.3,
      85.4,
      85.5,
      85.6,
      85.6,
      85.7,
      85.8,
      85.8,
      85.9,
      86.0,
      86.0,
      86.1,
      86.1,
      86.2,
      86.3,
      86.3,
      86.4,
      86.5,
      86.5,
      86.6,
      86.7,
      86.7,
      86.8,
      86.9,
      87.0,
      87.1,
      87.2,
      87.3,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.8,
      88.9,
      89.0,
      89.2,
      89.3,
      89.5,
      89.7,
      89.8,
      90.0,
      90.2,
      90.4,
      90.6,
      90.8,
      91.1,
      91.3,
      91.6,
      91.9,
      92.2,
      92.6,
      93.0,
      93.4,
      93.8,
      94.3,
      94.8,
      95.4,
      96.0,
      96.7,
      97.4,
      98.1
    ],
    1970: [
      82.2,
      83.7,
      83.9,
      84.0,
      84.1,
      84.1,
      84.2,
      84.2,
      84.2,
      84.2,
      84.3,
      84.3,
      84.3,
      84.3,
      84.4,
      84.4,
      84.5,
      84.5,
      84.6,
      84.7,
      84.9,
      85.0,
      85.1,
      85.2,
      85.3,
      85.4,
      85.5,
      85.6,
      85.7,
      85.7,
      85.8,
      85.9,
      85.9,
      86.0,
      86.0,
      86.1,
      86.1,
      86.2,
      86.3,
      86.3,
      86.4,
      86.5,
      86.5,
      86.6,
      86.7,
      86.7,
      86.8,
      86.9,
      87.0,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.9,
      89.0,
      89.1,
      89.3,
      89.4,
      89.6,
      89.8,
      89.9,
      90.1,
      90.3,
      90.5,
      90.7,
      90.9,
      91.1,
      91.4,
      91.6,
      91.9,
      92.3,
      92.6,
      93.0,
      93.4,
      93.9,
      94.3,
      94.9,
      95.4,
      96.0,
      96.7,
      97.4,
      98.1
    ],
    1971: [
      82.6,
      84.1,
      84.2,
      84.3,
      84.3,
      84.4,
      84.4,
      84.4,
      84.5,
      84.5,
      84.5,
      84.5,
      84.5,
      84.5,
      84.6,
      84.6,
      84.7,
      84.7,
      84.9,
      85.0,
      85.1,
      85.2,
      85.3,
      85.4,
      85.5,
      85.6,
      85.7,
      85.8,
      85.9,
      85.9,
      86.0,
      86.1,
      86.1,
      86.2,
      86.2,
      86.3,
      86.3,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.7,
      86.8,
      86.8,
      86.9,
      87.0,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.9,
      89.0,
      89.1,
      89.2,
      89.4,
      89.5,
      89.7,
      89.8,
      90.0,
      90.2,
      90.4,
      90.5,
      90.7,
      91.0,
      91.2,
      91.4,
      91.7,
      92.0,
      92.3,
      92.6,
      93.0,
      93.4,
      93.9,
      94.4,
      94.9,
      95.5,
      96.1,
      96.7,
      97.4,
      98.1
    ],
    1972: [
      82.7,
      84.2,
      84.3,
      84.4,
      84.5,
      84.6,
      84.6,
      84.6,
      84.6,
      84.7,
      84.7,
      84.7,
      84.7,
      84.8,
      84.8,
      84.8,
      84.9,
      84.9,
      85.1,
      85.1,
      85.2,
      85.3,
      85.5,
      85.6,
      85.7,
      85.8,
      85.9,
      85.9,
      86.0,
      86.1,
      86.1,
      86.2,
      86.2,
      86.3,
      86.3,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.7,
      86.7,
      86.8,
      86.9,
      87.0,
      87.0,
      87.1,
      87.2,
      87.3,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.5,
      89.6,
      89.8,
      89.9,
      90.1,
      90.3,
      90.4,
      90.6,
      90.8,
      91.0,
      91.2,
      91.5,
      91.8,
      92.0,
      92.3,
      92.7,
      93.1,
      93.5,
      93.9,
      94.4,
      94.9,
      95.5,
      96.1,
      96.7,
      97.4,
      98.1
    ],
    1973: [
      83,
      84.6,
      84.7,
      84.8,
      84.8,
      84.9,
      84.9,
      85.0,
      85.0,
      85.0,
      85.0,
      85.1,
      85.1,
      85.1,
      85.1,
      85.2,
      85.2,
      85.3,
      85.4,
      85.5,
      85.6,
      85.7,
      85.8,
      85.9,
      85.9,
      86.0,
      86.1,
      86.2,
      86.2,
      86.3,
      86.4,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.7,
      86.8,
      86.8,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.3,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.6,
      89.7,
      89.9,
      90.0,
      90.2,
      90.3,
      90.5,
      90.7,
      90.9,
      91.1,
      91.3,
      91.5,
      91.8,
      92.1,
      92.4,
      92.7,
      93.1,
      93.5,
      93.9,
      94.4,
      94.9,
      95.5,
      96.1,
      96.7,
      97.4,
      98.1
    ],
    1974: [
      83.2,
      84.8,
      84.9,
      84.9,
      85.0,
      85.0,
      85.1,
      85.1,
      85.1,
      85.1,
      85.2,
      85.2,
      85.2,
      85.2,
      85.3,
      85.3,
      85.3,
      85.4,
      85.5,
      85.6,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.1,
      86.3,
      86.3,
      86.4,
      86.4,
      86.5,
      86.5,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.7,
      89.8,
      90.0,
      90.1,
      90.3,
      90.4,
      90.6,
      90.8,
      91.0,
      91.2,
      91.4,
      91.6,
      91.9,
      92.1,
      92.4,
      92.8,
      93.1,
      93.5,
      94.0,
      94.4,
      94.9,
      95.5,
      96.1,
      96.7,
      97.4,
      98.1
    ],
    1975: [
      83.6,
      85.1,
      85.2,
      85.3,
      85.3,
      85.3,
      85.4,
      85.4,
      85.4,
      85.5,
      85.5,
      85.5,
      85.5,
      85.6,
      85.6,
      85.6,
      85.7,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.2,
      86.3,
      86.3,
      86.4,
      86.5,
      86.5,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.4,
      87.4,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.9,
      87.9,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.4,
      88.5,
      88.6,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.8,
      89.9,
      90.0,
      90.2,
      90.3,
      90.5,
      90.7,
      90.8,
      91.0,
      91.2,
      91.4,
      91.7,
      91.9,
      92.2,
      92.5,
      92.8,
      93.2,
      93.5,
      94.0,
      94.4,
      95.0,
      95.5,
      96.1,
      96.8,
      97.4,
      98.2
    ],
    1976: [
      83.9,
      85.3,
      85.4,
      85.4,
      85.5,
      85.5,
      85.6,
      85.6,
      85.6,
      85.6,
      85.6,
      85.7,
      85.7,
      85.7,
      85.7,
      85.8,
      85.8,
      85.9,
      85.9,
      86.0,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.5,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.5,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.9,
      87.9,
      88.0,
      88.1,
      88.2,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.7,
      89.9,
      90.0,
      90.1,
      90.3,
      90.4,
      90.6,
      90.7,
      90.9,
      91.1,
      91.3,
      91.5,
      91.7,
      92.0,
      92.2,
      92.5,
      92.8,
      93.2,
      93.6,
      94.0,
      94.5,
      95.0,
      95.5,
      96.1,
      96.8,
      97.4,
      98.2
    ],
    1977: [
      84.1,
      85.5,
      85.6,
      85.7,
      85.7,
      85.8,
      85.8,
      85.8,
      85.8,
      85.8,
      85.9,
      85.9,
      85.9,
      85.9,
      85.9,
      86.0,
      86.0,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.4,
      87.5,
      87.5,
      87.6,
      87.7,
      87.7,
      87.8,
      87.9,
      87.9,
      88.0,
      88.1,
      88.1,
      88.2,
      88.3,
      88.4,
      88.4,
      88.5,
      88.6,
      88.7,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.7,
      89.8,
      90.0,
      90.1,
      90.2,
      90.4,
      90.5,
      90.7,
      90.8,
      91.0,
      91.2,
      91.3,
      91.5,
      91.8,
      92.0,
      92.3,
      92.6,
      92.9,
      93.2,
      93.6,
      94.0,
      94.5,
      95.0,
      95.5,
      96.1,
      96.8,
      97.5,
      98.2
    ],
    1978: [
      84.4,
      85.8,
      85.9,
      85.9,
      86.0,
      86.0,
      86.1,
      86.1,
      86.1,
      86.1,
      86.1,
      86.2,
      86.2,
      86.2,
      86.2,
      86.2,
      86.3,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.4,
      87.5,
      87.5,
      87.6,
      87.6,
      87.7,
      87.7,
      87.8,
      87.9,
      87.9,
      88.0,
      88.1,
      88.1,
      88.2,
      88.3,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.7,
      89.8,
      89.9,
      90.1,
      90.2,
      90.3,
      90.4,
      90.6,
      90.7,
      90.9,
      91.0,
      91.2,
      91.4,
      91.6,
      91.8,
      92.1,
      92.3,
      92.6,
      92.9,
      93.3,
      93.6,
      94.1,
      94.5,
      95.0,
      95.6,
      96.2,
      96.8,
      97.5,
      98.2
    ],
    1979: [
      84.6,
      85.9,
      86.0,
      86.0,
      86.1,
      86.1,
      86.1,
      86.1,
      86.2,
      86.2,
      86.2,
      86.2,
      86.2,
      86.3,
      86.3,
      86.3,
      86.4,
      86.4,
      86.5,
      86.6,
      86.7,
      86.7,
      86.8,
      86.9,
      87.0,
      87.0,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.4,
      87.5,
      87.5,
      87.6,
      87.6,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.1,
      88.1,
      88.2,
      88.3,
      88.3,
      88.4,
      88.5,
      88.6,
      88.6,
      88.7,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.3,
      89.4,
      89.5,
      89.6,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.3,
      90.4,
      90.5,
      90.7,
      90.8,
      91.0,
      91.1,
      91.3,
      91.5,
      91.7,
      91.9,
      92.1,
      92.4,
      92.6,
      92.9,
      93.3,
      93.7,
      94.1,
      94.5,
      95.0,
      95.6,
      96.2,
      96.8,
      97.5,
      98.2
    ],
    1980: [
      84.9,
      86.0,
      86.1,
      86.2,
      86.3,
      86.3,
      86.3,
      86.3,
      86.4,
      86.4,
      86.4,
      86.4,
      86.4,
      86.5,
      86.5,
      86.5,
      86.5,
      86.7,
      86.7,
      86.8,
      86.9,
      86.9,
      87.0,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.1,
      88.2,
      88.3,
      88.3,
      88.4,
      88.5,
      88.5,
      88.6,
      88.7,
      88.8,
      88.8,
      88.9,
      89.0,
      89.1,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.4,
      90.5,
      90.6,
      90.7,
      90.9,
      91.0,
      91.2,
      91.3,
      91.5,
      91.7,
      91.9,
      92.2,
      92.4,
      92.7,
      93.0,
      93.3,
      93.7,
      94.1,
      94.6,
      95.1,
      95.6,
      96.2,
      96.8,
      97.5,
      98.2
    ],
    1981: [
      85.1,
      86.3,
      86.3,
      86.4,
      86.4,
      86.5,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.6,
      86.6,
      86.7,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.4,
      87.4,
      87.5,
      87.5,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.5,
      88.5,
      88.6,
      88.7,
      88.7,
      88.8,
      88.9,
      89.0,
      89.0,
      89.1,
      89.2,
      89.3,
      89.3,
      89.4,
      89.5,
      89.6,
      89.6,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.6,
      90.7,
      90.8,
      91.0,
      91.1,
      91.2,
      91.4,
      91.6,
      91.8,
      92.0,
      92.2,
      92.4,
      92.7,
      93.0,
      93.4,
      93.7,
      94.1,
      94.6,
      95.1,
      95.6,
      96.2,
      96.8,
      97.5,
      98.2
    ],
    1982: [
      85.4,
      86.5,
      86.6,
      86.7,
      86.7,
      86.7,
      86.8,
      86.8,
      86.8,
      86.9,
      86.9,
      86.9,
      86.9,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.3,
      87.3,
      87.4,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.7,
      88.7,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.1,
      89.2,
      89.3,
      89.4,
      89.4,
      89.5,
      89.6,
      89.7,
      89.8,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.8,
      90.9,
      91.0,
      91.2,
      91.3,
      91.5,
      91.6,
      91.8,
      92.0,
      92.2,
      92.5,
      92.8,
      93.1,
      93.4,
      93.8,
      94.2,
      94.6,
      95.1,
      95.6,
      96.2,
      96.8,
      97.5,
      98.2
    ],
    1983: [
      85.4,
      86.5,
      86.6,
      86.7,
      86.8,
      86.8,
      86.8,
      86.8,
      86.9,
      86.9,
      86.9,
      86.9,
      86.9,
      86.9,
      87.0,
      87.0,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.4,
      87.5,
      87.6,
      87.6,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.1,
      89.2,
      89.3,
      89.3,
      89.4,
      89.5,
      89.6,
      89.6,
      89.7,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.8,
      91.0,
      91.1,
      91.2,
      91.4,
      91.5,
      91.7,
      91.9,
      92.1,
      92.3,
      92.5,
      92.8,
      93.1,
      93.4,
      93.8,
      94.2,
      94.6,
      95.1,
      95.6,
      96.2,
      96.8,
      97.5,
      98.2
    ],
    1984: [
      85.6,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.0,
      87.1,
      87.1,
      87.1,
      87.1,
      87.1,
      87.1,
      87.2,
      87.2,
      87.2,
      87.3,
      87.4,
      87.4,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.7,
      89.8,
      89.9,
      90.0,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.8,
      90.9,
      91.0,
      91.2,
      91.3,
      91.4,
      91.6,
      91.8,
      91.9,
      92.1,
      92.3,
      92.6,
      92.8,
      93.1,
      93.5,
      93.8,
      94.2,
      94.7,
      95.1,
      95.7,
      96.2,
      96.9,
      97.5,
      98.2
    ],
    1985: [
      85.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.2,
      87.2,
      87.2,
      87.2,
      87.3,
      87.3,
      87.3,
      87.3,
      87.4,
      87.4,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.9,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.4,
      89.4,
      89.5,
      89.6,
      89.6,
      89.7,
      89.8,
      89.8,
      89.9,
      90.0,
      90.1,
      90.1,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.4,
      91.5,
      91.7,
      91.8,
      92.0,
      92.2,
      92.4,
      92.6,
      92.9,
      93.2,
      93.5,
      93.8,
      94.2,
      94.7,
      95.2,
      95.7,
      96.3,
      96.9,
      97.5,
      98.2
    ],
    1986: [
      86,
      87.0,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.3,
      87.3,
      87.3,
      87.3,
      87.4,
      87.4,
      87.4,
      87.4,
      87.4,
      87.5,
      87.5,
      87.6,
      87.7,
      87.8,
      87.8,
      87.9,
      88.0,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.7,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.4,
      91.6,
      91.7,
      91.9,
      92.0,
      92.2,
      92.4,
      92.7,
      92.9,
      93.2,
      93.5,
      93.9,
      94.3,
      94.7,
      95.2,
      95.7,
      96.3,
      96.9,
      97.5,
      98.2
    ],
    1987: [
      86.2,
      87.1,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.4,
      87.4,
      87.4,
      87.4,
      87.4,
      87.5,
      87.5,
      87.5,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.0,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.7,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.1,
      90.2,
      90.3,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      91.0,
      91.1,
      91.3,
      91.4,
      91.5,
      91.6,
      91.8,
      91.9,
      92.1,
      92.3,
      92.5,
      92.7,
      93.0,
      93.2,
      93.6,
      93.9,
      94.3,
      94.7,
      95.2,
      95.7,
      96.3,
      96.9,
      97.6,
      98.3
    ],
    1988: [
      86.4,
      87.4,
      87.5,
      87.6,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.7,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.8,
      87.9,
      88.0,
      88.0,
      88.1,
      88.2,
      88.2,
      88.3,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      90.0,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.4,
      90.4,
      90.5,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.5,
      91.6,
      91.7,
      91.8,
      92.0,
      92.2,
      92.3,
      92.5,
      92.8,
      93.0,
      93.3,
      93.6,
      93.9,
      94.3,
      94.7,
      95.2,
      95.7,
      96.3,
      96.9,
      97.6,
      98.3
    ],
    1989: [
      86.5,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.8,
      87.8,
      87.8,
      87.9,
      87.9,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.1,
      90.2,
      90.3,
      90.3,
      90.4,
      90.5,
      90.5,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.8,
      91.9,
      92.0,
      92.2,
      92.4,
      92.6,
      92.8,
      93.0,
      93.3,
      93.6,
      94.0,
      94.3,
      94.8,
      95.2,
      95.7,
      96.3,
      96.9,
      97.6,
      98.3
    ],
    1990: [
      87,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.0,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.7,
      90.8,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.7,
      91.8,
      92.0,
      92.1,
      92.3,
      92.4,
      92.6,
      92.8,
      93.1,
      93.4,
      93.7,
      94.0,
      94.4,
      94.8,
      95.2,
      95.8,
      96.3,
      96.9,
      97.6,
      98.3
    ],
    1991: [
      87.1,
      87.9,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.8,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.3,
      91.4,
      91.5,
      91.7,
      91.8,
      91.9,
      92.0,
      92.2,
      92.3,
      92.5,
      92.7,
      92.9,
      93.1,
      93.4,
      93.7,
      94.0,
      94.4,
      94.8,
      95.3,
      95.8,
      96.3,
      96.9,
      97.6,
      98.3
    ],
    1992: [
      87.4,
      88.1,
      88.2,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.3,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      90.9,
      91.0,
      91.1,
      91.2,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.7,
      91.8,
      92.0,
      92.1,
      92.2,
      92.4,
      92.5,
      92.7,
      92.9,
      93.2,
      93.4,
      93.7,
      94.0,
      94.4,
      94.8,
      95.3,
      95.8,
      96.3,
      97.0,
      97.6,
      98.3
    ],
    1993: [
      87.6,
      88.3,
      88.4,
      88.4,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.5,
      88.5,
      88.5,
      88.5,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.1,
      91.2,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.3,
      92.4,
      92.6,
      92.8,
      93.0,
      93.2,
      93.5,
      93.7,
      94.1,
      94.4,
      94.9,
      95.3,
      95.8,
      96.4,
      97.0,
      97.6,
      98.3
    ],
    1994: [
      87.7,
      88.4,
      88.4,
      88.5,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.6,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.2,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.2,
      92.3,
      92.5,
      92.6,
      92.8,
      93.0,
      93.2,
      93.5,
      93.8,
      94.1,
      94.5,
      94.9,
      95.3,
      95.8,
      96.4,
      97.0,
      97.6,
      98.3
    ],
    1995: [
      87.9,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.8,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.1,
      91.1,
      91.2,
      91.3,
      91.3,
      91.4,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.3,
      92.4,
      92.5,
      92.7,
      92.9,
      93.1,
      93.3,
      93.5,
      93.8,
      94.1,
      94.5,
      94.9,
      95.3,
      95.8,
      96.4,
      97.0,
      97.6,
      98.3
    ],
    1996: [
      88,
      88.7,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      88.9,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.3,
      91.3,
      91.4,
      91.5,
      91.5,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.2,
      92.3,
      92.4,
      92.6,
      92.7,
      92.9,
      93.1,
      93.3,
      93.6,
      93.8,
      94.2,
      94.5,
      94.9,
      95.4,
      95.9,
      96.4,
      97.0,
      97.6,
      98.3
    ],
    1997: [
      88.3,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.0,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.1,
      89.1,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.4,
      91.4,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.0,
      92.1,
      92.3,
      92.4,
      92.5,
      92.6,
      92.8,
      92.9,
      93.1,
      93.4,
      93.6,
      93.9,
      94.2,
      94.5,
      94.9,
      95.4,
      95.9,
      96.4,
      97.0,
      97.7,
      98.3
    ],
    1998: [
      88.3,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.1,
      89.1,
      89.1,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.9,
      91.9,
      92.0,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.7,
      92.8,
      93.0,
      93.2,
      93.4,
      93.6,
      93.9,
      94.2,
      94.6,
      95.0,
      95.4,
      95.9,
      96.4,
      97.0,
      97.7,
      98.4
    ],
    1999: [
      88.6,
      89.2,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.4,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.8,
      91.9,
      92.0,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.6,
      92.7,
      92.9,
      93.0,
      93.2,
      93.4,
      93.7,
      93.9,
      94.2,
      94.6,
      95.0,
      95.4,
      95.9,
      96.4,
      97.0,
      97.7,
      98.4
    ],
    2000: [
      88.7,
      89.3,
      89.4,
      89.4,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.8,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.6,
      92.8,
      92.9,
      93.1,
      93.3,
      93.5,
      93.7,
      94.0,
      94.3,
      94.6,
      95.0,
      95.4,
      95.9,
      96.5,
      97.1,
      97.7,
      98.4
    ],
    2001: [
      88.9,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.6,
      92.7,
      92.8,
      93.0,
      93.1,
      93.3,
      93.5,
      93.7,
      94.0,
      94.3,
      94.6,
      95.0,
      95.5,
      95.9,
      96.5,
      97.1,
      97.7,
      98.4
    ],
    2002: [
      89,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.9,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.4,
      92.4,
      92.5,
      92.6,
      92.8,
      92.9,
      93.0,
      93.2,
      93.3,
      93.5,
      93.8,
      94.0,
      94.3,
      94.7,
      95.0,
      95.5,
      96.0,
      96.5,
      97.1,
      97.7,
      98.4
    ],
    2003: [
      89.1,
      89.7,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.3,
      92.4,
      92.5,
      92.6,
      92.7,
      92.8,
      92.9,
      93.1,
      93.2,
      93.4,
      93.6,
      93.8,
      94.1,
      94.4,
      94.7,
      95.1,
      95.5,
      96.0,
      96.5,
      97.1,
      97.7,
      98.4
    ],
    2004: [
      89.3,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      89.9,
      89.9,
      89.9,
      89.9,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.3,
      92.4,
      92.5,
      92.6,
      92.7,
      92.7,
      92.9,
      93.0,
      93.1,
      93.3,
      93.4,
      93.6,
      93.8,
      94.1,
      94.4,
      94.7,
      95.1,
      95.5,
      96.0,
      96.5,
      97.1,
      97.7,
      98.4
    ],
    2005: [
      89.3,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.0,
      90.0,
      90.0,
      90.0,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.3,
      92.4,
      92.5,
      92.5,
      92.6,
      92.7,
      92.8,
      92.9,
      93.0,
      93.2,
      93.3,
      93.5,
      93.7,
      93.9,
      94.1,
      94.4,
      94.7,
      95.1,
      95.5,
      96.0,
      96.5,
      97.1,
      97.7,
      98.4
    ],
    2006: [
      89.4,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.1,
      90.1,
      90.1,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.3,
      92.4,
      92.5,
      92.5,
      92.6,
      92.7,
      92.8,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.5,
      93.7,
      93.9,
      94.2,
      94.4,
      94.8,
      95.1,
      95.6,
      96.0,
      96.5,
      97.1,
      97.8,
      98.4
    ],
    2007: [
      89.6,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.2,
      90.2,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.6,
      92.7,
      92.7,
      92.8,
      92.9,
      93.0,
      93.1,
      93.2,
      93.4,
      93.5,
      93.7,
      93.9,
      94.2,
      94.5,
      94.8,
      95.2,
      95.6,
      96.0,
      96.6,
      97.1,
      97.8,
      98.4
    ],
    2008: [
      89.8,
      90.2,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.8,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.4,
      93.6,
      93.8,
      94.0,
      94.2,
      94.5,
      94.8,
      95.2,
      95.6,
      96.1,
      96.6,
      97.1,
      97.8,
      98.4
    ],
    2009: [
      89.8,
      90.3,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.8,
      92.8,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.5,
      93.6,
      93.8,
      94.0,
      94.3,
      94.5,
      94.8,
      95.2,
      95.6,
      96.1,
      96.6,
      97.2,
      97.8,
      98.5
    ],
    2010: [
      89.9,
      90.4,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.8,
      92.8,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.4,
      93.5,
      93.7,
      93.8,
      94.0,
      94.3,
      94.6,
      94.9,
      95.2,
      95.6,
      96.1,
      96.6,
      97.2,
      97.8,
      98.5
    ],
    2011: [
      90.1,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.8,
      92.8,
      92.9,
      93.0,
      93.0,
      93.1,
      93.2,
      93.3,
      93.4,
      93.6,
      93.7,
      93.9,
      94.1,
      94.3,
      94.6,
      94.9,
      95.2,
      95.7,
      96.1,
      96.6,
      97.2,
      97.8,
      98.5
    ],
    2012: [
      90.2,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.4,
      93.5,
      93.6,
      93.7,
      93.9,
      94.1,
      94.3,
      94.6,
      94.9,
      95.3,
      95.7,
      96.1,
      96.6,
      97.2,
      97.8,
      98.5
    ],
    2013: [
      90.3,
      90.8,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.1,
      93.1,
      93.2,
      93.3,
      93.4,
      93.5,
      93.6,
      93.8,
      94.0,
      94.1,
      94.4,
      94.6,
      94.9,
      95.3,
      95.7,
      96.1,
      96.6,
      97.2,
      97.8,
      98.5
    ],
    2014: [
      90.4,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.1,
      93.1,
      93.2,
      93.3,
      93.3,
      93.4,
      93.6,
      93.7,
      93.8,
      94.0,
      94.2,
      94.4,
      94.7,
      95.0,
      95.3,
      95.7,
      96.2,
      96.7,
      97.2,
      97.8,
      98.5
    ],
    2015: [
      90.6,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.2,
      93.2,
      93.3,
      93.4,
      93.5,
      93.6,
      93.7,
      93.9,
      94.0,
      94.2,
      94.4,
      94.7,
      95.0,
      95.3,
      95.7,
      96.2,
      96.7,
      97.2,
      97.8,
      98.5
    ],
    2016: [
      90.7,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.2,
      93.2,
      93.3,
      93.4,
      93.4,
      93.5,
      93.6,
      93.8,
      93.9,
      94.1,
      94.2,
      94.5,
      94.7,
      95.0,
      95.4,
      95.7,
      96.2,
      96.7,
      97.2,
      97.9,
      98.5
    ],
    2017: [
      90.8,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.3,
      93.3,
      93.4,
      93.5,
      93.6,
      93.7,
      93.8,
      93.9,
      94.1,
      94.3,
      94.5,
      94.7,
      95.0,
      95.4,
      95.8,
      96.2,
      96.7,
      97.3,
      97.9,
      98.5
    ],
    2018: [
      90.9,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.3,
      93.3,
      93.4,
      93.5,
      93.5,
      93.6,
      93.7,
      93.8,
      94.0,
      94.1,
      94.3,
      94.5,
      94.8,
      95.1,
      95.4,
      95.8,
      96.2,
      96.7,
      97.3,
      97.9,
      98.5
    ]
  }
  data.female = {
    1921: [
      72.2,
      75.8,
      76.4,
      76.7,
      77.0,
      77.2,
      77.3,
      77.5,
      77.6,
      77.7,
      77.8,
      77.9,
      77.9,
      78.0,
      78.1,
      78.2,
      78.3,
      78.4,
      78.4,
      78.5,
      78.7,
      78.8,
      78.9,
      79.0,
      79.1,
      79.2,
      79.3,
      79.4,
      79.5,
      79.6,
      79.7,
      79.7,
      79.8,
      79.8,
      79.9,
      79.9,
      80.0,
      80.1,
      80.1,
      80.2,
      80.3,
      80.4,
      80.5,
      80.6,
      80.7,
      80.8,
      80.9,
      81.0,
      81.1,
      81.3,
      81.4,
      81.6,
      81.7,
      81.9,
      82.0,
      82.2,
      82.4,
      82.6,
      82.7,
      82.9,
      83.2,
      83.3,
      83.6,
      83.8,
      84.0,
      84.3,
      84.5,
      84.8,
      85.1,
      85.4,
      85.7,
      86.0,
      86.3,
      86.6,
      87.0,
      87.4,
      87.8,
      88.1,
      88.5,
      88.9,
      89.3,
      89.7,
      90.2,
      90.6,
      91.1,
      91.6,
      92.1,
      92.7,
      93.2,
      93.8,
      94.5,
      95.2,
      95.8,
      96.5,
      97.3,
      98.0
    ],
    1922: [
      72.5,
      75.9,
      76.5,
      76.9,
      77.1,
      77.3,
      77.5,
      77.6,
      77.7,
      77.8,
      77.9,
      78.0,
      78.1,
      78.2,
      78.3,
      78.3,
      78.4,
      78.5,
      78.6,
      78.7,
      78.8,
      78.9,
      79.0,
      79.1,
      79.2,
      79.3,
      79.4,
      79.5,
      79.6,
      79.7,
      79.7,
      79.8,
      79.8,
      79.9,
      79.9,
      80.0,
      80.0,
      80.1,
      80.2,
      80.2,
      80.3,
      80.4,
      80.5,
      80.6,
      80.7,
      80.8,
      80.9,
      81.1,
      81.2,
      81.3,
      81.4,
      81.6,
      81.7,
      81.9,
      82.0,
      82.2,
      82.3,
      82.5,
      82.7,
      82.9,
      83.1,
      83.3,
      83.5,
      83.8,
      84.0,
      84.3,
      84.5,
      84.8,
      85.1,
      85.4,
      85.7,
      86.0,
      86.3,
      86.7,
      87.1,
      87.4,
      87.8,
      88.1,
      88.5,
      88.9,
      89.3,
      89.7,
      90.2,
      90.7,
      91.2,
      91.7,
      92.2,
      92.8,
      93.4,
      93.9,
      94.6,
      95.3,
      96.0,
      96.7,
      97.4,
      98.1
    ],
    1923: [
      72.5,
      75.9,
      76.6,
      77.0,
      77.3,
      77.4,
      77.6,
      77.7,
      77.8,
      78.0,
      78.1,
      78.2,
      78.2,
      78.3,
      78.4,
      78.5,
      78.6,
      78.7,
      78.8,
      78.9,
      79.0,
      79.0,
      79.1,
      79.3,
      79.4,
      79.4,
      79.6,
      79.6,
      79.7,
      79.7,
      79.8,
      79.9,
      79.9,
      80.0,
      80.0,
      80.1,
      80.1,
      80.2,
      80.2,
      80.3,
      80.4,
      80.5,
      80.6,
      80.7,
      80.8,
      80.9,
      81.0,
      81.1,
      81.3,
      81.4,
      81.5,
      81.7,
      81.8,
      82.0,
      82.1,
      82.3,
      82.5,
      82.6,
      82.9,
      83.1,
      83.3,
      83.5,
      83.7,
      84.0,
      84.2,
      84.5,
      84.8,
      85.0,
      85.3,
      85.6,
      85.9,
      86.2,
      86.5,
      86.9,
      87.2,
      87.5,
      87.9,
      88.2,
      88.6,
      89.0,
      89.4,
      89.8,
      90.3,
      90.7,
      91.2,
      91.7,
      92.2,
      92.7,
      93.3,
      93.9,
      94.6,
      95.2,
      95.9,
      96.7,
      97.4,
      98.1
    ],
    1924: [
      73.1,
      76.5,
      77.2,
      77.6,
      77.8,
      78.0,
      78.2,
      78.3,
      78.4,
      78.5,
      78.5,
      78.6,
      78.7,
      78.8,
      78.9,
      79.0,
      79.1,
      79.2,
      79.3,
      79.3,
      79.4,
      79.5,
      79.6,
      79.7,
      79.8,
      79.9,
      80.0,
      80.0,
      80.1,
      80.1,
      80.2,
      80.3,
      80.3,
      80.3,
      80.4,
      80.4,
      80.5,
      80.5,
      80.6,
      80.7,
      80.7,
      80.8,
      80.9,
      81.0,
      81.1,
      81.2,
      81.3,
      81.4,
      81.6,
      81.7,
      81.8,
      82.0,
      82.1,
      82.3,
      82.4,
      82.6,
      82.8,
      83.0,
      83.1,
      83.3,
      83.5,
      83.7,
      84.0,
      84.2,
      84.5,
      84.7,
      85.0,
      85.2,
      85.5,
      85.8,
      86.1,
      86.4,
      86.7,
      87.0,
      87.3,
      87.7,
      88.0,
      88.4,
      88.7,
      89.1,
      89.5,
      89.9,
      90.3,
      90.7,
      91.2,
      91.8,
      92.3,
      92.8,
      93.4,
      94.0,
      94.6,
      95.2,
      95.9,
      96.7,
      97.4,
      98.1
    ],
    1925: [
      73.5,
      76.9,
      77.5,
      77.9,
      78.1,
      78.3,
      78.5,
      78.6,
      78.7,
      78.8,
      78.8,
      78.9,
      79.0,
      79.1,
      79.2,
      79.2,
      79.3,
      79.4,
      79.5,
      79.6,
      79.7,
      79.8,
      79.9,
      80.0,
      80.1,
      80.2,
      80.2,
      80.3,
      80.4,
      80.4,
      80.4,
      80.5,
      80.5,
      80.6,
      80.6,
      80.7,
      80.7,
      80.8,
      80.8,
      80.9,
      81.0,
      81.1,
      81.1,
      81.2,
      81.3,
      81.4,
      81.5,
      81.7,
      81.8,
      81.9,
      82.0,
      82.2,
      82.3,
      82.5,
      82.7,
      82.8,
      83.0,
      83.2,
      83.4,
      83.6,
      83.7,
      83.9,
      84.2,
      84.4,
      84.7,
      84.9,
      85.2,
      85.5,
      85.7,
      86.0,
      86.2,
      86.5,
      86.8,
      87.1,
      87.4,
      87.7,
      88.1,
      88.4,
      88.8,
      89.2,
      89.6,
      90.0,
      90.4,
      90.9,
      91.3,
      91.8,
      92.3,
      92.9,
      93.4,
      94.0,
      94.6,
      95.3,
      96.0,
      96.7,
      97.4,
      98.2
    ],
    1926: [
      73.6,
      77.0,
      77.6,
      78.0,
      78.2,
      78.4,
      78.6,
      78.7,
      78.8,
      78.9,
      79.0,
      79.1,
      79.1,
      79.2,
      79.3,
      79.4,
      79.4,
      79.5,
      79.6,
      79.7,
      79.8,
      79.9,
      80.0,
      80.1,
      80.2,
      80.3,
      80.3,
      80.4,
      80.4,
      80.5,
      80.5,
      80.5,
      80.6,
      80.6,
      80.7,
      80.8,
      80.8,
      80.9,
      80.9,
      81.0,
      81.1,
      81.1,
      81.2,
      81.3,
      81.4,
      81.5,
      81.6,
      81.7,
      81.9,
      82.0,
      82.1,
      82.3,
      82.4,
      82.6,
      82.7,
      82.9,
      83.0,
      83.2,
      83.4,
      83.6,
      83.8,
      84.0,
      84.3,
      84.5,
      84.7,
      85.0,
      85.2,
      85.5,
      85.8,
      86.0,
      86.3,
      86.6,
      86.9,
      87.2,
      87.5,
      87.9,
      88.2,
      88.6,
      88.9,
      89.3,
      89.7,
      90.0,
      90.4,
      90.9,
      91.3,
      91.8,
      92.4,
      92.9,
      93.5,
      94.0,
      94.7,
      95.3,
      96.0,
      96.7,
      97.4,
      98.2
    ],
    1927: [
      74.1,
      77.3,
      77.9,
      78.2,
      78.4,
      78.6,
      78.7,
      78.9,
      79.0,
      79.0,
      79.1,
      79.2,
      79.3,
      79.4,
      79.5,
      79.6,
      79.6,
      79.7,
      79.8,
      79.9,
      80.0,
      80.1,
      80.2,
      80.2,
      80.3,
      80.3,
      80.4,
      80.4,
      80.5,
      80.5,
      80.6,
      80.6,
      80.7,
      80.7,
      80.8,
      80.9,
      80.9,
      81.0,
      81.0,
      81.1,
      81.2,
      81.2,
      81.3,
      81.4,
      81.5,
      81.6,
      81.7,
      81.9,
      82.0,
      82.1,
      82.2,
      82.4,
      82.5,
      82.7,
      82.9,
      83.0,
      83.1,
      83.3,
      83.5,
      83.7,
      83.9,
      84.2,
      84.3,
      84.6,
      84.8,
      85.1,
      85.3,
      85.6,
      85.8,
      86.1,
      86.4,
      86.7,
      87.0,
      87.3,
      87.6,
      87.9,
      88.3,
      88.6,
      89.0,
      89.3,
      89.7,
      90.1,
      90.5,
      90.9,
      91.4,
      91.9,
      92.4,
      92.9,
      93.5,
      94.1,
      94.7,
      95.4,
      96.0,
      96.7,
      97.4,
      98.2
    ],
    1928: [
      74.7,
      77.7,
      78.3,
      78.6,
      78.8,
      79.0,
      79.1,
      79.2,
      79.3,
      79.4,
      79.4,
      79.6,
      79.6,
      79.7,
      79.8,
      79.9,
      80.0,
      80.0,
      80.1,
      80.2,
      80.3,
      80.4,
      80.5,
      80.5,
      80.6,
      80.6,
      80.7,
      80.7,
      80.8,
      80.8,
      80.9,
      80.9,
      81.0,
      81.0,
      81.1,
      81.1,
      81.2,
      81.3,
      81.3,
      81.4,
      81.5,
      81.5,
      81.6,
      81.7,
      81.8,
      81.9,
      82.0,
      82.1,
      82.2,
      82.4,
      82.5,
      82.6,
      82.8,
      82.9,
      83.1,
      83.2,
      83.4,
      83.5,
      83.7,
      83.9,
      84.1,
      84.3,
      84.5,
      84.7,
      85.0,
      85.2,
      85.5,
      85.7,
      86.0,
      86.2,
      86.5,
      86.8,
      87.1,
      87.4,
      87.7,
      88.0,
      88.4,
      88.7,
      89.0,
      89.4,
      89.8,
      90.1,
      90.5,
      91.0,
      91.4,
      91.9,
      92.4,
      93.0,
      93.5,
      94.2,
      94.8,
      95.4,
      96.0,
      96.7,
      97.4,
      98.2
    ],
    1929: [
      75.0,
      78.1,
      78.6,
      78.9,
      79.1,
      79.3,
      79.4,
      79.5,
      79.6,
      79.7,
      79.8,
      79.8,
      79.9,
      80.0,
      80.1,
      80.1,
      80.2,
      80.3,
      80.4,
      80.5,
      80.6,
      80.7,
      80.7,
      80.8,
      80.8,
      80.9,
      80.9,
      81.0,
      81.0,
      81.1,
      81.1,
      81.2,
      81.2,
      81.3,
      81.3,
      81.4,
      81.4,
      81.5,
      81.5,
      81.6,
      81.7,
      81.8,
      81.9,
      82.0,
      82.0,
      82.1,
      82.3,
      82.4,
      82.5,
      82.6,
      82.8,
      82.9,
      83.0,
      83.1,
      83.3,
      83.5,
      83.6,
      83.8,
      84.0,
      84.2,
      84.3,
      84.5,
      84.8,
      85.0,
      85.2,
      85.4,
      85.7,
      85.9,
      86.2,
      86.5,
      86.8,
      87.0,
      87.3,
      87.6,
      87.9,
      88.2,
      88.5,
      88.9,
      89.2,
      89.5,
      89.9,
      90.3,
      90.7,
      91.1,
      91.6,
      92.0,
      92.5,
      93.0,
      93.6,
      94.2,
      94.8,
      95.4,
      96.1,
      96.7,
      97.5,
      98.2
    ],
    1930: [
      75.1,
      78.2,
      78.8,
      79.1,
      79.2,
      79.4,
      79.5,
      79.6,
      79.7,
      79.8,
      79.9,
      80.0,
      80.0,
      80.1,
      80.1,
      80.2,
      80.3,
      80.4,
      80.5,
      80.5,
      80.6,
      80.7,
      80.7,
      80.8,
      80.8,
      80.9,
      80.9,
      81.0,
      81.0,
      81.0,
      81.1,
      81.1,
      81.2,
      81.2,
      81.3,
      81.3,
      81.4,
      81.5,
      81.5,
      81.6,
      81.7,
      81.8,
      81.9,
      82.0,
      82.1,
      82.2,
      82.3,
      82.4,
      82.6,
      82.7,
      82.8,
      82.9,
      83.1,
      83.2,
      83.4,
      83.5,
      83.7,
      83.9,
      84.1,
      84.3,
      84.4,
      84.7,
      84.9,
      85.0,
      85.3,
      85.6,
      85.8,
      86.1,
      86.4,
      86.6,
      86.9,
      87.2,
      87.4,
      87.7,
      88.0,
      88.3,
      88.6,
      88.9,
      89.3,
      89.6,
      90.0,
      90.4,
      90.7,
      91.1,
      91.6,
      92.1,
      92.6,
      93.1,
      93.6,
      94.2,
      94.8,
      95.4,
      96.1,
      96.8,
      97.5,
      98.2
    ],
    1931: [
      75.6,
      78.5,
      79.0,
      79.3,
      79.5,
      79.7,
      79.8,
      79.9,
      80.1,
      80.1,
      80.2,
      80.3,
      80.4,
      80.4,
      80.5,
      80.6,
      80.6,
      80.7,
      80.8,
      80.9,
      81.0,
      81.0,
      81.1,
      81.1,
      81.2,
      81.2,
      81.3,
      81.3,
      81.3,
      81.4,
      81.4,
      81.5,
      81.5,
      81.5,
      81.6,
      81.6,
      81.7,
      81.7,
      81.8,
      81.9,
      82.0,
      82.1,
      82.1,
      82.2,
      82.3,
      82.4,
      82.6,
      82.7,
      82.8,
      82.9,
      83.1,
      83.2,
      83.3,
      83.4,
      83.6,
      83.8,
      83.9,
      84.1,
      84.3,
      84.4,
      84.6,
      84.8,
      85.0,
      85.2,
      85.4,
      85.7,
      85.9,
      86.2,
      86.4,
      86.7,
      86.9,
      87.2,
      87.5,
      87.8,
      88.1,
      88.4,
      88.7,
      89.0,
      89.4,
      89.7,
      90.0,
      90.4,
      90.8,
      91.2,
      91.7,
      92.1,
      92.6,
      93.1,
      93.7,
      94.2,
      94.8,
      95.4,
      96.1,
      96.8,
      97.5,
      98.2
    ],
    1932: [
      75.5,
      78.6,
      79.2,
      79.6,
      79.8,
      79.9,
      80.1,
      80.2,
      80.3,
      80.4,
      80.5,
      80.6,
      80.7,
      80.7,
      80.8,
      80.9,
      81.0,
      81.1,
      81.2,
      81.2,
      81.3,
      81.3,
      81.4,
      81.4,
      81.5,
      81.5,
      81.5,
      81.6,
      81.6,
      81.7,
      81.7,
      81.7,
      81.8,
      81.8,
      81.8,
      81.9,
      81.9,
      82.0,
      82.1,
      82.1,
      82.2,
      82.3,
      82.4,
      82.4,
      82.6,
      82.7,
      82.7,
      82.9,
      83.0,
      83.1,
      83.3,
      83.4,
      83.5,
      83.7,
      83.8,
      83.9,
      84.1,
      84.3,
      84.4,
      84.6,
      84.8,
      85.0,
      85.2,
      85.3,
      85.5,
      85.8,
      86.0,
      86.2,
      86.5,
      86.7,
      87.0,
      87.3,
      87.5,
      87.8,
      88.1,
      88.4,
      88.7,
      89.0,
      89.4,
      89.7,
      90.1,
      90.4,
      90.8,
      91.3,
      91.7,
      92.2,
      92.7,
      93.2,
      93.7,
      94.3,
      94.8,
      95.5,
      96.1,
      96.8,
      97.5,
      98.2
    ],
    1933: [
      75.8,
      78.9,
      79.5,
      79.8,
      80.0,
      80.2,
      80.4,
      80.5,
      80.5,
      80.6,
      80.7,
      80.8,
      80.8,
      80.9,
      81.0,
      81.1,
      81.1,
      81.2,
      81.2,
      81.3,
      81.3,
      81.4,
      81.4,
      81.5,
      81.5,
      81.6,
      81.6,
      81.6,
      81.7,
      81.7,
      81.7,
      81.8,
      81.8,
      81.9,
      81.9,
      82.0,
      82.0,
      82.1,
      82.1,
      82.2,
      82.3,
      82.4,
      82.4,
      82.5,
      82.7,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.5,
      83.6,
      83.8,
      83.9,
      84.1,
      84.2,
      84.4,
      84.6,
      84.8,
      85.0,
      85.2,
      85.4,
      85.7,
      85.9,
      86.1,
      86.3,
      86.6,
      86.8,
      87.0,
      87.3,
      87.5,
      87.8,
      88.0,
      88.3,
      88.6,
      88.9,
      89.2,
      89.5,
      89.8,
      90.1,
      90.5,
      90.9,
      91.4,
      91.8,
      92.3,
      92.7,
      93.2,
      93.7,
      94.3,
      94.9,
      95.5,
      96.1,
      96.8,
      97.5,
      98.2
    ],
    1934: [
      76.0,
      79.2,
      79.7,
      80.0,
      80.3,
      80.5,
      80.6,
      80.7,
      80.8,
      80.9,
      80.9,
      81.0,
      81.1,
      81.2,
      81.2,
      81.3,
      81.3,
      81.4,
      81.4,
      81.5,
      81.5,
      81.6,
      81.6,
      81.6,
      81.7,
      81.7,
      81.8,
      81.8,
      81.8,
      81.9,
      81.9,
      82.0,
      82.0,
      82.0,
      82.1,
      82.2,
      82.2,
      82.3,
      82.3,
      82.4,
      82.5,
      82.5,
      82.6,
      82.7,
      82.8,
      82.9,
      83.1,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.9,
      84.1,
      84.2,
      84.4,
      84.5,
      84.7,
      84.9,
      85.1,
      85.3,
      85.5,
      85.7,
      85.9,
      86.1,
      86.3,
      86.5,
      86.8,
      87.0,
      87.2,
      87.5,
      87.7,
      88.0,
      88.3,
      88.6,
      88.9,
      89.2,
      89.5,
      89.9,
      90.2,
      90.6,
      91.0,
      91.4,
      91.9,
      92.3,
      92.8,
      93.2,
      93.8,
      94.3,
      94.9,
      95.5,
      96.1,
      96.8,
      97.5,
      98.2
    ],
    1935: [
      76.2,
      79.4,
      79.9,
      80.2,
      80.5,
      80.7,
      80.8,
      80.9,
      81.0,
      81.1,
      81.1,
      81.2,
      81.3,
      81.3,
      81.4,
      81.5,
      81.5,
      81.5,
      81.6,
      81.7,
      81.7,
      81.7,
      81.8,
      81.8,
      81.8,
      81.9,
      81.9,
      81.9,
      82.0,
      82.0,
      82.1,
      82.1,
      82.1,
      82.2,
      82.2,
      82.3,
      82.3,
      82.4,
      82.5,
      82.5,
      82.6,
      82.7,
      82.8,
      82.8,
      82.9,
      83.0,
      83.1,
      83.2,
      83.3,
      83.5,
      83.6,
      83.7,
      83.9,
      84.0,
      84.2,
      84.3,
      84.5,
      84.6,
      84.8,
      85.0,
      85.2,
      85.4,
      85.5,
      85.7,
      86.0,
      86.2,
      86.4,
      86.6,
      86.9,
      87.2,
      87.4,
      87.6,
      87.9,
      88.1,
      88.4,
      88.7,
      89.0,
      89.3,
      89.6,
      90.0,
      90.3,
      90.7,
      91.1,
      91.5,
      91.9,
      92.3,
      92.8,
      93.3,
      93.8,
      94.3,
      94.9,
      95.5,
      96.1,
      96.8,
      97.5,
      98.3
    ],
    1936: [
      76.6,
      79.8,
      80.5,
      80.9,
      81.1,
      81.2,
      81.4,
      81.5,
      81.6,
      81.6,
      81.7,
      81.8,
      81.8,
      81.9,
      81.9,
      81.9,
      82.0,
      82.0,
      82.1,
      82.1,
      82.2,
      82.2,
      82.3,
      82.3,
      82.3,
      82.4,
      82.4,
      82.4,
      82.5,
      82.5,
      82.6,
      82.6,
      82.6,
      82.7,
      82.7,
      82.8,
      82.8,
      82.9,
      82.9,
      83.0,
      83.1,
      83.2,
      83.2,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.7,
      83.8,
      83.9,
      84.1,
      84.2,
      84.3,
      84.5,
      84.6,
      84.8,
      84.9,
      85.1,
      85.3,
      85.5,
      85.6,
      85.8,
      86.0,
      86.2,
      86.4,
      86.6,
      86.8,
      87.1,
      87.3,
      87.5,
      87.8,
      88.0,
      88.3,
      88.6,
      88.8,
      89.2,
      89.4,
      89.8,
      90.1,
      90.4,
      90.8,
      91.2,
      91.6,
      92.0,
      92.4,
      92.8,
      93.3,
      93.8,
      94.4,
      94.9,
      95.5,
      96.2,
      96.8,
      97.5,
      98.3
    ],
    1937: [
      77.1,
      80.2,
      81.0,
      81.3,
      81.5,
      81.6,
      81.7,
      81.8,
      81.9,
      82.0,
      82.1,
      82.1,
      82.2,
      82.2,
      82.3,
      82.3,
      82.3,
      82.4,
      82.4,
      82.4,
      82.5,
      82.5,
      82.6,
      82.6,
      82.6,
      82.7,
      82.7,
      82.7,
      82.8,
      82.8,
      82.9,
      82.9,
      83.0,
      83.0,
      83.1,
      83.1,
      83.2,
      83.3,
      83.3,
      83.4,
      83.4,
      83.5,
      83.6,
      83.7,
      83.8,
      83.9,
      84.0,
      84.0,
      84.1,
      84.2,
      84.4,
      84.5,
      84.5,
      84.7,
      84.8,
      85.0,
      85.1,
      85.3,
      85.4,
      85.5,
      85.8,
      85.9,
      86.1,
      86.3,
      86.5,
      86.7,
      86.9,
      87.1,
      87.3,
      87.5,
      87.7,
      88.0,
      88.2,
      88.4,
      88.7,
      89.0,
      89.3,
      89.6,
      89.9,
      90.2,
      90.5,
      90.9,
      91.2,
      91.6,
      92.0,
      92.4,
      92.9,
      93.3,
      93.8,
      94.4,
      94.9,
      95.5,
      96.2,
      96.8,
      97.5,
      98.3
    ],
    1938: [
      77.0,
      80.6,
      81.1,
      81.3,
      81.6,
      81.7,
      81.8,
      81.9,
      82.0,
      82.1,
      82.1,
      82.2,
      82.2,
      82.2,
      82.3,
      82.3,
      82.3,
      82.4,
      82.4,
      82.4,
      82.5,
      82.5,
      82.6,
      82.6,
      82.6,
      82.7,
      82.7,
      82.7,
      82.8,
      82.8,
      82.9,
      82.9,
      83.0,
      83.0,
      83.1,
      83.1,
      83.2,
      83.3,
      83.3,
      83.4,
      83.4,
      83.5,
      83.6,
      83.7,
      83.8,
      83.9,
      84.0,
      84.0,
      84.2,
      84.3,
      84.4,
      84.5,
      84.6,
      84.7,
      84.9,
      85.0,
      85.2,
      85.3,
      85.5,
      85.6,
      85.8,
      86.0,
      86.2,
      86.4,
      86.6,
      86.8,
      87.0,
      87.2,
      87.4,
      87.6,
      87.9,
      88.1,
      88.3,
      88.6,
      88.8,
      89.1,
      89.3,
      89.6,
      90.0,
      90.3,
      90.6,
      90.9,
      91.3,
      91.7,
      92.1,
      92.5,
      92.9,
      93.4,
      93.9,
      94.4,
      95.0,
      95.6,
      96.2,
      96.9,
      97.6,
      98.3
    ],
    1939: [
      78.0,
      81.1,
      81.5,
      81.8,
      82.0,
      82.2,
      82.3,
      82.4,
      82.4,
      82.4,
      82.5,
      82.5,
      82.5,
      82.6,
      82.6,
      82.7,
      82.7,
      82.7,
      82.7,
      82.8,
      82.8,
      82.9,
      82.9,
      82.9,
      83.0,
      83.0,
      83.0,
      83.1,
      83.1,
      83.1,
      83.2,
      83.2,
      83.3,
      83.3,
      83.4,
      83.4,
      83.5,
      83.5,
      83.6,
      83.6,
      83.7,
      83.8,
      83.8,
      83.9,
      84.0,
      84.1,
      84.1,
      84.2,
      84.3,
      84.4,
      84.6,
      84.7,
      84.8,
      84.9,
      85.1,
      85.2,
      85.4,
      85.5,
      85.7,
      85.9,
      86.0,
      86.2,
      86.4,
      86.6,
      86.8,
      87.0,
      87.2,
      87.4,
      87.6,
      87.8,
      88.0,
      88.3,
      88.5,
      88.7,
      89.0,
      89.2,
      89.5,
      89.8,
      90.1,
      90.4,
      90.7,
      91.0,
      91.4,
      91.7,
      92.1,
      92.5,
      92.9,
      93.4,
      93.9,
      94.4,
      95.0,
      95.6,
      96.2,
      96.9,
      97.6,
      98.3
    ],
    1940: [
      78.4,
      81.3,
      81.8,
      82.1,
      82.3,
      82.4,
      82.5,
      82.6,
      82.6,
      82.7,
      82.7,
      82.7,
      82.7,
      82.8,
      82.8,
      82.9,
      82.9,
      82.9,
      83.0,
      83.0,
      83.0,
      83.1,
      83.1,
      83.1,
      83.2,
      83.2,
      83.3,
      83.3,
      83.3,
      83.4,
      83.4,
      83.5,
      83.5,
      83.5,
      83.6,
      83.6,
      83.7,
      83.7,
      83.8,
      83.9,
      83.9,
      84.0,
      84.1,
      84.1,
      84.2,
      84.3,
      84.4,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.2,
      85.3,
      85.4,
      85.5,
      85.7,
      85.9,
      86.0,
      86.2,
      86.4,
      86.6,
      86.7,
      86.9,
      87.1,
      87.3,
      87.5,
      87.7,
      87.9,
      88.1,
      88.4,
      88.6,
      88.8,
      89.1,
      89.3,
      89.6,
      89.9,
      90.2,
      90.5,
      90.8,
      91.1,
      91.4,
      91.8,
      92.1,
      92.5,
      93.0,
      93.4,
      93.9,
      94.5,
      95.0,
      95.6,
      96.2,
      96.9,
      97.6,
      98.3
    ],
    1941: [
      78.9,
      81.9,
      82.3,
      82.6,
      82.7,
      82.9,
      82.9,
      83.0,
      83.0,
      83.1,
      83.1,
      83.1,
      83.2,
      83.2,
      83.2,
      83.3,
      83.3,
      83.3,
      83.4,
      83.4,
      83.4,
      83.5,
      83.5,
      83.6,
      83.6,
      83.6,
      83.7,
      83.7,
      83.7,
      83.8,
      83.8,
      83.9,
      83.9,
      83.9,
      84.0,
      84.1,
      84.1,
      84.1,
      84.2,
      84.3,
      84.3,
      84.4,
      84.4,
      84.5,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.1,
      85.2,
      85.3,
      85.5,
      85.6,
      85.7,
      85.8,
      86.0,
      86.1,
      86.3,
      86.4,
      86.6,
      86.7,
      86.9,
      87.1,
      87.3,
      87.5,
      87.7,
      87.9,
      88.0,
      88.3,
      88.5,
      88.7,
      88.9,
      89.2,
      89.4,
      89.7,
      90.0,
      90.2,
      90.5,
      90.8,
      91.1,
      91.5,
      91.8,
      92.2,
      92.6,
      93.0,
      93.5,
      94.0,
      94.5,
      95.0,
      95.6,
      96.2,
      96.9,
      97.6,
      98.3
    ],
    1942: [
      79.4,
      82.0,
      82.5,
      82.7,
      82.8,
      82.9,
      83.0,
      83.1,
      83.1,
      83.1,
      83.2,
      83.2,
      83.2,
      83.3,
      83.3,
      83.4,
      83.4,
      83.4,
      83.5,
      83.5,
      83.5,
      83.6,
      83.6,
      83.6,
      83.7,
      83.7,
      83.7,
      83.8,
      83.8,
      83.8,
      83.9,
      83.9,
      84.0,
      84.0,
      84.0,
      84.1,
      84.1,
      84.1,
      84.2,
      84.3,
      84.3,
      84.4,
      84.5,
      84.5,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.0,
      85.2,
      85.2,
      85.4,
      85.5,
      85.6,
      85.8,
      85.9,
      86.1,
      86.2,
      86.4,
      86.6,
      86.7,
      86.8,
      87.0,
      87.2,
      87.4,
      87.6,
      87.7,
      87.9,
      88.1,
      88.3,
      88.6,
      88.8,
      89.0,
      89.3,
      89.5,
      89.8,
      90.1,
      90.3,
      90.6,
      90.9,
      91.2,
      91.5,
      91.9,
      92.2,
      92.6,
      93.1,
      93.5,
      94.0,
      94.5,
      95.0,
      95.6,
      96.3,
      96.9,
      97.6,
      98.3
    ],
    1943: [
      79.1,
      82.1,
      82.5,
      82.7,
      82.8,
      83.0,
      83.1,
      83.1,
      83.1,
      83.2,
      83.2,
      83.3,
      83.3,
      83.3,
      83.4,
      83.4,
      83.4,
      83.5,
      83.5,
      83.6,
      83.6,
      83.6,
      83.7,
      83.7,
      83.7,
      83.8,
      83.8,
      83.9,
      83.9,
      83.9,
      84.0,
      84.0,
      84.1,
      84.1,
      84.2,
      84.2,
      84.2,
      84.3,
      84.3,
      84.4,
      84.4,
      84.5,
      84.6,
      84.6,
      84.7,
      84.8,
      84.9,
      85.0,
      85.1,
      85.1,
      85.2,
      85.4,
      85.5,
      85.6,
      85.7,
      85.8,
      86.0,
      86.1,
      86.3,
      86.4,
      86.6,
      86.7,
      86.9,
      87.1,
      87.2,
      87.4,
      87.6,
      87.8,
      88.0,
      88.2,
      88.4,
      88.7,
      88.9,
      89.1,
      89.4,
      89.6,
      89.9,
      90.1,
      90.4,
      90.7,
      91.0,
      91.3,
      91.6,
      91.9,
      92.3,
      92.7,
      93.1,
      93.5,
      94.0,
      94.5,
      95.1,
      95.7,
      96.3,
      96.9,
      97.6,
      98.3
    ],
    1944: [
      79.6,
      82.5,
      82.9,
      83.2,
      83.3,
      83.4,
      83.4,
      83.5,
      83.5,
      83.6,
      83.6,
      83.6,
      83.7,
      83.7,
      83.8,
      83.8,
      83.8,
      83.8,
      83.9,
      83.9,
      83.9,
      84.0,
      84.0,
      84.0,
      84.1,
      84.1,
      84.1,
      84.2,
      84.2,
      84.2,
      84.3,
      84.3,
      84.4,
      84.4,
      84.5,
      84.5,
      84.5,
      84.6,
      84.6,
      84.7,
      84.7,
      84.8,
      84.8,
      84.9,
      85.0,
      85.0,
      85.1,
      85.2,
      85.3,
      85.4,
      85.5,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.3,
      86.4,
      86.5,
      86.7,
      86.9,
      87.0,
      87.1,
      87.3,
      87.5,
      87.7,
      87.8,
      88.0,
      88.2,
      88.4,
      88.6,
      88.8,
      89.1,
      89.3,
      89.5,
      89.7,
      90.0,
      90.2,
      90.5,
      90.7,
      91.0,
      91.3,
      91.6,
      92.0,
      92.3,
      92.7,
      93.1,
      93.6,
      94.0,
      94.5,
      95.1,
      95.7,
      96.3,
      96.9,
      97.6,
      98.3
    ],
    1945: [
      80.2,
      82.9,
      83.3,
      83.4,
      83.5,
      83.6,
      83.7,
      83.7,
      83.8,
      83.8,
      83.8,
      83.9,
      83.9,
      83.9,
      84.0,
      84.0,
      84.0,
      84.0,
      84.1,
      84.1,
      84.1,
      84.2,
      84.2,
      84.2,
      84.3,
      84.3,
      84.3,
      84.4,
      84.4,
      84.4,
      84.5,
      84.5,
      84.5,
      84.6,
      84.6,
      84.7,
      84.7,
      84.7,
      84.8,
      84.8,
      84.9,
      85.0,
      85.0,
      85.1,
      85.1,
      85.2,
      85.3,
      85.4,
      85.5,
      85.6,
      85.7,
      85.8,
      85.9,
      86.1,
      86.2,
      86.3,
      86.4,
      86.6,
      86.7,
      86.8,
      87.0,
      87.1,
      87.3,
      87.5,
      87.6,
      87.8,
      88.0,
      88.2,
      88.4,
      88.5,
      88.7,
      89.0,
      89.2,
      89.4,
      89.6,
      89.8,
      90.1,
      90.3,
      90.6,
      90.8,
      91.1,
      91.4,
      91.7,
      92.0,
      92.4,
      92.8,
      93.2,
      93.6,
      94.1,
      94.6,
      95.1,
      95.7,
      96.3,
      97.0,
      97.6,
      98.4
    ],
    1946: [
      80.8,
      83.3,
      83.6,
      83.8,
      83.9,
      83.9,
      84.0,
      84.0,
      84.1,
      84.1,
      84.1,
      84.1,
      84.2,
      84.2,
      84.2,
      84.3,
      84.3,
      84.3,
      84.3,
      84.4,
      84.4,
      84.4,
      84.5,
      84.5,
      84.5,
      84.6,
      84.6,
      84.6,
      84.7,
      84.7,
      84.8,
      84.8,
      84.8,
      84.9,
      84.9,
      84.9,
      85.0,
      85.0,
      85.1,
      85.1,
      85.2,
      85.3,
      85.3,
      85.4,
      85.5,
      85.6,
      85.6,
      85.7,
      85.8,
      85.9,
      86.0,
      86.1,
      86.2,
      86.4,
      86.4,
      86.6,
      86.7,
      86.8,
      86.9,
      87.1,
      87.2,
      87.3,
      87.4,
      87.6,
      87.8,
      87.9,
      88.1,
      88.3,
      88.5,
      88.7,
      88.9,
      89.1,
      89.3,
      89.5,
      89.7,
      89.9,
      90.1,
      90.4,
      90.6,
      90.9,
      91.2,
      91.4,
      91.7,
      92.1,
      92.4,
      92.8,
      93.2,
      93.6,
      94.1,
      94.6,
      95.1,
      95.7,
      96.3,
      97.0,
      97.6,
      98.4
    ],
    1947: [
      81.3,
      83.7,
      84.0,
      84.1,
      84.2,
      84.3,
      84.3,
      84.4,
      84.4,
      84.4,
      84.5,
      84.5,
      84.5,
      84.5,
      84.5,
      84.6,
      84.6,
      84.6,
      84.7,
      84.7,
      84.7,
      84.8,
      84.8,
      84.8,
      84.9,
      84.9,
      84.9,
      85.0,
      85.0,
      85.0,
      85.1,
      85.1,
      85.1,
      85.2,
      85.2,
      85.3,
      85.3,
      85.4,
      85.4,
      85.5,
      85.5,
      85.6,
      85.6,
      85.7,
      85.8,
      85.8,
      85.9,
      86.0,
      86.0,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      87.1,
      87.2,
      87.3,
      87.5,
      87.6,
      87.8,
      87.9,
      88.1,
      88.3,
      88.4,
      88.6,
      88.8,
      89.0,
      89.2,
      89.4,
      89.6,
      89.8,
      90.0,
      90.2,
      90.5,
      90.7,
      91.0,
      91.2,
      91.5,
      91.8,
      92.1,
      92.5,
      92.8,
      93.2,
      93.7,
      94.1,
      94.6,
      95.2,
      95.7,
      96.3,
      97.0,
      97.7,
      98.4
    ],
    1948: [
      81.4,
      83.7,
      84.0,
      84.1,
      84.2,
      84.2,
      84.3,
      84.3,
      84.4,
      84.4,
      84.4,
      84.5,
      84.5,
      84.5,
      84.5,
      84.6,
      84.6,
      84.6,
      84.7,
      84.7,
      84.7,
      84.7,
      84.8,
      84.8,
      84.9,
      84.9,
      84.9,
      85.0,
      85.0,
      85.0,
      85.1,
      85.1,
      85.1,
      85.2,
      85.2,
      85.3,
      85.3,
      85.4,
      85.4,
      85.5,
      85.5,
      85.6,
      85.7,
      85.7,
      85.8,
      85.9,
      85.9,
      86.0,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      87.1,
      87.2,
      87.3,
      87.4,
      87.6,
      87.7,
      87.9,
      88.0,
      88.2,
      88.4,
      88.5,
      88.7,
      88.9,
      89.1,
      89.3,
      89.5,
      89.7,
      89.9,
      90.1,
      90.3,
      90.5,
      90.8,
      91.0,
      91.3,
      91.6,
      91.9,
      92.2,
      92.5,
      92.9,
      93.3,
      93.7,
      94.2,
      94.6,
      95.2,
      95.7,
      96.3,
      97.0,
      97.7,
      98.4
    ],
    1949: [
      81.4,
      83.9,
      84.2,
      84.2,
      84.4,
      84.4,
      84.5,
      84.5,
      84.5,
      84.6,
      84.6,
      84.6,
      84.6,
      84.7,
      84.7,
      84.7,
      84.7,
      84.8,
      84.8,
      84.8,
      84.9,
      84.9,
      85.0,
      85.0,
      85.0,
      85.1,
      85.1,
      85.1,
      85.2,
      85.2,
      85.2,
      85.3,
      85.3,
      85.4,
      85.4,
      85.5,
      85.5,
      85.6,
      85.6,
      85.7,
      85.7,
      85.8,
      85.8,
      85.9,
      86.0,
      86.0,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      87.0,
      87.1,
      87.3,
      87.4,
      87.5,
      87.7,
      87.8,
      87.9,
      88.1,
      88.2,
      88.4,
      88.5,
      88.7,
      88.9,
      89.0,
      89.2,
      89.4,
      89.6,
      89.8,
      90.0,
      90.2,
      90.4,
      90.6,
      90.9,
      91.1,
      91.4,
      91.6,
      91.9,
      92.2,
      92.6,
      92.9,
      93.3,
      93.7,
      94.2,
      94.7,
      95.2,
      95.8,
      96.4,
      97.0,
      97.7,
      98.4
    ],
    1950: [
      81.9,
      84.1,
      84.4,
      84.5,
      84.6,
      84.7,
      84.7,
      84.7,
      84.8,
      84.8,
      84.8,
      84.8,
      84.9,
      84.9,
      84.9,
      84.9,
      85.0,
      85.0,
      85.0,
      85.1,
      85.1,
      85.2,
      85.2,
      85.2,
      85.3,
      85.3,
      85.3,
      85.3,
      85.4,
      85.4,
      85.5,
      85.5,
      85.5,
      85.6,
      85.6,
      85.7,
      85.7,
      85.7,
      85.8,
      85.8,
      85.9,
      85.9,
      86.0,
      86.1,
      86.1,
      86.2,
      86.3,
      86.4,
      86.5,
      86.5,
      86.6,
      86.7,
      86.8,
      86.9,
      87.1,
      87.1,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.9,
      88.0,
      88.2,
      88.3,
      88.5,
      88.6,
      88.8,
      89.0,
      89.2,
      89.3,
      89.5,
      89.7,
      89.9,
      90.1,
      90.3,
      90.5,
      90.7,
      90.9,
      91.2,
      91.4,
      91.7,
      92.0,
      92.3,
      92.6,
      93.0,
      93.3,
      93.8,
      94.2,
      94.7,
      95.2,
      95.8,
      96.4,
      97.0,
      97.7,
      98.4
    ],
    1951: [
      82.3,
      84.4,
      84.6,
      84.8,
      84.9,
      84.9,
      85.0,
      85.0,
      85.0,
      85.1,
      85.1,
      85.1,
      85.2,
      85.2,
      85.2,
      85.2,
      85.3,
      85.3,
      85.3,
      85.4,
      85.4,
      85.5,
      85.5,
      85.5,
      85.6,
      85.6,
      85.6,
      85.7,
      85.7,
      85.7,
      85.8,
      85.8,
      85.8,
      85.9,
      85.9,
      85.9,
      86.0,
      86.0,
      86.1,
      86.1,
      86.2,
      86.2,
      86.3,
      86.4,
      86.4,
      86.5,
      86.6,
      86.7,
      86.7,
      86.8,
      86.9,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.9,
      88.0,
      88.1,
      88.2,
      88.4,
      88.5,
      88.6,
      88.8,
      88.9,
      89.1,
      89.3,
      89.4,
      89.6,
      89.8,
      90.0,
      90.2,
      90.4,
      90.6,
      90.8,
      91.0,
      91.2,
      91.5,
      91.7,
      92.0,
      92.3,
      92.7,
      93.0,
      93.4,
      93.8,
      94.2,
      94.7,
      95.2,
      95.8,
      96.4,
      97.0,
      97.7,
      98.4
    ],
    1952: [
      82.3,
      84.4,
      84.7,
      84.8,
      84.9,
      84.9,
      85.0,
      85.0,
      85.1,
      85.1,
      85.1,
      85.1,
      85.2,
      85.2,
      85.2,
      85.2,
      85.3,
      85.3,
      85.4,
      85.4,
      85.5,
      85.5,
      85.5,
      85.6,
      85.6,
      85.7,
      85.7,
      85.7,
      85.8,
      85.8,
      85.8,
      85.9,
      85.9,
      86.0,
      86.0,
      86.1,
      86.1,
      86.2,
      86.2,
      86.3,
      86.4,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.7,
      86.8,
      86.8,
      86.9,
      87.0,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.8,
      87.9,
      88.0,
      88.1,
      88.2,
      88.3,
      88.5,
      88.6,
      88.8,
      88.9,
      89.1,
      89.2,
      89.4,
      89.6,
      89.7,
      89.9,
      90.1,
      90.3,
      90.5,
      90.7,
      90.9,
      91.1,
      91.3,
      91.5,
      91.8,
      92.1,
      92.4,
      92.7,
      93.0,
      93.4,
      93.8,
      94.3,
      94.7,
      95.3,
      95.8,
      96.4,
      97.0,
      97.7,
      98.4
    ],
    1953: [
      82.8,
      84.7,
      84.9,
      85.0,
      85.1,
      85.2,
      85.2,
      85.3,
      85.3,
      85.3,
      85.4,
      85.4,
      85.4,
      85.4,
      85.5,
      85.5,
      85.5,
      85.6,
      85.6,
      85.7,
      85.7,
      85.8,
      85.8,
      85.9,
      85.9,
      85.9,
      86.0,
      86.0,
      86.1,
      86.1,
      86.1,
      86.2,
      86.2,
      86.3,
      86.3,
      86.3,
      86.4,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.1,
      87.1,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.2,
      88.3,
      88.5,
      88.6,
      88.8,
      88.9,
      89.0,
      89.2,
      89.3,
      89.5,
      89.7,
      89.8,
      90.0,
      90.2,
      90.4,
      90.5,
      90.7,
      90.9,
      91.2,
      91.4,
      91.6,
      91.9,
      92.1,
      92.4,
      92.7,
      93.1,
      93.5,
      93.9,
      94.3,
      94.8,
      95.3,
      95.8,
      96.4,
      97.1,
      97.7,
      98.4
    ],
    1954: [
      83.2,
      85.0,
      85.2,
      85.3,
      85.4,
      85.4,
      85.5,
      85.5,
      85.5,
      85.6,
      85.6,
      85.6,
      85.6,
      85.6,
      85.7,
      85.7,
      85.7,
      85.8,
      85.8,
      85.8,
      85.9,
      85.9,
      86.0,
      86.0,
      86.0,
      86.1,
      86.1,
      86.2,
      86.2,
      86.2,
      86.2,
      86.3,
      86.3,
      86.4,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      87.0,
      87.0,
      87.1,
      87.2,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.2,
      88.4,
      88.5,
      88.6,
      88.8,
      88.9,
      89.0,
      89.2,
      89.3,
      89.5,
      89.6,
      89.8,
      89.9,
      90.1,
      90.3,
      90.4,
      90.6,
      90.8,
      91.0,
      91.2,
      91.4,
      91.7,
      91.9,
      92.2,
      92.5,
      92.8,
      93.1,
      93.5,
      93.9,
      94.3,
      94.8,
      95.3,
      95.9,
      96.4,
      97.1,
      97.7,
      98.4
    ],
    1955: [
      83.5,
      85.3,
      85.5,
      85.6,
      85.7,
      85.7,
      85.8,
      85.8,
      85.8,
      85.9,
      85.9,
      85.9,
      85.9,
      85.9,
      85.9,
      86.0,
      86.0,
      86.0,
      86.1,
      86.1,
      86.1,
      86.2,
      86.2,
      86.2,
      86.3,
      86.3,
      86.3,
      86.4,
      86.4,
      86.4,
      86.5,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.3,
      87.3,
      87.4,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.8,
      88.9,
      89.0,
      89.2,
      89.3,
      89.4,
      89.6,
      89.7,
      89.9,
      90.0,
      90.2,
      90.4,
      90.5,
      90.7,
      90.9,
      91.1,
      91.3,
      91.5,
      91.7,
      92.0,
      92.2,
      92.5,
      92.8,
      93.2,
      93.5,
      93.9,
      94.3,
      94.8,
      95.3,
      95.9,
      96.5,
      97.1,
      97.7,
      98.5
    ],
    1956: [
      83.5,
      85.3,
      85.5,
      85.6,
      85.7,
      85.7,
      85.8,
      85.8,
      85.8,
      85.8,
      85.9,
      85.9,
      85.9,
      85.9,
      85.9,
      86.0,
      86.0,
      86.0,
      86.1,
      86.1,
      86.1,
      86.2,
      86.2,
      86.2,
      86.3,
      86.3,
      86.4,
      86.4,
      86.4,
      86.5,
      86.5,
      86.5,
      86.6,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.2,
      87.2,
      87.3,
      87.4,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.8,
      88.9,
      89.0,
      89.1,
      89.3,
      89.4,
      89.5,
      89.7,
      89.8,
      90.0,
      90.1,
      90.3,
      90.5,
      90.6,
      90.8,
      91.0,
      91.2,
      91.4,
      91.6,
      91.8,
      92.0,
      92.3,
      92.6,
      92.9,
      93.2,
      93.6,
      94.0,
      94.4,
      94.8,
      95.3,
      95.9,
      96.5,
      97.1,
      97.8,
      98.5
    ],
    1957: [
      83.7,
      85.5,
      85.7,
      85.8,
      85.9,
      85.9,
      86.0,
      86.0,
      86.0,
      86.0,
      86.0,
      86.1,
      86.1,
      86.1,
      86.1,
      86.1,
      86.2,
      86.2,
      86.3,
      86.3,
      86.3,
      86.4,
      86.4,
      86.5,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.8,
      86.9,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.5,
      87.5,
      87.6,
      87.7,
      87.8,
      87.9,
      87.9,
      88.0,
      88.2,
      88.3,
      88.3,
      88.5,
      88.6,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.3,
      89.4,
      89.5,
      89.7,
      89.8,
      89.9,
      90.1,
      90.2,
      90.4,
      90.5,
      90.7,
      90.9,
      91.1,
      91.2,
      91.4,
      91.6,
      91.9,
      92.1,
      92.3,
      92.6,
      92.9,
      93.2,
      93.6,
      94.0,
      94.4,
      94.9,
      95.4,
      95.9,
      96.5,
      97.1,
      97.8,
      98.5
    ],
    1958: [
      83.8,
      85.5,
      85.7,
      85.8,
      85.9,
      85.9,
      86.0,
      86.0,
      86.0,
      86.0,
      86.1,
      86.1,
      86.1,
      86.1,
      86.1,
      86.2,
      86.2,
      86.2,
      86.3,
      86.3,
      86.3,
      86.4,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.7,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.1,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.5,
      87.6,
      87.7,
      87.7,
      87.8,
      87.9,
      88.0,
      88.1,
      88.1,
      88.2,
      88.4,
      88.5,
      88.6,
      88.7,
      88.8,
      88.9,
      89.0,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.8,
      89.9,
      90.0,
      90.2,
      90.3,
      90.5,
      90.6,
      90.8,
      91.0,
      91.1,
      91.3,
      91.5,
      91.7,
      91.9,
      92.1,
      92.4,
      92.7,
      93.0,
      93.3,
      93.6,
      94.0,
      94.4,
      94.9,
      95.4,
      95.9,
      96.5,
      97.1,
      97.8,
      98.5
    ],
    1959: [
      84.0,
      85.9,
      86.0,
      86.1,
      86.1,
      86.2,
      86.2,
      86.2,
      86.3,
      86.3,
      86.3,
      86.3,
      86.4,
      86.4,
      86.4,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.8,
      86.9,
      86.9,
      86.9,
      87.0,
      87.0,
      87.0,
      87.1,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.3,
      87.4,
      87.4,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.8,
      87.9,
      88.0,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.8,
      89.9,
      90.0,
      90.1,
      90.3,
      90.4,
      90.6,
      90.7,
      90.9,
      91.0,
      91.2,
      91.4,
      91.6,
      91.8,
      92.0,
      92.2,
      92.4,
      92.7,
      93.0,
      93.3,
      93.7,
      94.0,
      94.5,
      94.9,
      95.4,
      95.9,
      96.5,
      97.1,
      97.8,
      98.5
    ],
    1960: [
      84.2,
      85.9,
      86.1,
      86.1,
      86.2,
      86.2,
      86.3,
      86.3,
      86.3,
      86.3,
      86.4,
      86.4,
      86.4,
      86.4,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.7,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      86.9,
      87.0,
      87.0,
      87.0,
      87.1,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.5,
      87.5,
      87.5,
      87.6,
      87.6,
      87.7,
      87.8,
      87.8,
      87.9,
      88.0,
      88.0,
      88.1,
      88.2,
      88.3,
      88.4,
      88.6,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.4,
      90.5,
      90.7,
      90.8,
      91.0,
      91.1,
      91.3,
      91.5,
      91.6,
      91.8,
      92.0,
      92.3,
      92.5,
      92.8,
      93.0,
      93.4,
      93.7,
      94.1,
      94.5,
      94.9,
      95.4,
      96.0,
      96.5,
      97.1,
      97.8,
      98.5
    ],
    1961: [
      84.5,
      86.2,
      86.4,
      86.5,
      86.5,
      86.6,
      86.6,
      86.6,
      86.7,
      86.7,
      86.7,
      86.7,
      86.7,
      86.7,
      86.8,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.3,
      87.4,
      87.4,
      87.4,
      87.4,
      87.5,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.2,
      88.2,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.4,
      90.5,
      90.6,
      90.8,
      90.9,
      91.1,
      91.2,
      91.4,
      91.5,
      91.7,
      91.9,
      92.1,
      92.3,
      92.6,
      92.8,
      93.1,
      93.4,
      93.7,
      94.1,
      94.5,
      95.0,
      95.4,
      96.0,
      96.5,
      97.2,
      97.8,
      98.5
    ],
    1962: [
      84.8,
      86.3,
      86.5,
      86.6,
      86.6,
      86.7,
      86.7,
      86.7,
      86.8,
      86.8,
      86.8,
      86.8,
      86.8,
      86.9,
      86.9,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.1,
      87.2,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.5,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.8,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.1,
      88.2,
      88.3,
      88.3,
      88.4,
      88.5,
      88.6,
      88.7,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.5,
      90.6,
      90.7,
      90.8,
      91.0,
      91.1,
      91.3,
      91.4,
      91.6,
      91.8,
      92.0,
      92.2,
      92.4,
      92.6,
      92.9,
      93.1,
      93.4,
      93.8,
      94.1,
      94.5,
      95.0,
      95.5,
      96.0,
      96.6,
      97.2,
      97.8,
      98.5
    ],
    1963: [
      85.1,
      86.5,
      86.7,
      86.8,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.0,
      87.0,
      87.1,
      87.1,
      87.1,
      87.1,
      87.1,
      87.2,
      87.2,
      87.3,
      87.3,
      87.3,
      87.4,
      87.4,
      87.5,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.2,
      88.3,
      88.3,
      88.4,
      88.5,
      88.5,
      88.6,
      88.7,
      88.8,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.6,
      90.7,
      90.8,
      90.9,
      91.1,
      91.2,
      91.4,
      91.5,
      91.7,
      91.8,
      92.0,
      92.2,
      92.4,
      92.7,
      92.9,
      93.2,
      93.5,
      93.8,
      94.2,
      94.6,
      95.0,
      95.5,
      96.0,
      96.6,
      97.2,
      97.8,
      98.5
    ],
    1964: [
      85.3,
      86.6,
      86.8,
      86.9,
      86.9,
      87.0,
      87.0,
      87.1,
      87.1,
      87.1,
      87.1,
      87.2,
      87.2,
      87.2,
      87.2,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.4,
      87.5,
      87.5,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.7,
      88.8,
      88.8,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.3,
      89.4,
      89.5,
      89.6,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.8,
      90.9,
      91.0,
      91.2,
      91.3,
      91.4,
      91.6,
      91.7,
      91.9,
      92.1,
      92.3,
      92.5,
      92.7,
      93.0,
      93.2,
      93.5,
      93.8,
      94.2,
      94.6,
      95.0,
      95.5,
      96.0,
      96.6,
      97.2,
      97.9,
      98.5
    ],
    1965: [
      85.5,
      86.9,
      87.0,
      87.1,
      87.1,
      87.2,
      87.2,
      87.2,
      87.3,
      87.3,
      87.3,
      87.3,
      87.3,
      87.4,
      87.4,
      87.4,
      87.5,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.7,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.5,
      89.6,
      89.6,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.9,
      91.0,
      91.1,
      91.2,
      91.4,
      91.5,
      91.7,
      91.8,
      92.0,
      92.1,
      92.3,
      92.5,
      92.8,
      93.0,
      93.3,
      93.6,
      93.9,
      94.2,
      94.6,
      95.1,
      95.5,
      96.0,
      96.6,
      97.2,
      97.9,
      98.6
    ],
    1966: [
      85.8,
      87.2,
      87.3,
      87.3,
      87.4,
      87.4,
      87.5,
      87.5,
      87.5,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.8,
      87.9,
      87.9,
      87.9,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      89.0,
      89.0,
      89.1,
      89.2,
      89.3,
      89.4,
      89.4,
      89.5,
      89.6,
      89.7,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.8,
      91.0,
      91.1,
      91.2,
      91.3,
      91.5,
      91.6,
      91.7,
      91.9,
      92.0,
      92.2,
      92.4,
      92.6,
      92.8,
      93.0,
      93.3,
      93.6,
      93.9,
      94.3,
      94.7,
      95.1,
      95.6,
      96.1,
      96.6,
      97.2,
      97.9,
      98.6
    ],
    1967: [
      85.9,
      87.2,
      87.4,
      87.4,
      87.5,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      87.9,
      88.0,
      88.0,
      88.1,
      88.1,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.1,
      89.1,
      89.2,
      89.3,
      89.3,
      89.4,
      89.5,
      89.6,
      89.6,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.8,
      90.9,
      91.0,
      91.2,
      91.3,
      91.4,
      91.5,
      91.7,
      91.8,
      92.0,
      92.1,
      92.3,
      92.4,
      92.6,
      92.9,
      93.1,
      93.3,
      93.6,
      93.9,
      94.3,
      94.7,
      95.1,
      95.6,
      96.1,
      96.6,
      97.2,
      97.9,
      98.6
    ],
    1968: [
      85.9,
      87.3,
      87.5,
      87.5,
      87.6,
      87.6,
      87.6,
      87.7,
      87.7,
      87.7,
      87.7,
      87.7,
      87.8,
      87.8,
      87.8,
      87.8,
      87.9,
      87.9,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.1,
      89.2,
      89.2,
      89.3,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.4,
      90.4,
      90.5,
      90.6,
      90.7,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.4,
      91.5,
      91.6,
      91.7,
      91.9,
      92.0,
      92.2,
      92.3,
      92.5,
      92.7,
      92.9,
      93.1,
      93.4,
      93.7,
      94.0,
      94.3,
      94.7,
      95.1,
      95.6,
      96.1,
      96.7,
      97.3,
      97.9,
      98.6
    ],
    1969: [
      86.3,
      87.5,
      87.7,
      87.7,
      87.8,
      87.8,
      87.9,
      87.9,
      87.9,
      87.9,
      87.9,
      88.0,
      88.0,
      88.0,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.3,
      89.3,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.7,
      89.8,
      89.9,
      90.0,
      90.1,
      90.1,
      90.2,
      90.3,
      90.4,
      90.5,
      90.6,
      90.6,
      90.7,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.4,
      91.6,
      91.7,
      91.8,
      91.9,
      92.1,
      92.2,
      92.4,
      92.6,
      92.8,
      93.0,
      93.2,
      93.4,
      93.7,
      94.0,
      94.4,
      94.7,
      95.2,
      95.6,
      96.1,
      96.7,
      97.3,
      97.9,
      98.6
    ],
    1970: [
      86.3,
      87.6,
      87.8,
      87.8,
      87.9,
      88.0,
      88.0,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.1,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.7,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.2,
      90.3,
      90.3,
      90.4,
      90.5,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.8,
      91.9,
      92.0,
      92.2,
      92.3,
      92.5,
      92.6,
      92.8,
      93.0,
      93.2,
      93.5,
      93.7,
      94.0,
      94.4,
      94.8,
      95.2,
      95.6,
      96.1,
      96.7,
      97.3,
      97.9,
      98.6
    ],
    1971: [
      86.5,
      87.8,
      88.0,
      88.0,
      88.1,
      88.1,
      88.1,
      88.2,
      88.2,
      88.2,
      88.2,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.1,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.4,
      89.5,
      89.5,
      89.6,
      89.7,
      89.7,
      89.8,
      89.9,
      89.9,
      90.0,
      90.1,
      90.1,
      90.2,
      90.3,
      90.4,
      90.4,
      90.5,
      90.6,
      90.7,
      90.8,
      90.8,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.7,
      91.8,
      92.0,
      92.1,
      92.2,
      92.4,
      92.5,
      92.7,
      92.9,
      93.1,
      93.3,
      93.5,
      93.8,
      94.1,
      94.4,
      94.8,
      95.2,
      95.7,
      96.2,
      96.7,
      97.3,
      97.9,
      98.6
    ],
    1972: [
      86.8,
      88.0,
      88.1,
      88.2,
      88.2,
      88.3,
      88.3,
      88.3,
      88.3,
      88.4,
      88.4,
      88.4,
      88.4,
      88.4,
      88.4,
      88.5,
      88.5,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      90.0,
      90.1,
      90.1,
      90.2,
      90.3,
      90.3,
      90.4,
      90.5,
      90.6,
      90.6,
      90.7,
      90.8,
      90.9,
      90.9,
      91.0,
      91.1,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.2,
      92.3,
      92.4,
      92.6,
      92.7,
      92.9,
      93.1,
      93.3,
      93.6,
      93.8,
      94.1,
      94.4,
      94.8,
      95.2,
      95.7,
      96.2,
      96.7,
      97.3,
      97.9,
      98.6
    ],
    1973: [
      87.0,
      88.2,
      88.3,
      88.4,
      88.4,
      88.5,
      88.5,
      88.5,
      88.6,
      88.6,
      88.6,
      88.6,
      88.6,
      88.6,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.4,
      90.5,
      90.5,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      91.0,
      91.0,
      91.1,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.2,
      92.3,
      92.5,
      92.6,
      92.8,
      93.0,
      93.1,
      93.4,
      93.6,
      93.9,
      94.2,
      94.5,
      94.8,
      95.2,
      95.7,
      96.2,
      96.7,
      97.3,
      98.0,
      98.6
    ],
    1974: [
      87.3,
      88.4,
      88.5,
      88.6,
      88.6,
      88.7,
      88.7,
      88.7,
      88.7,
      88.8,
      88.8,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.1,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.4,
      90.4,
      90.5,
      90.6,
      90.6,
      90.7,
      90.8,
      90.8,
      90.9,
      91.0,
      91.1,
      91.1,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.7,
      91.7,
      91.8,
      92.0,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.7,
      92.8,
      93.0,
      93.2,
      93.4,
      93.6,
      93.9,
      94.2,
      94.5,
      94.9,
      95.3,
      95.7,
      96.2,
      96.7,
      97.3,
      98.0,
      98.6
    ],
    1975: [
      87.4,
      88.6,
      88.7,
      88.7,
      88.8,
      88.8,
      88.8,
      88.9,
      88.9,
      88.9,
      88.9,
      88.9,
      88.9,
      89.0,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.5,
      90.5,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      91.0,
      91.0,
      91.1,
      91.2,
      91.2,
      91.3,
      91.4,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.2,
      92.4,
      92.5,
      92.6,
      92.7,
      92.9,
      93.1,
      93.2,
      93.4,
      93.7,
      93.9,
      94.2,
      94.5,
      94.9,
      95.3,
      95.7,
      96.2,
      96.8,
      97.3,
      98.0,
      98.7
    ],
    1976: [
      87.8,
      88.8,
      88.9,
      89.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.1,
      89.1,
      89.1,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.5,
      90.5,
      90.6,
      90.7,
      90.7,
      90.8,
      90.9,
      90.9,
      91.0,
      91.1,
      91.1,
      91.2,
      91.3,
      91.3,
      91.4,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.7,
      92.8,
      92.9,
      93.1,
      93.3,
      93.5,
      93.7,
      94.0,
      94.3,
      94.6,
      94.9,
      95.3,
      95.8,
      96.2,
      96.8,
      97.4,
      98.0,
      98.7
    ],
    1977: [
      88.0,
      89.0,
      89.1,
      89.2,
      89.2,
      89.2,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.8,
      90.8,
      90.9,
      91.0,
      91.0,
      91.1,
      91.2,
      91.2,
      91.3,
      91.4,
      91.4,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.6,
      92.7,
      92.9,
      93.0,
      93.2,
      93.3,
      93.5,
      93.8,
      94.0,
      94.3,
      94.6,
      94.9,
      95.3,
      95.8,
      96.3,
      96.8,
      97.4,
      98.0,
      98.7
    ],
    1978: [
      88.0,
      89.0,
      89.0,
      89.1,
      89.1,
      89.2,
      89.2,
      89.2,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.1,
      91.1,
      91.2,
      91.3,
      91.3,
      91.4,
      91.5,
      91.5,
      91.6,
      91.7,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.2,
      92.2,
      92.3,
      92.4,
      92.5,
      92.7,
      92.8,
      92.9,
      93.1,
      93.2,
      93.4,
      93.6,
      93.8,
      94.0,
      94.3,
      94.6,
      95.0,
      95.4,
      95.8,
      96.3,
      96.8,
      97.4,
      98.0,
      98.7
    ],
    1979: [
      88.3,
      89.2,
      89.2,
      89.3,
      89.3,
      89.4,
      89.4,
      89.4,
      89.4,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.7,
      91.7,
      91.8,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.6,
      92.7,
      92.8,
      93.0,
      93.1,
      93.3,
      93.4,
      93.6,
      93.8,
      94.1,
      94.4,
      94.7,
      95.0,
      95.4,
      95.8,
      96.3,
      96.8,
      97.4,
      98.0,
      98.7
    ],
    1980: [
      88.3,
      89.3,
      89.4,
      89.5,
      89.5,
      89.5,
      89.5,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.8,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.6,
      92.7,
      92.8,
      92.9,
      93.0,
      93.2,
      93.3,
      93.5,
      93.7,
      93.9,
      94.1,
      94.4,
      94.7,
      95.0,
      95.4,
      95.8,
      96.3,
      96.8,
      97.4,
      98.0,
      98.7
    ],
    1981: [
      88.5,
      89.4,
      89.5,
      89.6,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.7,
      91.7,
      91.8,
      91.9,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.4,
      92.5,
      92.5,
      92.6,
      92.7,
      92.8,
      93.0,
      93.1,
      93.2,
      93.4,
      93.5,
      93.7,
      93.9,
      94.2,
      94.4,
      94.7,
      95.1,
      95.4,
      95.9,
      96.3,
      96.9,
      97.4,
      98.0,
      98.7
    ],
    1982: [
      88.6,
      89.6,
      89.6,
      89.6,
      89.7,
      89.7,
      89.7,
      89.7,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.8,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.4,
      92.4,
      92.5,
      92.6,
      92.7,
      92.8,
      92.9,
      93.0,
      93.1,
      93.3,
      93.4,
      93.6,
      93.8,
      94.0,
      94.2,
      94.5,
      94.7,
      95.1,
      95.5,
      95.9,
      96.4,
      96.9,
      97.4,
      98.1,
      98.7
    ],
    1983: [
      88.7,
      89.7,
      89.8,
      89.8,
      89.9,
      89.9,
      89.9,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.3,
      92.4,
      92.4,
      92.5,
      92.6,
      92.7,
      92.8,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.5,
      93.6,
      93.8,
      94.0,
      94.2,
      94.5,
      94.8,
      95.1,
      95.5,
      95.9,
      96.4,
      96.9,
      97.5,
      98.1,
      98.7
    ],
    1984: [
      89.0,
      89.9,
      89.9,
      90.0,
      90.0,
      90.0,
      90.0,
      90.1,
      90.1,
      90.1,
      90.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.2,
      92.2,
      92.3,
      92.4,
      92.4,
      92.5,
      92.6,
      92.7,
      92.7,
      92.8,
      92.9,
      93.0,
      93.1,
      93.2,
      93.4,
      93.5,
      93.7,
      93.8,
      94.0,
      94.3,
      94.5,
      94.8,
      95.1,
      95.5,
      95.9,
      96.4,
      96.9,
      97.5,
      98.1,
      98.7
    ],
    1985: [
      89.2,
      90.0,
      90.1,
      90.1,
      90.2,
      90.2,
      90.2,
      90.2,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.4,
      92.4,
      92.5,
      92.6,
      92.7,
      92.7,
      92.8,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.4,
      93.6,
      93.7,
      93.9,
      94.1,
      94.3,
      94.5,
      94.8,
      95.2,
      95.5,
      95.9,
      96.4,
      96.9,
      97.5,
      98.1,
      98.8
    ],
    1986: [
      89.1,
      90.1,
      90.1,
      90.2,
      90.2,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.6,
      92.6,
      92.7,
      92.8,
      92.9,
      93.0,
      93.0,
      93.1,
      93.2,
      93.3,
      93.5,
      93.6,
      93.8,
      93.9,
      94.1,
      94.3,
      94.6,
      94.9,
      95.2,
      95.6,
      96.0,
      96.4,
      96.9,
      97.5,
      98.1,
      98.8
    ],
    1987: [
      89.4,
      90.3,
      90.3,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.4,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.5,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.8,
      92.9,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.4,
      93.5,
      93.6,
      93.8,
      94.0,
      94.2,
      94.4,
      94.6,
      94.9,
      95.2,
      95.6,
      96.0,
      96.4,
      96.9,
      97.5,
      98.1,
      98.8
    ],
    1988: [
      89.6,
      90.4,
      90.4,
      90.5,
      90.5,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.7,
      92.7,
      92.8,
      92.9,
      92.9,
      93.0,
      93.1,
      93.2,
      93.3,
      93.3,
      93.5,
      93.6,
      93.7,
      93.8,
      94.0,
      94.2,
      94.4,
      94.6,
      94.9,
      95.2,
      95.6,
      96.0,
      96.5,
      97.0,
      97.5,
      98.1,
      98.8
    ],
    1989: [
      89.8,
      90.6,
      90.6,
      90.6,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.9,
      92.9,
      93.0,
      93.1,
      93.1,
      93.2,
      93.3,
      93.4,
      93.5,
      93.6,
      93.7,
      93.9,
      94.0,
      94.2,
      94.4,
      94.7,
      95.0,
      95.3,
      95.6,
      96.0,
      96.5,
      97.0,
      97.5,
      98.1,
      98.8
    ],
    1990: [
      90.0,
      90.6,
      90.7,
      90.7,
      90.7,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.9,
      92.9,
      93.0,
      93.1,
      93.1,
      93.2,
      93.3,
      93.4,
      93.5,
      93.6,
      93.7,
      93.8,
      93.9,
      94.1,
      94.3,
      94.5,
      94.7,
      95.0,
      95.3,
      95.6,
      96.0,
      96.5,
      97.0,
      97.5,
      98.1,
      98.8
    ],
    1991: [
      90.1,
      90.7,
      90.8,
      90.8,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.2,
      93.3,
      93.3,
      93.4,
      93.5,
      93.6,
      93.7,
      93.8,
      94.0,
      94.1,
      94.3,
      94.5,
      94.7,
      95.0,
      95.3,
      95.7,
      96.1,
      96.5,
      97.0,
      97.6,
      98.2,
      98.8
    ],
    1992: [
      90.3,
      90.9,
      91.0,
      91.0,
      91.0,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.3,
      93.4,
      93.5,
      93.6,
      93.7,
      93.8,
      93.9,
      94.0,
      94.2,
      94.3,
      94.5,
      94.8,
      95.0,
      95.3,
      95.7,
      96.1,
      96.5,
      97.0,
      97.6,
      98.2,
      98.8
    ],
    1993: [
      90.4,
      91.0,
      91.0,
      91.1,
      91.1,
      91.1,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.3,
      93.4,
      93.4,
      93.5,
      93.6,
      93.7,
      93.8,
      93.9,
      94.1,
      94.2,
      94.4,
      94.6,
      94.8,
      95.1,
      95.4,
      95.7,
      96.1,
      96.5,
      97.0,
      97.6,
      98.2,
      98.8
    ],
    1994: [
      90.4,
      91.1,
      91.1,
      91.2,
      91.2,
      91.2,
      91.2,
      91.2,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.3,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.3,
      93.4,
      93.4,
      93.5,
      93.6,
      93.7,
      93.8,
      93.9,
      94.0,
      94.1,
      94.2,
      94.4,
      94.6,
      94.8,
      95.1,
      95.4,
      95.7,
      96.1,
      96.6,
      97.1,
      97.6,
      98.2,
      98.8
    ],
    1995: [
      90.7,
      91.2,
      91.3,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.3,
      93.4,
      93.4,
      93.5,
      93.6,
      93.6,
      93.7,
      93.8,
      93.9,
      94.0,
      94.1,
      94.3,
      94.5,
      94.6,
      94.9,
      95.1,
      95.4,
      95.8,
      96.1,
      96.6,
      97.1,
      97.6,
      98.2,
      98.9
    ],
    1996: [
      90.7,
      91.3,
      91.4,
      91.4,
      91.4,
      91.4,
      91.4,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.3,
      93.3,
      93.4,
      93.4,
      93.5,
      93.5,
      93.6,
      93.7,
      93.8,
      93.9,
      94.0,
      94.1,
      94.2,
      94.3,
      94.5,
      94.7,
      94.9,
      95.2,
      95.4,
      95.8,
      96.2,
      96.6,
      97.1,
      97.6,
      98.2,
      98.9
    ],
    1997: [
      91.0,
      91.5,
      91.5,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.3,
      93.3,
      93.4,
      93.4,
      93.5,
      93.5,
      93.6,
      93.7,
      93.7,
      93.8,
      93.9,
      94.0,
      94.1,
      94.2,
      94.4,
      94.5,
      94.7,
      94.9,
      95.2,
      95.5,
      95.8,
      96.2,
      96.6,
      97.1,
      97.6,
      98.2,
      98.9
    ],
    1998: [
      91.2,
      91.6,
      91.6,
      91.6,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.4,
      93.4,
      93.5,
      93.5,
      93.6,
      93.7,
      93.7,
      93.8,
      93.9,
      93.9,
      94.0,
      94.1,
      94.3,
      94.4,
      94.6,
      94.8,
      95.0,
      95.2,
      95.5,
      95.8,
      96.2,
      96.6,
      97.1,
      97.7,
      98.2,
      98.9
    ],
    1999: [
      91.2,
      91.6,
      91.7,
      91.7,
      91.7,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.6,
      93.7,
      93.7,
      93.8,
      93.8,
      93.9,
      94.0,
      94.1,
      94.2,
      94.3,
      94.4,
      94.6,
      94.8,
      95.0,
      95.2,
      95.5,
      95.9,
      96.2,
      96.7,
      97.1,
      97.7,
      98.3,
      98.9
    ],
    2000: [
      91.3,
      91.8,
      91.8,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      91.9,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.5,
      93.5,
      93.6,
      93.6,
      93.7,
      93.7,
      93.8,
      93.8,
      93.9,
      94.0,
      94.0,
      94.1,
      94.2,
      94.4,
      94.5,
      94.6,
      94.8,
      95.0,
      95.3,
      95.6,
      95.9,
      96.2,
      96.7,
      97.1,
      97.7,
      98.3,
      98.9
    ],
    2001: [
      91.5,
      91.9,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.6,
      93.6,
      93.7,
      93.7,
      93.8,
      93.8,
      93.9,
      93.9,
      94.0,
      94.1,
      94.2,
      94.3,
      94.4,
      94.5,
      94.7,
      94.9,
      95.1,
      95.3,
      95.6,
      95.9,
      96.3,
      96.7,
      97.2,
      97.7,
      98.3,
      98.9
    ],
    2002: [
      91.5,
      92.0,
      92.0,
      92.0,
      92.0,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.7,
      93.7,
      93.8,
      93.8,
      93.9,
      93.9,
      94.0,
      94.1,
      94.1,
      94.2,
      94.3,
      94.4,
      94.6,
      94.7,
      94.9,
      95.1,
      95.3,
      95.6,
      95.9,
      96.3,
      96.7,
      97.2,
      97.7,
      98.3,
      98.9
    ],
    2003: [
      91.6,
      92.0,
      92.1,
      92.1,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.8,
      93.8,
      93.9,
      93.9,
      94.0,
      94.0,
      94.1,
      94.2,
      94.3,
      94.4,
      94.5,
      94.6,
      94.7,
      94.9,
      95.1,
      95.4,
      95.6,
      95.9,
      96.3,
      96.7,
      97.2,
      97.7,
      98.3,
      98.9
    ],
    2004: [
      91.7,
      92.2,
      92.2,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.9,
      93.9,
      94.0,
      94.0,
      94.1,
      94.2,
      94.2,
      94.3,
      94.4,
      94.5,
      94.6,
      94.8,
      95.0,
      95.2,
      95.4,
      95.7,
      96.0,
      96.3,
      96.7,
      97.2,
      97.7,
      98.3,
      98.9
    ],
    2005: [
      91.8,
      92.3,
      92.3,
      92.3,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      94.0,
      94.0,
      94.1,
      94.1,
      94.2,
      94.3,
      94.4,
      94.4,
      94.6,
      94.7,
      94.8,
      95.0,
      95.2,
      95.4,
      95.7,
      96.0,
      96.3,
      96.8,
      97.2,
      97.7,
      98.3,
      99.0
    ],
    2006: [
      91.9,
      92.3,
      92.4,
      92.4,
      92.4,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.1,
      94.1,
      94.2,
      94.3,
      94.3,
      94.4,
      94.5,
      94.6,
      94.7,
      94.9,
      95.0,
      95.2,
      95.4,
      95.7,
      96.0,
      96.4,
      96.8,
      97.2,
      97.8,
      98.3,
      99.0
    ],
    2007: [
      92.0,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.5,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.2,
      94.2,
      94.3,
      94.4,
      94.4,
      94.5,
      94.6,
      94.8,
      94.9,
      95.1,
      95.2,
      95.5,
      95.7,
      96.0,
      96.4,
      96.8,
      97.3,
      97.8,
      98.3,
      99.0
    ],
    2008: [
      92.1,
      92.5,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.6,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.3,
      94.3,
      94.4,
      94.5,
      94.6,
      94.7,
      94.8,
      94.9,
      95.1,
      95.3,
      95.5,
      95.8,
      96.1,
      96.4,
      96.8,
      97.3,
      97.8,
      98.4,
      99.0
    ],
    2009: [
      92.2,
      92.6,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.3,
      94.3,
      94.4,
      94.5,
      94.5,
      94.6,
      94.7,
      94.8,
      95.0,
      95.1,
      95.3,
      95.5,
      95.8,
      96.1,
      96.4,
      96.8,
      97.3,
      97.8,
      98.4,
      99.0
    ],
    2010: [
      92.3,
      92.7,
      92.7,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.4,
      94.4,
      94.5,
      94.6,
      94.7,
      94.8,
      94.9,
      95.0,
      95.2,
      95.3,
      95.6,
      95.8,
      96.1,
      96.4,
      96.8,
      97.3,
      97.8,
      98.4,
      99.0
    ],
    2011: [
      92.4,
      92.8,
      92.8,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.4,
      94.4,
      94.5,
      94.5,
      94.6,
      94.7,
      94.8,
      94.9,
      95.0,
      95.2,
      95.4,
      95.6,
      95.8,
      96.1,
      96.5,
      96.9,
      97.3,
      97.8,
      98.4,
      99.0
    ],
    2012: [
      92.5,
      92.9,
      92.9,
      92.9,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.3,
      94.4,
      94.4,
      94.5,
      94.5,
      94.6,
      94.7,
      94.7,
      94.8,
      94.9,
      95.1,
      95.2,
      95.4,
      95.6,
      95.9,
      96.1,
      96.5,
      96.9,
      97.3,
      97.8,
      98.4,
      99.0
    ],
    2013: [
      92.6,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.0,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.3,
      94.4,
      94.4,
      94.5,
      94.5,
      94.6,
      94.6,
      94.7,
      94.8,
      94.9,
      95.0,
      95.1,
      95.2,
      95.4,
      95.6,
      95.9,
      96.2,
      96.5,
      96.9,
      97.3,
      97.9,
      98.4,
      99.0
    ],
    2014: [
      92.6,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.3,
      94.4,
      94.4,
      94.4,
      94.5,
      94.5,
      94.6,
      94.6,
      94.7,
      94.7,
      94.8,
      94.9,
      95.0,
      95.1,
      95.3,
      95.5,
      95.7,
      95.9,
      96.2,
      96.5,
      96.9,
      97.4,
      97.9,
      98.4,
      99.1
    ],
    2015: [
      92.7,
      93.1,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.3,
      94.3,
      94.4,
      94.4,
      94.4,
      94.5,
      94.5,
      94.6,
      94.6,
      94.7,
      94.7,
      94.8,
      94.9,
      94.9,
      95.0,
      95.2,
      95.3,
      95.5,
      95.7,
      95.9,
      96.2,
      96.5,
      96.9,
      97.4,
      97.9,
      98.4,
      99.1
    ],
    2016: [
      92.9,
      93.2,
      93.2,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.3,
      94.3,
      94.4,
      94.4,
      94.4,
      94.5,
      94.5,
      94.5,
      94.6,
      94.6,
      94.7,
      94.7,
      94.8,
      94.8,
      94.9,
      95.0,
      95.1,
      95.2,
      95.3,
      95.5,
      95.7,
      96.0,
      96.2,
      96.6,
      97.0,
      97.4,
      97.9,
      98.5,
      99.1
    ],
    2017: [
      93.0,
      93.3,
      93.3,
      93.3,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.3,
      94.3,
      94.3,
      94.4,
      94.4,
      94.4,
      94.5,
      94.5,
      94.5,
      94.5,
      94.6,
      94.6,
      94.7,
      94.7,
      94.7,
      94.8,
      94.9,
      94.9,
      95.0,
      95.1,
      95.2,
      95.4,
      95.5,
      95.7,
      96.0,
      96.3,
      96.6,
      97.0,
      97.4,
      97.9,
      98.5,
      99.1
    ],
    2018: [
      93.0,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.4,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.5,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.6,
      93.7,
      93.7,
      93.7,
      93.7,
      93.7,
      93.7,
      93.7,
      93.7,
      93.8,
      93.8,
      93.8,
      93.8,
      93.8,
      93.9,
      93.9,
      93.9,
      93.9,
      93.9,
      94.0,
      94.0,
      94.0,
      94.0,
      94.1,
      94.1,
      94.1,
      94.1,
      94.2,
      94.2,
      94.2,
      94.2,
      94.2,
      94.3,
      94.3,
      94.3,
      94.3,
      94.3,
      94.4,
      94.4,
      94.4,
      94.4,
      94.5,
      94.5,
      94.5,
      94.5,
      94.6,
      94.6,
      94.6,
      94.7,
      94.7,
      94.7,
      94.8,
      94.8,
      94.9,
      95.0,
      95.1,
      95.2,
      95.3,
      95.4,
      95.6,
      95.8,
      96.0,
      96.3,
      96.6,
      97.0,
      97.4,
      97.9,
      98.5,
      99.1
    ]
  }
  let lifeExpectancy = Math.round(data[gender][birthYear][currentAge])
  return lifeExpectancy
}

export default {
  getLifeExpectancy(gender, currentAge, birthYear) {
    return lifeExpectancy(gender, currentAge, birthYear)
  }
}
