function todaysDate() {
  let date = new Date()
  let yyyy = date.getFullYear().toString()
  let mm = (date.getMonth() + 1).toString()
  let dd = date.getDate().toString()

  let mmChars = mm.split('')
  let ddChars = dd.split('')

  return (
    yyyy +
    '-' +
    (mmChars[1] ? mm : '0' + mmChars[0]) +
    '-' +
    (ddChars[1] ? dd : '0' + ddChars[0])
  )
}

function todaysYear() {
  let date = new Date()
  let yyyy = date.getFullYear().toString()
  return yyyy
}

function randomIdBetween(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min) //The maximum and minimum is inclusive
}

function numberOfDays(date1, date2) {
  var days = Math.ceil((Date.parse(date1) - Date.parse(date2)) / 86400000)
  return days
}

export default {
  dateToday() {
    return todaysDate()
  },

  yearToday() {
    return todaysYear()
  },

  getRandomIdBetween(min, max) {
    return randomIdBetween(min, max)
  },

  getNumberOfDays(date1, date2) {
    return numberOfDays(date1, date2)
  }
}
