import store from '@/vuex/store'

// Goal Attribute generation
function generateGoalAttributes(goal) {
  return new Promise(resolve => {
    // Process individual Retirement Goal
    if (goal.id == 2 && store.getters.clientProfileType == 'Individual') {
      // set the goal headline
      goal.proposedData.headlinePerson1 =
        store.getters.clientPerson1.firstName + "'s " + 'Retirement'
      // set the retirement year for the person according to retirement age
      goal.proposedData.yearPerson1 =
        Number(store.getters.clientPerson1.birthDate.substring(0, 4)) +
        goal.proposedData.retirementAgePerson1

      if (goal.proposedData.retirementExpenseType == 'rate') {
        // generate personalized simple description
        goal.proposedData.description =
          'at age ' +
          goal.proposedData.retirementAgePerson1 +
          ', maint. ' +
          goal.proposedData.retirementExpenseRate +
          '% of expenses'

        // generate personalized enhanced (per person) description
        goal.proposedData.descriptionEnhancedPerson1 =
          store.getters.clientPerson1.firstName +
          ' retires at age ' +
          goal.proposedData.retirementAgePerson1 +
          ' and maintains ' +
          goal.proposedData.retirementExpenseRate +
          '% of the current expenses in Retirement, adjusted for inflation'
      } else {
        // generate personalized simple description
        goal.proposedData.description =
          'at age ' +
          goal.proposedData.retirementAgePerson1 +
          ', recieve ' +
          goal.proposedData.retirementExpenseValue.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) +
          ' ' +
          store.getters.currencyApp +
          ' p.a.'

        // generate personalized enhanced (per person) description
        goal.proposedData.descriptionEnhancedPerson1 =
          store.getters.clientPerson1.firstName +
          ' retires at age ' +
          goal.proposedData.retirementAgePerson1 +
          ', while receiving income of ' +
          goal.proposedData.retirementExpenseValue.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) +
          ' ' +
          store.getters.currencyApp +
          ' p.a., adjusted for inflation'
      }
    }

    // Process joint Retirement Goal
    else if (goal.id == 2 && store.getters.clientProfileType == 'Joint') {
      // set the goal headline
      goal.proposedData.headlinePerson1 =
        store.getters.clientPerson1.firstName + "'s " + 'Retirement'
      goal.proposedData.headlinePerson2 =
        store.getters.clientPerson2.firstName + "'s " + 'Retirement'
      // set the retirement year for the persons according to retirement age
      goal.proposedData.yearPerson1 =
        Number(store.getters.clientPerson1.birthDate.substring(0, 4)) +
        goal.proposedData.retirementAgePerson1
      goal.proposedData.yearPerson2 =
        Number(store.getters.clientPerson2.birthDate.substring(0, 4)) +
        goal.proposedData.retirementAgePerson2

      // generate personalized simple description
      goal.proposedData.description =
        store.getters.clientPerson1.firstName +
        ' at age ' +
        goal.proposedData.retirementAgePerson1 +
        ' / ' +
        store.getters.clientPerson2.firstName +
        ' at age ' +
        goal.proposedData.retirementAgePerson2

      if (goal.proposedData.retirementExpenseType == 'rate') {
        // generate personalized enhanced (per person) description for rated income
        goal.proposedData.descriptionEnhancedPerson1 =
          store.getters.clientPerson1.firstName +
          ' retires at age ' +
          goal.proposedData.retirementAgePerson1 +
          ', while maintaining ' +
          goal.proposedData.retirementExpenseRate +
          '% of the current, combined expenses in Retirement, adjusted for inflation'
        goal.proposedData.descriptionEnhancedPerson2 =
          store.getters.clientPerson2.firstName +
          ' retires at age ' +
          goal.proposedData.retirementAgePerson2 +
          ', while maintaining ' +
          goal.proposedData.retirementExpenseRate +
          '% of the current, combined expenses in Retirement, adjusted for inflation'
      } else {
        // generate personalized enhanced (per person) description for fixed income
        goal.proposedData.descriptionEnhancedPerson1 =
          store.getters.clientPerson1.firstName +
          ' retires at age ' +
          goal.proposedData.retirementAgePerson1 +
          ', while receiving income of ' +
          goal.proposedData.retirementExpenseValue.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) +
          ' ' +
          store.getters.currencyApp +
          ' p.a., adjusted for inflation'
        goal.proposedData.descriptionEnhancedPerson2 =
          store.getters.clientPerson2.firstName +
          ' retires at age ' +
          goal.proposedData.retirementAgePerson2 +
          ', while receiving income of ' +
          goal.proposedData.retirementExpenseValue.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) +
          ' ' +
          store.getters.currencyApp +
          ' p.a., adjusted for inflation'
      }
    }

    resolve(goal)
  })
}

export default {
  // Compute a single Goal (on goal update)
  computeGoal(goal) {
    return new Promise((resolve, reject) => {
      generateGoalAttributes(goal)
        .then(goal => {
          resolve(goal)
        })
        .catch(err => {
          console.log('ERROR - failed to compute goal attributes', err.response)
          const notification = {
            type: 'error',
            message: err.message
          }
          store.dispatch('addNotification', notification)
          reject()
        })
    })
  }
}
