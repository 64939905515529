<template>
  <div>
    <v-app>
      <component :is="layout">
        <v-main>
          <router-view :key="$route.fullPath" class="page" />
        </v-main>
      </component>
      <NotificationContainer />
    </v-app>
  </div>
</template>

<script>
import NotificationContainer from '@/components/NotificationContainer.vue'
const default_layout = 'default'

export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout'
    }
  },
  components: {
    NotificationContainer
  }
}
</script>

<style lang="scss">
.page {
  display: flex;
  flex-direction: column;
}
</style>
