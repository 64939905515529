import { Auth } from 'aws-amplify'
import store from '@/vuex/store'
import router from '@/router/index'

export const state = {
  cognitoUser: null
}

export const mutations = {
  SET_COGNITO_USER(state, cognitoUserData) {
    state.cognitoUser = cognitoUserData
    localStorage.removeItem('cognitoUser')
    localStorage.setItem('cognitoUser', JSON.stringify(cognitoUserData))
  },

  CLEAR_COGNITO_USER() {
    localStorage.removeItem('cognitoUser')
    router.push('/')
  }
}

export const actions = {
  login({ commit, dispatch }, cognitoUser) {
    commit('SET_COGNITO_USER', cognitoUser)
    dispatch('fetchUserDataOnLogin')
    console.log('Falcon9 user state is logged in', cognitoUser)
  },

  logout({ commit, dispatch }) {
    commit('CLEAR_COGNITO_USER')
    dispatch('clearUserDataOnLogout')
    console.log('Falcon9 user state is logged out')
  },

  async syncCognitoUserState({ commit }) {
    let cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
    console.log('Current User after Attribute Update', cognitoUser)
    commit('SET_COGNITO_USER', cognitoUser)
  },

  async updateCognitoUserName({ dispatch }) {
    let name =
      store.state.advisor.profile.firstName +
      ' ' +
      store.state.advisor.profile.familyName
    let user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      given_name: store.state.advisor.profile.firstName,
      family_name: store.state.advisor.profile.familyName,
      name: name
    })
      .then(result => {
        console.log('Successful update of Cognito Attributes', result)
        dispatch('syncCognitoUserState')
      })
      .catch(err => {
        console.log('Error updating Cognito Attributes', err)
        const notification = {
          type: 'error',
          message: err.message
        }
        dispatch('addNotification', notification)
      })
  },

  async updateCognitoEmail({ dispatch }, emailAddress) {
    let user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      email: emailAddress
    })
      .then(result => {
        console.log('Successful update of Cognito Email Address', result)
        dispatch('syncCognitoUserState')
      })
      .catch(err => {
        console.log('Error updating Cognito Email Address', err)
        const notification = {
          type: 'error',
          message: err.message
        }
        dispatch('addNotification', notification)
      })
  },

  async confirmCognitoCode({ dispatch }, code) {
    await Auth.verifyCurrentUserAttributeSubmit('email', code)
      .then(result => {
        console.log(
          'Successful verification of new Cognito Email Address',
          result
        )
        dispatch('syncCognitoUserState')
      })
      .catch(err => {
        console.log('Error verifying new Cognito Email Address', err)
        const notification = {
          type: 'error',
          message: err.message
        }
        dispatch('addNotification', notification)
      })
  },

  async updateCognitoPhone({ dispatch }) {
    let user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      phone_number: store.state.advisor.profile.mobilePhone
    })
      .then(result => {
        console.log('Successful update of Cognito Phone', result)
        dispatch('syncCognitoUserState')
      })
      .catch(err => {
        console.log('Error updating Cognito Phone', err)
        const notification = {
          type: 'error',
          message: err.message
        }
        dispatch('addNotification', notification)
      })
  }
}

export const getters = {
  loggedIn(state) {
    return !!state.cognitoUser
    // returns the truthiness of the user object
  },

  role(state) {
    return state.cognitoUser.attributes['custom:role']
  },

  tenantName(state) {
    return state.cognitoUser.attributes['custom:tenant_name']
  },

  userName(state) {
    return state.cognitoUser.attributes.name
  },

  tenantId(state) {
    return state.cognitoUser.attributes['custom:tenant_id']
  },

  userId(state) {
    if (
      (state.cognitoUser.attributes['custom:role'] === 'TenAdm') |
      (state.cognitoUser.attributes['custom:role'] === 'TenAdv')
    ) {
      return 'A-' + state.cognitoUser.attributes.sub
    } else if (state.cognitoUser.attributes['custom:role'] === 'TenClt') {
      return 'C-' + state.cognitoUser.attributes.sub
    }
  },

  userEmail(state) {
    return state.cognitoUser.attributes.email
  },

  cognitoUserName(state) {
    return state.cognitoUser.username
  },

  cognitoUserPreferredMFA(state) {
    return state.cognitoUser.preferredMFA
  },

  userPhone(state) {
    return state.cognitoUser.attributes.phone_number
  },

  userEmailVerified(state) {
    return state.cognitoUser.attributes.email_verified
    // returns the truthiness of user email verified
  }
}
