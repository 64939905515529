import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // meta: { layout: 'default' },
    // component: require('@/views/Home.vue').default
    component: () => import('@/views/Home.vue')
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   meta: { requiresNotAuth: true },
  //   component: () => import('@/views/auth/Register.vue')
  // },
  {
    path: '/login',
    name: 'login',
    meta: { requiresNotAuth: true },
    component: () => import('@/views/auth/Login.vue')
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('@/views/auth/Confirm.vue')
  },
  {
    path: '/forgotpassword',
    name: 'forgotPassword',
    component: () => import('@/views/auth/ForgotPassword.vue')
  },
  {
    path: '/resetpassword',
    name: 'resetPassword',
    component: () => import('@/views/auth/ResetPassword.vue')
  },
  {
    path: '/advisor/dashboard',
    name: 'advisorDashboard',
    meta: { layout: 'advisor', requiresAuth: true },
    component: () => import('@/views/advisor/AdvisorDashboard.vue')
  },
  {
    path: '/advisor/profile',
    name: 'advisorProfile',
    meta: { layout: 'advisor', requiresAuth: true },
    component: () => import('@/views/advisor/AdvisorProfile.vue')
  },
  {
    path: '/advisor/client-new',
    name: 'advisorClientNew',
    meta: { layout: 'advisor', requiresAuth: true },
    component: () => import('@/views/advisor/AdvisorClientNew.vue')
  },
  {
    path: '/advisor/definitions/:tabs',
    name: 'advisorDefinitions',
    meta: { layout: 'advisor', requiresAuth: true },
    component: () => import('@/views/advisor/AdvisorDefinitions.vue')
  },
  {
    path: '/client/dashboard',
    name: 'clientDashboard',
    meta: { layout: 'client', requiresAuth: true },
    component: () => import('@/views/client/ClientDashboard.vue')
  },
  {
    path: '/client/ledger/:tabs',
    name: 'clientLedger',
    meta: { layout: 'client', requiresAuth: true },
    component: () => import('@/views/client/ClientLedger.vue')
  },
  {
    path: '/client/projection/:tabs',
    name: 'clientProjection',
    meta: { layout: 'client', requiresAuth: true },
    component: () => import('@/views/client/ClientProjection.vue')
  },
  {
    path: '/client/performance',
    name: 'clientPerformance',
    meta: { layout: 'client', requiresAuth: true },
    component: () => import('@/views/client/ClientPerformance.vue')
  },
  // {
  //   path: '/client/retirement/:tabs',
  //   name: 'clientRetirement',
  //   meta: { layout: 'client', requiresAuth: true },
  //   component: () => import('@/views/client/ClientRetirement.vue')
  // },
  {
    path: '/client/profile/:tabs',
    name: 'clientProfile',
    meta: { layout: 'client', requiresAuth: true },
    component: () => import('@/views/client/ClientProfile.vue')
  },
  {
    path: '/admin/advisors',
    name: 'adminAdvisors',
    meta: { layout: 'admin', requiresAuth: true },
    component: () => import('@/views/admin/AdminAdvisors.vue')
  },
  {
    path: '/admin/definitions/:tabs',
    name: 'adminDefinitions',
    meta: { layout: 'admin', requiresAuth: true },
    component: () => import('@/views/admin/AdminDefinitions.vue')
  },
  {
    path: '/admin/advisor-profile',
    name: 'adminAdvisorProfile',
    meta: { layout: 'admin', requiresAuth: true },
    component: () => import('@/views/admin/AdminAdvisorProfile.vue')
  },
  {
    path: '/tour/dashboard',
    name: 'tourDashboard',
    meta: { layout: 'tour', requiresAuth: true },
    component: () => import('@/views/tour/TourDashboard.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/404.vue')
  },
  {
    path: '*',
    redirect: { name: '404' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// method is used to make sure, the user is authenticated before opening protected route
router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let user
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      .then(data => {
        if (data && data.signInUserSession) {
          user = data
          next() // User is authenticated -> allow to proceed to protected route
          console.log(
            'Check successful! User is authenticated, proceed displaying protected data',
            user
          )
        }
      })
      .catch(() => {
        next({
          path: '/login' // User is not authenticated -> redirect to the login page
        })
      })
  }
  // redirect if user is already logged in
  else if (to.matched.some(record => record.meta.requiresNotAuth)) {
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      .then(data => {
        if (data && data.signInUserSession) {
          next({
            path: '/advisor/dashboard' // User is already authenticated -> redirect to dashboard
          })
          console.log(
            'Check successful! User is authenticated, redirect to advisor dashboard'
          )
        }
      })
      .catch(() => {
        next() // User is not authenticated -> no redirect necessary
      })
  } else next() // no authentication needed
})

export default router
