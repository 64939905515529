import { API } from 'aws-amplify'

export const state = {
  dataChanged: false, // indicates if user has made changes (state = true) in a component, which are not saved yet
  fx: null, // holds fx object with all currency pairs for the day
  tabClientLayout: null, // tab for main client navigation - 0 Dashboard, 1 Profile, 2 Ledger, 3 Performance, 4 Projections,
  tabAdvisorLayout: null, // tab for main advisor navigation - 0 Dashboard, 1 Definitions, 2 Portfolio, 3 SOA, 4 Settings
  tabAdminLayout: null, // tab for main admin navigation - 0 Dashboard, 1 Accounts, 2 Model Portfolios, 3 Definitions, 4 Subscription
  caroClientProfile: null, // carousel for client profile - 0 Assumptions, 1 Person One, 2 Person Two, 3 Risk Type, 4 Model Portfolio
  caroClientLedger: null, // carousel for client ledger - 0 Assets, 1 Liabilities, 2 Income, 3 Expenses, 4 Goals
  caroClientProjection: null, // carousel for client projection - 0 FinancialPlan, 1 Assets
  caroAdvisorDefinitions: null, // carousel for advisor definitions - 0 Assets, 1 Liabilities, 2 Income, 3 Expenses, 4 Model-Portfolios
  caroAdminDefinitions: null // carousel for admin definitions - 0 Assets, 1 Liabilities, 2 Income, 3 Expenses, 4 Risk-Types 5 Model-Portfolios
}

export const mutations = {
  SET_DATA_CHANGED(state, dataChanged) {
    // indicates if user has made changes (dataChanged = true) to any projection, which are not saved yet
    state.dataChanged = dataChanged
  },

  SET_FX_RATES(state, attributes) {
    console.log('SET_FX_RATES', attributes)
    state.fx = attributes
  },

  SET_CLIENT_LAYOUT_TAB(state, attributes) {
    state.tabClientLayout = attributes
  },

  SET_ADVISOR_LAYOUT_TAB(state, attributes) {
    state.tabAdvisorLayout = attributes
  },

  SET_ADMIN_LAYOUT_TAB(state, attributes) {
    state.tabAdminLayout = attributes
  },

  SET_CLIENT_LEDGER_CARO(state, attributes) {
    state.caroClientLedger = attributes
  },

  SET_CLIENT_PROJECTION_CARO(state, attributes) {
    state.caroClientProjection = attributes
  },

  SET_CLIENT_PROFILE_CARO(state, attributes) {
    state.caroClientProfile = attributes
  },

  SET_ADVISOR_DEFINITIONS_CARO(state, attributes) {
    state.caroAdvisorDefinitions = attributes
  },

  SET_ADMIN_DEFINITIONS_CARO(state, attributes) {
    state.caroAdminDefinitions = attributes
  }
}

export const actions = {
  // Fiat-, Crypto- and Metal- Rates and Currencies
  fetchFxRatesToday({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      if (!state.fx) {
        let path = '/system/fx'
        API.get('Falcon9API', path)
          .then(event => {
            commit('SET_FX_RATES', event.response.Item.ratesLatest)
            console.log(
              'SUCCESS - API retrieved fx rates',
              event.response.Item.ratesLatest
            )
            resolve()
          })
          .catch(err => {
            console.log('ERROR - API failed to retrieve fx rates', err.response)
            const notification = {
              type: 'error',
              message: err.message
            }
            dispatch('addNotification', notification)
            reject()
          })
      } else {
        resolve()
      }
    })
  },

  // Logging In and Out of the App
  clearUserDataOnLogout({ commit }) {
    commit('INIT_ADMIN_ADVISOR_PROFILE')
    commit('INIT_ADMIN_ADVISORS')
    commit('INIT_ADMIN_DEFINITIONS')
    commit('INIT_ADVISOR_PROFILE')
    commit('INIT_ADVISOR_DEFINITIONS')
    commit('INIT_CLIENT_PROFILE')
    commit('RESET_CLIENT_LEDGER')
    commit('INIT_CLIENT_PROJECTION')
    commit('INIT_CLIENTS_STATE')
  },

  fetchUserDataOnLogin({ dispatch }) {
    dispatch('fetchAdminDefinitions').then(() =>
      dispatch('refreshAdvisorDefinitions')
    )
  }
}

export const getters = {
  fxRateToday(state) {
    return state.fx.rates
  }
}
