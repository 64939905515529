import store from '@/vuex/store'
import SystemService from '@/services/SystemService.js'

const defaultAdvisorProfile = () => {
  return {
    profile: {
      partKey: '', // tenant_id filled in by backend (lambda) on registration
      sortKey: '', // advisor_id filled in by backend (lambda) on registration
      accountState: '', // filled in by backend (lambda) on registration
      accountStateDate: '', // filled in by backend (lambda) on registration
      role: '', // filled in by backend (lambda) on registration
      cognitoUserName: '', // required by backend (lambda) in order to update cognito attributes
      name: '', // App account name required because of Cognito
      title: null,
      firstName: '', // input required because of Cognito
      familyName: '', // input required because of Cognito
      resNumber: '',
      resStreet: '',
      resSuburb: '',
      resCity: '',
      resPostcode: '',
      resCountry: '',
      mailNumber: '',
      mailStreet: '',
      mailSuburb: '',
      mailCity: '',
      mailPostcode: '',
      mailCountry: '',
      mobilePhone: '', // input required because of Cognito
      homePhone: '',
      workPhone: '',
      email: '' // input required because of Cognito
    }
  }
}

const defaultClientProfile = () => {
  return {
    profile: {
      partKey: '', // tenant_id filled in by backend (lambda) on registration
      sortKey: '', // client_id filled in by backend (lambda) on registration
      primaryAdvisor: '', // advisor_id filled in by backend (lambda) on registration
      secondaryAdvisor: 'None',
      accountState: '', // filled in by backend (lambda) on registration
      accountStateDate: '', // filled in by backend (lambda) on registration
      role: '', // filled in by backend (lambda) on registration
      cognitoUserName: '', // required by backend (lambda) in order to update cognito attributes
      name: '', // App account name required because of Cognito
      modified: '', // filled in by backend (lambda) on registration
      pref: 'primary', // App primary or secondary
      type: '', // App Individual, Joint, Trust, Company or Estate
      jointId: '',
      title: '',
      firstName: '', // input required because of Cognito
      familyName: '', // input required because of Cognito
      birthDate: '',
      gender: '',
      martialStatus: '',
      residencyStatus: '',
      prefContactMethod: '', // select from Mail, Phone, Email or Falcon9
      prefContactNumber: '', // select from Mobile, Home or Work Phone
      resNumber: '',
      resStreet: '',
      resSuburb: '',
      resCity: '',
      resPostcode: '',
      resCountry: '',
      mailNumber: '',
      mailStreet: '',
      mailSuburb: '',
      mailCity: '',
      mailPostcode: '',
      mailCountry: '',
      mobilePhone: '', // input required because of Cognito
      homePhone: '',
      workPhone: '',
      email: '' // input required because of Cognito
    },

    profileJoint: {
      partKey: '', // tenant_id filled in by backend (lambda) on registration
      sortKey: '', // client_id filled in by backend (lambda) on registration
      primaryAdvisor: '', // advisor_id filled in by backend (lambda) on registration
      secondaryAdvisor: 'None',
      accountState: '', // filled in by backend (lambda) on registration
      accountStateDate: '', // filled in by backend (lambda) on registration
      role: '', // filled in by backend (lambda) on registration
      cognitoUserName: '', // required by backend (lambda) in order to update cognito attributes
      name: '', // App account name required because of Cognito
      modified: '', // filled in by backend (lambda) on registration
      pref: '', // App primary or secondary
      type: '', // App Individual, Joint, Trust, Company or Estate
      jointId: '',
      title: '',
      firstName: '', // input required because of Cognito
      familyName: '', // input required because of Cognito
      birthDate: '',
      gender: '',
      martialStatus: '',
      residencyStatus: '',
      prefContactMethod: '', // select from Mail, Phone, Email or Falcon9
      prefContactNumber: '', // select from Mobile, Home or Work Phone
      resNumber: '',
      resStreet: '',
      resSuburb: '',
      resCity: '',
      resPostcode: '',
      resCountry: '',
      mailNumber: '',
      mailStreet: '',
      mailSuburb: '',
      mailCity: '',
      mailPostcode: '',
      mailCountry: '',
      mobilePhone: '', // input required because of Cognito
      homePhone: '',
      workPhone: '',
      email: '' // input required because of Cognito
    }
  }
}

const defaultAdminDefinitionsAsset = () => {
  return {
    id: SystemService.getRandomIdBetween(1001, 9999),
    rank: 0, // defines the rank in the category items list, you choose where the new item is inserted
    type: 'Neutral', // Tyeps are Income, Neutral and Growth (Neutral represents non working assets outside of general investments)
    class: '',
    category: '',
    growth: 0, // Advisor selected growth value
    default: 0 // Default or Admin selected growth value
  }
}

const defaultAdminDefinitionsLiability = () => {
  const id = SystemService.getRandomIdBetween(1001, 9999)
  return {
    id: id,
    class: '',
    interest: 0, // Advisor selected interest value
    default: 0 // Default or Admin selected interest value
  }
}

const defaultAdminDefinitionsIncomeSource = () => {
  const id = SystemService.getRandomIdBetween(1001, 9999)
  return {
    id: id,
    class: '',
    growth: 0,
    default: 0
  }
}

const defaultAdminDefinitionsSpendingStream = () => {
  const id = SystemService.getRandomIdBetween(1001, 9999)
  return {
    id: id,
    class: '',
    category: '',
    growth: 0,
    default: 0
  }
}

const defaultAdminDefinitionsRiskType = () => {
  const id = SystemService.getRandomIdBetween(1001, 9999)
  return {
    id: id,
    rank: 0,
    name: '',
    description: 'Modified', // defines which description (Default or Modified) is active
    sysDescription: '', // Contains the system riskType description (up to 400 characters)
    indDescription: '', // Contains the individual or admin riskType description (up to 400 characters)
    returnRate: 0
  }
}

const defaultAdminDefinitionsModelPortfolio = () => {
  const id = SystemService.getRandomIdBetween(1001, 9999)
  const data = []
  const x = store.getters.adminDefinitionsAssetInvestmentCategories
  console.log('X = ', x)
  Object.entries(
    store.getters.adminDefinitionsAssetInvestmentCategories
  ).forEach(([, val]) => {
    data.push({ x: val.name, y: 0, type: val.type })
  })
  return {
    id: id,
    modelPortfolio: '',
    riskType: '',
    data: data
  }
}

const defaultAdvisorDefinitionsAsset = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  return {
    id: id,
    rank: 0,
    type: 'Neutral',
    class: '',
    category: '',
    growth: 0,
    default: 0
  }
}

const defaultAdvisorDefinitionsLiability = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  return {
    id: id,
    class: '',
    interest: 0,
    default: 0
  }
}

const defaultAdvisorDefinitionsIncomeSource = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  return {
    id: id,
    class: '',
    growth: 0,
    default: 0
  }
}

const defaultAdvisorDefinitionsSpendingStream = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  return {
    id: id,
    class: '',
    category: '',
    growth: 0,
    default: 0
  }
}

const defaultAdvisorDefinitionsModelPortfolio = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  const data = []
  Object.entries(
    store.getters.advisorDefinitionsAssetInvestmentCategories
  ).forEach(([, val]) => {
    data.push({ x: val.name, y: 0, type: val.type })
  })
  return {
    id: id,
    modelPortfolio: '',
    riskType: '',
    data: data
  }
}

const defaultDefinitions = () => {
  return {
    definitions: {
      assets: [
        {
          class: 'Property',
          category: 'Residential',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Growth',
          id: 1
        },
        {
          class: 'Property',
          category: 'Commercial',
          growth: 0,
          default: 0,
          rank: 2,
          type: 'Growth',
          id: 2
        },
        {
          class: 'Property',
          category: 'Land',
          growth: 0,
          default: 0,
          rank: 3,
          type: 'Growth',
          id: 3
        },
        {
          class: 'Property',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 4,
          type: 'Growth',
          id: 4
        },

        {
          class: 'Business',
          category: 'Building',
          growth: 0,
          default: 0,
          rank: 5,
          type: 'Growth',
          id: 5
        },
        {
          class: 'Business',
          category: 'Land',
          growth: 0,
          default: 0,
          rank: 6,
          type: 'Growth',
          id: 6
        },
        {
          class: 'Business',
          category: 'Equity',
          growth: 0,
          default: 0,
          rank: 7,
          type: 'Growth',
          id: 7
        },
        {
          class: 'Business',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 8,
          type: 'Growth',
          id: 8
        },

        {
          class: 'Cash',
          category: 'Cash & Cash Equivalents',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Income',
          id: 9
        },

        {
          class: 'Investment',
          category: 'Cash',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Income',
          id: 10
        },
        {
          class: 'Investment',
          category: 'New Zealand Fixed Interest',
          growth: 0,
          default: 0,
          rank: 2,
          type: 'Income',
          id: 11
        },
        {
          class: 'Investment',
          category: 'International Fixed Interest',
          growth: 0,
          default: 0,
          rank: 3,
          type: 'Income',
          id: 12
        },
        {
          class: 'Investment',
          category: 'Australasian Listed Property',
          growth: 0,
          default: 0,
          rank: 4,
          type: 'Growth',
          id: 13
        },
        {
          class: 'Investment',
          category: 'International Listed Property',
          growth: 0,
          default: 0,
          rank: 5,
          type: 'Growth',
          id: 14
        },
        {
          class: 'Investment',
          category: 'International Infrastructure',
          growth: 0,
          default: 0,
          rank: 6,
          type: 'Growth',
          id: 15
        },
        {
          class: 'Investment',
          category: 'Australasian Equities',
          growth: 0,
          default: 0,
          rank: 7,
          type: 'Growth',
          id: 16
        },
        {
          class: 'Investment',
          category: 'International Equities',
          growth: 0,
          default: 0,
          rank: 8,
          type: 'Growth',
          id: 17
        },
        {
          class: 'Investment',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 9,
          type: 'Growth',
          id: 18
        },

        {
          class: 'Precious Metal',
          category: 'Gold',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Growth',
          id: 19
        },
        {
          class: 'Precious Metal',
          category: 'Silver',
          growth: 0,
          default: 0,
          rank: 2,
          type: 'Growth',
          id: 20
        },
        {
          class: 'Precious Metal',
          category: 'Platinum',
          growth: 0,
          default: 0,
          rank: 3,
          type: 'Growth',
          id: 21
        },
        {
          class: 'Precious Metal',
          category: 'Palladium',
          growth: 0,
          default: 0,
          rank: 4,
          type: 'Growth',
          id: 22
        },
        {
          class: 'Precious Metal',
          category: 'Rhodium',
          growth: 0,
          default: 0,
          rank: 5,
          type: 'Growth',
          id: 23
        },
        {
          class: 'Precious Metal',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 6,
          type: 'Growth',
          id: 24
        },

        {
          class: 'Crypto Currency',
          category: 'Bitcoin',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Growth',
          id: 25
        },
        {
          class: 'Crypto Currency',
          category: 'Bitcoin Cash',
          growth: 0,
          default: 0,
          rank: 2,
          type: 'Growth',
          id: 26
        },
        {
          class: 'Crypto Currency',
          category: 'Ethereum',
          growth: 0,
          default: 0,
          rank: 3,
          type: 'Growth',
          id: 27
        },
        {
          class: 'Crypto Currency',
          category: 'LiteCoin',
          growth: 0,
          default: 0,
          rank: 4,
          type: 'Growth',
          id: 28
        },
        {
          class: 'Crypto Currency',
          category: 'Ripple',
          growth: 0,
          default: 0,
          rank: 5,
          type: 'Growth',
          id: 29
        },
        {
          class: 'Crypto Currency',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 6,
          type: 'Growth',
          id: 30
        },

        {
          class: 'Insurance',
          category: 'Life Insurance Death Benefit',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Growth',
          id: 31
        },
        {
          class: 'Insurance',
          category: 'Life Insurance Cash Value',
          growth: 0,
          default: 0,
          rank: 2,
          type: 'Growth',
          id: 32
        },
        {
          class: 'Insurance',
          category: 'Disability Insurance',
          growth: 0,
          default: 0,
          rank: 3,
          type: 'Growth',
          id: 33
        },
        {
          class: 'Insurance',
          category: 'Trauma Cover',
          growth: 0,
          default: 0,
          rank: 4,
          type: 'Growth',
          id: 34
        },
        {
          class: 'Insurance',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 5,
          type: 'Growth',
          id: 35
        },

        {
          class: 'Pension Scheme',
          category: 'KiwiSaver',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Growth',
          id: 36
        },
        {
          class: 'Pension Scheme',
          category: 'Capital Life Insurance',
          growth: 0,
          default: 0,
          rank: 2,
          type: 'Growth',
          id: 37
        },
        {
          class: 'Pension Scheme',
          category: 'Private Pension',
          growth: 0,
          default: 0,
          rank: 3,
          type: 'Income',
          id: 38
        },
        {
          class: 'Pension Scheme',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 4,
          type: 'Income',
          id: 39
        },

        {
          class: 'Non Working Asset',
          category: 'Property',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Neutral',
          id: 41
        },
        {
          class: 'Non Working Asset',
          category: 'Vehicle',
          growth: 0,
          default: 0,
          rank: 2,
          type: 'Neutral',
          id: 42
        },
        {
          class: 'Non Working Asset',
          category: 'Valuables',
          growth: 0,
          default: 0,
          rank: 3,
          type: 'Neutral',
          id: 43
        },
        {
          class: 'Non Working Asset',
          category: 'Personal Property',
          growth: 0,
          default: 0,
          rank: 4,
          type: 'Neutral',
          id: 44
        },
        {
          class: 'Non Working Asset',
          category: 'Furniture',
          growth: 0,
          default: 0,
          rank: 5,
          type: 'Neutral',
          id: 45
        },
        {
          class: 'Non Working Asset',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 6,
          type: 'Neutral',
          id: 46
        },

        {
          class: 'Other',
          category: 'Other',
          growth: 0,
          default: 0,
          rank: 1,
          type: 'Neutral',
          id: 47
        }
      ],

      liabilities: [
        { class: 'Home Mortgage', interest: 0, default: 0, id: 48 },
        { class: 'Property Mortgage', interest: 0, default: 0, id: 49 },
        { class: 'Loan', interest: 0, default: 0, id: 50 },
        { class: 'CreditCard', interest: 0, default: 0, id: 51 },
        { class: 'Tax', interest: 0, default: 0, id: 52 },
        { class: 'Hire Purchase', interest: 0, default: 0, id: 53 },
        { class: 'Other', interest: 0, default: 0, id: 54 }
      ],

      incomeSources: [
        { class: 'Your take-home pay', growth: 0, default: 0, id: 56 },
        { class: 'Bonus/Overtime', growth: 0, default: 0, id: 57 },
        { class: 'Super Annuation', growth: 0, default: 0, id: 58 },
        { class: 'Investment', growth: 0, default: 0, id: 59 },
        { class: 'Property', growth: 0, default: 0, id: 60 },
        { class: 'Own business', growth: 0, default: 0, id: 61 },
        { class: 'Child support', growth: 0, default: 0, id: 62 },
        { class: 'Family benefit', growth: 0, default: 0, id: 63 },
        { class: 'WINZ benefits', growth: 0, default: 0, id: 64 },
        { class: 'Other', growth: 0, default: 0, id: 65 }
      ],

      spendingStreams: [
        {
          class: 'Financial Commitments',
          category: 'Other',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 67
        },
        {
          class: 'Financial Commitments',
          category: 'Investment',
          growth: 0,
          default: 0,
          sysDescription: 'Investment fortnightly/monthly contribution',
          id: 68
        },
        {
          class: 'Financial Commitments',
          category: 'Rent',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 69
        },
        {
          class: 'Financial Commitments',
          category: 'Mortgage',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 70
        },
        {
          class: 'Financial Commitments',
          category: 'Loan repayments',
          growth: 0,
          default: 0,
          sysDescription:
            'Interest free credit, investment loans, HECS repayments, appliance rentals',
          id: 71
        },
        {
          class: 'Financial Commitments',
          category: 'CreditCard interest',
          growth: 0,
          default: 0,
          sysDescription:
            'Include monthly interest and regular repayments to bring down your debt over time. Do not include payments, which simply pay off your creditCard debt each month',
          id: 72
        },
        {
          class: 'Financial Commitments',
          category: 'Superannuation contribution',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 73
        },
        {
          class: 'Financial Commitments',
          category: 'KiwiSaver contribution',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 74
        },
        {
          class: 'Financial Commitments',
          category: 'Savings',
          growth: 0,
          default: 0,
          sysDescription:
            'The amount you put aside each fortnight/month etc, not the balance of your savings account',
          id: 75
        },
        {
          class: 'Financial Commitments',
          category: 'Child support payments',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 76
        },
        {
          class: 'Financial Commitments',
          category: 'Donations/Charity',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 77
        },
        {
          class: 'Financial Commitments',
          category: 'Pocket money',
          growth: 0,
          default: 0,
          sysDescription: 'Money you give to your kids',
          id: 78
        },
        {
          class: 'Financial Commitments',
          category: 'Type in your own expense',
          growth: 0,
          default: 0,
          sysDescription:
            'fines, bank fees, tax debt repayments, regular payments to family',
          id: 79
        },

        {
          class: 'Home',
          category: 'Council rates',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 80
        },
        {
          class: 'Home',
          category: 'Body corporate fees',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 81
        },
        {
          class: 'Home',
          category: 'Home and contents insurance',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 82
        },
        {
          class: 'Home',
          category: 'Home maintenance and repairs',
          growth: 0,
          default: 0,
          sysDescription:
            'Painting, electrician/plumber, garden improvements, spending at the hardware shop or garden center',
          id: 83
        },
        {
          class: 'Home',
          category: 'New furniture / appliances',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 84
        },
        {
          class: 'Home',
          category: 'Other',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 85
        },

        {
          class: 'Utilities',
          category: 'Electricity',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 90
        },
        {
          class: 'Utilities',
          category: 'Gas',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 91
        },
        {
          class: 'Utilities',
          category: 'Water',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 92
        },
        {
          class: 'Utilities',
          category: 'Internet',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 93
        },
        {
          class: 'Utilities',
          category: 'Pay TV',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 94
        },
        {
          class: 'Utilities',
          category: 'Home phone',
          growth: 0,
          default: 0,
          sysDescription: 'Monthly rental plus call costs',
          id: 95
        },
        {
          class: 'Utilities',
          category: 'Mobile phone(s)',
          growth: 0,
          default: 0,
          sysDescription:
            'Spending on handset plus monthly fees and extra call costs. Include all the family phones that you pay for',
          id: 96
        },
        {
          class: 'Utilities',
          category: 'Own expense',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 97
        },
        {
          class: 'Utilities',
          category: 'Other',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 98
        },

        {
          class: 'Education',
          category: 'School fees',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 100
        },
        {
          class: 'Education',
          category: 'University',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 101
        },
        {
          class: 'Education',
          category: 'Childcare / Pre-school',
          growth: 0,
          default: 0,
          sysDescription:
            'The amount you pay after subtracting any government benefit received',
          id: 102
        },
        {
          class: 'Education',
          category: 'School uniforms',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 103
        },
        {
          class: 'Education',
          category: 'Sport, music, dance, etc',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 104
        },
        {
          class: 'Education',
          category: 'Excursions',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 105
        },
        {
          class: 'Education',
          category: 'Other',
          growth: 0,
          default: 0,
          sysDescription:
            'Kids activities - dance, music, language, drama, books and stationary',
          id: 106
        },

        {
          class: 'Health',
          category: 'Private health insurance',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 110
        },
        {
          class: 'Health',
          category: 'Life insurance',
          growth: 0,
          default: 0,
          sysDescription:
            'Include income protection, trauma - and disability - insurance',
          id: 111
        },
        {
          class: 'Health',
          category: 'Doctors',
          growth: 0,
          default: 0,
          sysDescription: 'GP, specialists, chiropractor, home nursing',
          id: 112
        },
        {
          class: 'Health',
          category: 'Dentists',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 113
        },
        {
          class: 'Health',
          category: 'Medicines / Pharmacy',
          growth: 0,
          default: 0,
          sysDescription: 'include overseas vaccinations',
          id: 114
        },
        {
          class: 'Health',
          category: 'Eyecare / Glasses',
          growth: 0,
          default: 0,
          sysDescription: 'Eye tests, contact lens, sunglasses etc',
          id: 115
        },
        {
          class: 'Health',
          category: 'Vet',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 116
        },
        {
          class: 'Health',
          category: 'Own expense',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 117
        },
        {
          class: 'Health',
          category: 'Other',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 118
        },

        {
          class: 'Shopping',
          category: 'Supermarket',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 120
        },
        {
          class: 'Shopping',
          category: 'Fruit / Veg',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 121
        },
        {
          class: 'Shopping',
          category: 'Baby products',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 122
        },
        {
          class: 'Shopping',
          category: 'Clothing / shoes',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 123
        },
        {
          class: 'Shopping',
          category: 'Cosmetics / toiletries',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 124
        },
        {
          class: 'Shopping',
          category: 'Hairdresser',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 125
        },
        {
          class: 'Shopping',
          category: 'Gifts and others',
          growth: 0,
          default: 0,
          sysDescription:
            'Birthdays, christmas, weggings, anniversaries, and "everything else" category like tradeMe, e-bay, amazon, etc',
          id: 126
        },
        {
          class: 'Shopping',
          category: 'Other food and grocery',
          growth: 0,
          default: 0,
          sysDescription: 'Bread, milk, butcher, etc',
          id: 127
        },
        {
          class: 'Shopping',
          category: 'Other',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 128
        },

        {
          class: 'Transport',
          category: 'Car insurance',
          growth: 0,
          default: 0,
          sysDescription: 'Including compulsory 3rd party',
          id: 130
        },
        {
          class: 'Transport',
          category: 'Car maintenance',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 131
        },
        {
          class: 'Transport',
          category: 'Car rego / licence',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 132
        },
        {
          class: 'Transport',
          category: 'Petrol',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 133
        },
        {
          class: 'Transport',
          category: 'Road tolls / parking',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 134
        },
        {
          class: 'Transport',
          category: 'Trains / buses / ferries',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 135
        },
        {
          class: 'Transport',
          category: 'Own expense',
          growth: 0,
          default: 0,
          sysDescription: 'Boat, yacht, caravan, motorbike, bicycle',
          id: 136
        },

        {
          class: 'Entertainment',
          category: 'Holidays',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 140
        },
        {
          class: 'Entertainment',
          category: 'Bars / Clubs',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 141
        },
        {
          class: 'Entertainment',
          category: 'Other alcohol',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 142
        },
        {
          class: 'Entertainment',
          category: 'Gym, Sporting membership',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 143
        },
        {
          class: 'Entertainment',
          category: 'Cigarettes',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 144
        },
        {
          class: 'Entertainment',
          category: 'Movies, music',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 145
        },
        {
          class: 'Entertainment',
          category: 'Hobbies',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 146
        },
        {
          class: 'Entertainment',
          category: 'Newspaper, magazines',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 147
        },
        {
          class: 'Entertainment',
          category: 'Celebrations',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 148
        },
        {
          class: 'Entertainment',
          category: 'Other',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 149
        },

        {
          class: 'Eating out',
          category: 'Restaurants',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 150
        },
        {
          class: 'Eating out',
          category: 'Takeaway / Snacks',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 151
        },
        {
          class: 'Eating out',
          category: 'Bought lunches',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 152
        },
        {
          class: 'Eating out',
          category: 'Coffee / Tea',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 153
        },
        {
          class: 'Eating out',
          category: 'Own expense',
          growth: 0,
          default: 0,
          sysDescription: '',
          id: 154
        }
      ],

      riskTypes: [
        {
          id: 1,
          rank: 1,
          name: 'Conservative',
          description: 'Default', // Default or Modified
          sysDescription:
            'This suits investors with a minimum two-year timeframe or those that seek a portfolio comprising mainly of interest bearing assets. This portfolio suits investors who give a high priority to the preservation of capital and are therefore willing to accept lower potential investment performance, hence the 85% percent exposure to income assets.',
          indDescription: '',
          returnRate: 4.2
        },
        {
          id: 2,
          rank: 2,
          name: 'Moderate',
          description: 'Default', // Default or Modified
          sysDescription:
            'This suits investors with a minimum three-year timeframe or those who primarily seek income with some potential for capital growth. This portfolio also suits investors seeking a low level of investment value volatility, and therefore willing to accept lower potential investment performance, hence the 70 percent exposure to income assets.',
          indDescription: '',
          returnRate: 4.7
        },
        {
          id: 3,
          rank: 3,
          name: 'Balanced',
          description: 'Default', // Default or Modified
          sysDescription:
            'This suits investors with a minimum five-year timeframe or those who seek both income and capital growth. This portfolio also suits investors who desire a modest level of capital stability but are willing to accept moderate investment volatility in return for commensurate potential investment performance, hence the 50% exposure to growth assets and 50% to income assets.',
          indDescription: '',
          returnRate: 5.4
        },
        {
          id: 4,
          rank: 4,
          name: 'Growth',
          description: 'Default', // Default or Modified
          sysDescription:
            'This suits investors with a minimum seven-year timeframe or those who are willing to accept higher levels of investment value volatility. Some capital stability is still desired, but the primary concern is a higher return, hence the 70% exposure to growth assets.',
          indDescription: '',
          returnRate: 6.1
        },
        {
          id: 5,
          rank: 5,
          name: 'Aggressive',
          description: 'Default', // Default or Modified
          sysDescription:
            'This suits investors with a minimum nine-year timeframe or those who are willing to accept high levels of investment value volatility in return for high potential investment performance. The 85 percent exposure to growth assets (shares and listed property) means that capital stability is only a minor concern.',
          indDescription: '',
          returnRate: 6.8
        },
        {
          id: 6,
          rank: 6,
          name: 'Very Aggressive',
          description: 'Default', // Default or Modified
          sysDescription:
            'This suits investors with a minimum ten-year timeframe or those who are willing to accept very high levels of investment value volatility to maximize potential investment performance. The 100 percent exposure to growth assets (shares and listed property) means that capital stability is not a consideration.',
          indDescription: '',
          returnRate: 7.5
        }
      ],

      modelPortfolios: [
        {
          id: 1,
          modelPortfolio: 'Default',
          riskType: 'Conservative',
          data: [
            { x: 'Cash', y: 36, type: 'Income' },
            { x: 'New Zealand Fixed Interest', y: 28, type: 'Income' },
            { x: 'International Fixed Interest', y: 21, type: 'Income' },
            { x: 'Australasian Listed Property', y: 0, type: 'Growth' },
            { x: 'International Listed Property', y: 0, type: 'Growth' },
            { x: 'International Infrastructure', y: 3, type: 'Growth' },
            { x: 'Australasian Equity', y: 4, type: 'Growth' },
            { x: 'International Equity', y: 8, type: 'Growth' },
            { x: 'Other', y: 0, type: 'Growth' }
          ]
        },
        {
          id: 2,
          modelPortfolio: 'Default',
          riskType: 'Moderate',
          data: [
            { x: 'Cash', y: 30, type: 'Income' },
            { x: 'New Zealand Fixed Interest', y: 23, type: 'Income' },
            { x: 'International Fixed Interest', y: 17, type: 'Income' },
            { x: 'Australasian Listed Property', y: 0, type: 'Growth' },
            { x: 'International Listed Property', y: 3, type: 'Growth' },
            { x: 'International Infrastructure', y: 3, type: 'Growth' },
            { x: 'Australasian Equity', y: 8, type: 'Growth' },
            { x: 'International Equity', y: 16, type: 'Growth' },
            { x: 'Other', y: 0, type: 'Growth' }
          ]
        },
        {
          id: 3,
          modelPortfolio: 'Default',
          riskType: 'Balanced',
          data: [
            { x: 'Cash', y: 20, type: 'Income' },
            { x: 'New Zealand Fixed Interest', y: 18, type: 'Income' },
            { x: 'International Fixed Interest', y: 12, type: 'Income' },
            { x: 'Australasian Listed Property', y: 3, type: 'Growth' },
            { x: 'International Listed Property', y: 3, type: 'Growth' },
            { x: 'International Infrastructure', y: 3, type: 'Growth' },
            { x: 'Australasian Equity', y: 13, type: 'Growth' },
            { x: 'International Equity', y: 28, type: 'Growth' },
            { x: 'Other', y: 0, type: 'Growth' }
          ]
        },
        {
          id: 4,
          modelPortfolio: 'Default',
          riskType: 'Growth',
          data: [
            { x: 'Cash', y: 12, type: 'Income' },
            { x: 'New Zealand Fixed Interest', y: 11, type: 'Income' },
            { x: 'International Fixed Interest', y: 7, type: 'Income' },
            { x: 'Australasian Listed Property', y: 6, type: 'Growth' },
            { x: 'International Listed Property', y: 3, type: 'Growth' },
            { x: 'International Infrastructure', y: 4, type: 'Growth' },
            { x: 'Australasian Equity', y: 18, type: 'Growth' },
            { x: 'International Equity', y: 39, type: 'Growth' },
            { x: 'Other', y: 0, type: 'Growth' }
          ]
        },
        {
          id: 5,
          modelPortfolio: 'Default',
          riskType: 'Aggressive',
          data: [
            { x: 'Cash', y: 3, type: 'Income' },
            { x: 'New Zealand Fixed Interest', y: 4, type: 'Income' },
            { x: 'International Fixed Interest', y: 3, type: 'Income' },
            { x: 'Australasian Listed Property', y: 6, type: 'Growth' },
            { x: 'International Listed Property', y: 5, type: 'Growth' },
            { x: 'International Infrastructure', y: 4, type: 'Growth' },
            { x: 'Australasian Equity', y: 27, type: 'Growth' },
            { x: 'International Equity', y: 48, type: 'Growth' },
            { x: 'Other', y: 0, type: 'Growth' }
          ]
        },
        {
          id: 6,
          modelPortfolio: 'Default',
          riskType: 'Very Aggressive',
          data: [
            { x: 'Cash', y: 0, type: 'Income' },
            { x: 'New Zealand Fixed Interest', y: 0, type: 'Income' },
            { x: 'International Fixed Interest', y: 0, type: 'Income' },
            { x: 'Australasian Listed Property', y: 0, type: 'Growth' },
            { x: 'International Listed Property', y: 0, type: 'Growth' },
            { x: 'International Infrastructure', y: 0, type: 'Growth' },
            { x: 'Australasian Equity', y: 0, type: 'Growth' },
            { x: 'International Equity', y: 0, type: 'Growth' },
            { x: 'Other', y: 0, type: 'Growth' }
          ]
        }
      ]
    }
  }
}

const defaultProjection = () => {
  return {
    newItemId: 0, // new item id, next one after selected item id (change is used as trigger for confirm changes window)
    selectedItemId: 0, // current item id (actually selected one)
    financialPlan: [],
    parameters: {
      headlinePerson1: '', // Headline for Goal Timeline
      headlinePerson2: '', // Headline for Goal Timeline
      yearPerson1: 0, // retirement year person1
      yearPerson2: 0, // retirement year person2
      description: '', // Goal description financial plan
      descriptionEnhancedPerson1: '', // goal description timeline person1
      descriptionEnhancedPerson2: '', // Timeline description timeline person2
      retirementAgePerson1: 65,
      retirementAgePerson2: 65,
      lifeExpectancyPerson1: 99,
      lifeExpectancyPerson2: 99,
      postRetirementRiskType: 'current', // current equals configured RiskType, otherwise choose ind. new one
      postRetirementRiskTypeFromYear: 2000,
      retirementExpenseValue: 10000, // absolut Value (in Fiat Currency and inflation adjusted)
      retirementExpenseRate: 100, // Rate of current expenses (inflation adjusted)
      retirementExpenseType: 'rate', // choose between "rate" (in %) or "fixed" (in Fiat Currency)
      comment: '' // Up to 250 characters text
    }
  }
}

const systemGoalsPersonal = () => {
  let goals = [
    {
      id: 1, // personal goal-item id's range from 1 to 49
      rank: 0, // rank or place, in which item is displayed in financial plan
      type: 'Emergency-Fund', // goal title (max. 30 characters)
      icon: 'mdi-piggy-bank',
      name: '',
      headline: 'Allocate Emergency-Fund', // Headline for Goal Timeline and Financial Plan
      currentData: {
        year: SystemService.yearToday(), // year is always individual year of goal event, in case of a recurring goal (identical to target year when non recurring goal)
        description:
          "According to Sorted (Commission for Financial Capability), a general rule suggests that you put three months' worth of expenses into an emergency fund. This allows you to stay ahead and acts as a self-insurance.",
        descriptionEnhanced:
          "According to Sorted (Commission for Financial Capability), a general rule suggests that you put three months' worth of expenses into an emergency fund. This allows you to stay ahead and acts as a self-insurance.",
        value: 0
      },
      proposedData: {
        year: SystemService.yearToday(),
        description: '', // Goal description financial plan
        descriptionEnhanced: '', // enghanced Goal description for timeline
        value: 0
      },
      comment: '' // Up to 250 characters text
    },
    {
      id: 2, // personal goal-item id's range from 1 to 49
      rank: 0, // rank or place, in which item is displayed in financial plan
      type: 'Retirement', // goal title (max. 30 characters)
      icon: 'mdi-beach',
      name: '',
      headline: 'Retirement', // Headline for Financial Plan
      currentData: {
        headlinePerson1: '', // Headline for Goal Timeline
        headlinePerson2: '', // Headline for Goal Timeline
        yearPerson1: 0, // retirement year person1
        yearPerson2: 0, // retirement year person2
        description: '', // Goal description financial plan
        descriptionEnhancedPerson1: '', // goal description timeline person1
        descriptionEnhancedPerson2: '', // Timeline description timeline person2
        retirementAgePerson1: 65,
        retirementAgePerson2: 65,
        lifeExpectancyPerson1: 99,
        lifeExpectancyPerson2: 99,
        postRetirementRiskType: 'current', // current equals configured RiskType, otherwise choose ind. new one
        postRetirementRiskTypeFromYear: 2000,
        retirementExpenseValue: 10000, // absolut Value (in Fiat Currency and inflation adjusted)
        retirementExpenseRate: 100, // Rate of current expenses (inflation adjusted)
        retirementExpenseType: 'rate', // choose between "rate" (in %) or "fixed" (in Fiat Currency)
        comment: '', // Up to 250 characters text
        fundingSource: {
          extraSavings: { value: 0 }, // value per month
          assetClass1: { name: '', rate: 0 }, // rate in % of total funds needed
          assetClass2: { name: '', rate: 0 }, // rate in % of total funds needed
          assetClass3: { name: '', rate: 0 } // rate in % of total funds needed
        }
      },
      proposedData: {}
    }
  ]
  return goals
}

const systemGoalsInstitutional = () => {
  let goals = [
    {
      id: 1, // personal goal-item id's range from 1 to 49
      rank: 0, // rank or place, in which item is displayed in financial plan
      type: 'Emergency-Fund', // goal title (max. 30 characters)
      icon: 'mdi-piggy-bank',
      name: '',
      headline: 'Allocate Emergency-Fund', // Headline for Goal Timeline and Financial Plan
      currentData: {
        year: SystemService.yearToday(), // year is always individual year of goal event, in case of a recurring goal (identical to target year when non recurring goal)
        description:
          "According to Sorted (Commission for Financial Capability), a general rule suggests that you put three months' worth of expenses into an emergency fund. This allows you to stay ahead and acts as a self-insurance.",
        descriptionEnhanced:
          "According to Sorted (Commission for Financial Capability), a general rule suggests that you put three months' worth of expenses into an emergency fund. This allows you to stay ahead and acts as a self-insurance.", // enghanced Goal description for timeline
        value: 0
      },
      proposedData: {
        year: SystemService.yearToday(), // year is always individual year of goal event, in case of a recurring goal (identical to target year when non recurring goal)
        description: '', // Goal description financial plan
        descriptionEnhanced: '', // enhanced Goal description for timeline
        value: 0
      },
      comment: '' // Up to 250 characters text
    }
  ]
  return goals
}

const systemLedger = () => {
  return {
    ledger: {
      // static Database Data
      assets: [],
      liabilities: [],
      incomeSources: [],
      spendingStreams: [],
      goals: [],
      advice: [
        {
          id: 50, // personal advice-item id's range from 50 to 99
          rank: 0, // rank or place, in which item is displayed in financial plan
          type: 'Total extra savings', // advice title (max. 30 characters)
          icon: 'mdi-piggy-bank',
          name: '',
          headline: 'Total extra savings', // advice Headline for Financial Plan
          targetYear: SystemService.yearToday(), // if recurring goal, targetYear the first of many years the goal is set for
          proposedData: {
            description: 'save additional 500 NZD/month', // Advice description financial plan
            idRelated: '', // choose existing Asset from PulldDown Menu (Sell/Downsize: Asset to sell/downsize, Buy/Upsize: Asset to draw funds for purchase)
            value: 500,
            year: 0, // e.g. Buy new car for 50.000 NZD (value) in 2023 (year)
            interval: 0,
            period: 0, // e.g., start in 2022 (year) every 1 (interval) year(s) for 20 years (period)
            comment: '' // Up to 250 characters text
          }
        }
      ],
      conditions: {
        accountCurrency: 'NZD', // choose from List of Fiat curremcies
        inflationRate: 2, // Inflation rate in %
        taxRate: '',
        riskType: '',
        riskToleranceQuestion1: '1',
        riskToleranceQuestion2: '0',
        riskToleranceQuestion3: '0',
        riskToleranceQuestion4: '0',
        riskToleranceQuestion5: '0',
        riskToleranceQuestion6: '0',
        riskToleranceQuestion7: '1',
        modelPortfolio: {
          modelPortfolio: 'Default'
        },
        ledgerBase: {
          unSelectedItems: []
        }
      },

      // dynamic State Data
      assetsName: [],
      assetsSum: 0,
      liabilitiesSum: 0,
      incomeSourcesSum: 0,
      spendingStreamsSum: 0
    }
  }
}

const defaultAsset = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  return {
    // attributes stored in DDB database
    id: id,
    idRelated: id,
    ledger: 'Asset',
    rank: 0,
    type: 'Neutral',
    class: 'Property',
    name: '',
    owner: store.getters.clientName,
    catText: '',
    catCode: '',
    amount: '',
    unit: '',
    currencyLocal: 'NZD',
    costValueLocal: '',
    costDate: '',
    pensionOpeningValueLocal: '',
    pensionOpeningDate: '',
    pensionRegularContributionLocal: '',
    pensionRegularPeriod: '',
    pensionExtraContribution: [], // {date: '', amount: ''}
    pensionPayment: '',
    pensionPaymentPeriod: '',
    priceValueLocal: '',
    priceDate: SystemService.dateToday(),
    maturityDate: '',
    maturityOption: 'lumpsum',
    growthRate: null,
    history: {},
    projection: {},
    projectionInclude: true,

    // attributes calculated in App for display only (via LedgerService.computeAsset)
    priceTodayLocal: '',
    priceTodayApp: '',
    priceTodayAppPercent: '',
    gainTodayLocal: '',
    gainTodayLocalPercent: ''
  }
}

const defaultLiability = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  return {
    // attributes stored in DDB database
    id: id,
    idRelated: -1,
    ledger: 'Liability',
    class: 'Home Mortgage',
    name: '',
    owner: store.getters.clientName,
    currencyLocal: 'NZD',
    closingValueLocal: '',
    closingDate: '',
    maturityDate: '',
    interestRate: '',
    repaymentOption: 'none',
    annuityValueLocal: '',
    principalValueLocal: '',
    period: '',
    extraPrincipal: [], // {date: '', amount: ''}
    history: {},
    projection: {},
    projectionInclude: true,

    // attributes calculated in App for display only (via LedgerService.computeLiability)
    balanceDueTodayLocal: '',
    balanceDueTodayApp: '',
    balanceDueTodayAppPercent: '',
    repayedTodayLocal: '',
    repayedTodayLocalPercent: ''
  }
}

const defaultIncomeSource = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  return {
    // attributes stored in DDB database
    id: id,
    idRelated: -1,
    ledger: 'Income',
    class: 'Your take-home pay',
    name: '',
    owner: store.getters.clientName,
    currencyLocal: 'NZD',
    incomeLocal: '',
    incomePaLocal: '',
    incomeExpiryType: 'retirement', // Choose between "retirement" oder specific "date"
    incomeExpiryDate: '', // define exact date
    payoutPeriod: 1,
    payoutDate: '',
    history: {},
    projection: {},
    projectionInclude: true,

    // attributes calculated in App for display only (via LedgerService.computeIncomeSource)
    incomePaTodayApp: '',
    incomePaTodayAppPercent: ''
  }
}

const defaultSpendingStream = () => {
  const id = SystemService.getRandomIdBetween(10000, 99999)
  return {
    // attributes stored in DDB database
    id: id,
    idRelated: -1,
    ledger: 'Expense',
    idLiabilityRelated: -1,
    class: 'Financial Commitments',
    catText: '',
    name: '',
    owner: store.getters.clientName,
    currencyLocal: 'NZD',
    spendingLocal: '',
    spendingPaLocal: '',
    spendingPeriod: 1,
    history: {},
    projection: {},
    projectionInclude: true,

    // attributes calculated in App for display only (via LedgerService.computeSpendingStream)
    spendingPaTodayApp: '',
    spendingPaTodayAppPercent: ''
  }
}

const defaultGoal = () => {
  const id = SystemService.getRandomIdBetween(100, 499)
  return {
    id: id, // personal goal-item id's range from 100 to 499
    rank: 0, // rank or place, in which item is displayed in financial plan
    type: 'Purchase', // choose between options: Purchase, Upsize, Invest
    icon: 'mdi-cash', // Sell/Downsize: mdi-cash-plus    Buy/Upsize: mdi-cash-minus
    name: '', // e.g. car, boat, house, etc. (max. 20 characters)
    headline: '', // Headline for Goal Timeline and Financial Plan
    targetYear: SystemService.yearToday(), // if recurring goal, targetYear the first of many years the goal is set for
    currentData: {
      description: '', // Goal description financial plan
      descriptionEnhanced: '', // enhanced Goal description for timeline
      idRelated: '', // choose existing Asset from PulldDown Menu (Sell/Downsize: Asset to sell/downsize, Buy/Upsize: Asset to draw funds for purchase)
      value: 0, // Sell: sale price (App will show predicted value in the chosen year, value can be modified if desired)
      // Buy: purchase price
      // Upsize: balance amount spend
      // Downsize: balance amount received
      year: SystemService.yearToday(), // year is always individual year of goal event, in case of a recurring goal (identical to target year when non recurring goal)
      recurring: false,
      interval: 0,
      period: 0, // e.g., start in 2022 (year) every 1 (interval) year(s) for 20 years (period)
      comment: '' // Up to 250 characters text
    },
    proposedData: {
      description: '', // Goal description financial plan
      descriptionEnhanced: '', // enhanced Goal description for timeline
      idRelated: '', // choose existing Asset from PulldDown Menu (Sell/Downsize: Asset to sell/downsize, Buy/Upsize: Asset to draw funds for purchase)
      value: 0, // Sell: sale price (App will show predicted value in the chosen year, value can be modified if desired)
      // Buy: purchase price
      // Upsize: balance amount spend
      // Downsize: balance amount received
      year: 0, // year is always individual year of goal event, in case of a recurring goal (identical to target year when non recurring goal)
      recurring: false,
      interval: 0,
      period: 0, // e.g., start in 2022 (year) every 1 (interval) year(s) for 20 years (period)
      comment: '' // Up to 250 characters text
    }
  }
}

const defaultAdvice = () => {
  const id = SystemService.getRandomIdBetween(500, 999)
  return {
    id: id, // personal advice-item id's range from 100 to 499
    rank: 0, // rank or place, in which item is displayed in financial plan
    type: 'Sell', // choose between options: Sell, Buy, Upsize, Downsize, Invest
    icon: 'mdi-cash-plus',
    name: '', // e.g. car, boat, house, etc. (max. 20 characters)
    headline: '', // Headline for Financial Plan
    targetYear: SystemService.yearToday(), // if recurring goal, targetYear the first of many years the goal is set for
    proposedData: {
      description: '', // Advice description financial plan
      idRelated: '', // choose existing Asset from PulldDown Menu (Sell/Downsize: Asset to sell/downsize, Buy/Upsize: Asset to draw funds for purchase)
      value: 0,
      year: 0, // e.g. Buy new car for 50.000 NZD (value) in 2023 (year)
      interval: 0,
      period: 0, // e.g., start in 2022 (year) every 1 (interval) year(s) for 20 years (period)
      comment: '' // Up to 250 characters text
    }
  }
}

const unitFiat = () => {
  return [
    { text: 'NZ Dollar', value: 'NZD' },
    { text: 'Australian Dollar', value: 'AUD' },
    { text: 'US Dollar', value: 'USD' },
    { text: 'Euro', value: 'EUR' },
    { text: 'Swiss Franc', value: 'CHF' },
    { text: 'Canadian Dollar', value: 'CAD' },
    { text: 'British Pound Sterling', value: 'GBP' },
    { text: 'Japanese Yen', value: 'JPY' },
    { text: 'Hong Kong Dollar', value: 'HKD' },
    { text: 'Chinese Yuan', value: 'CNY' }
  ]
}

const categoryPreciousMetal = () => {
  return [
    { text: 'Gold', value: 'XAU' },
    { text: 'Silver', value: 'XAG' },
    { text: 'Platinum', value: 'XPT' },
    { text: 'Palladium', value: 'XPD' },
    { text: 'Rhodium', value: 'XRH' }
  ]
}

const unitPreciousMetal = () => {
  return [
    { text: 'oz - Troy ounce', value: 1 },
    { text: 'g - Gram', value: 0.0321507 },
    { text: 'kg - Kilogram', value: 32.1507 }
  ]
}

const categoryCryptoCurrency = () => {
  return [
    { text: 'Bitcoin', value: 'BTC' },
    { text: 'Bitcoin-Cash', value: 'BCH' },
    { text: 'Ethereum', value: 'ETH' },
    { text: 'Litecoin', value: 'LTC' },
    { text: 'Ripple', value: 'XRP' }
  ]
}

const categoryGender = () => {
  return [
    { text: 'Female', value: 'female' },
    { text: 'Male', value: 'male' },
    { text: 'Intersex', value: 'intersex' },
    { text: 'Non-binary', value: 'nonBinary' }
  ]
}

export default {
  getAdvisorProfile() {
    return defaultAdvisorProfile()
  },

  getClientProfile() {
    return defaultClientProfile()
  },

  getAdminDefinitionsAssetObject() {
    return defaultAdminDefinitionsAsset()
  },

  getAdminDefinitionsLiabilityObject() {
    return defaultAdminDefinitionsLiability()
  },

  getAdminDefinitionsIncomeSourceObject() {
    return defaultAdminDefinitionsIncomeSource()
  },

  getAdminDefinitionsSpendingStreamObject() {
    return defaultAdminDefinitionsSpendingStream()
  },

  getAdminDefinitionsRiskTypeObject() {
    return defaultAdminDefinitionsRiskType()
  },

  getAdminDefinitionsModelPortfolioObject() {
    return defaultAdminDefinitionsModelPortfolio()
  },

  getAdvisorDefinitionsAssetObject() {
    return defaultAdvisorDefinitionsAsset()
  },

  getAdvisorDefinitionsLiabilityObject() {
    return defaultAdvisorDefinitionsLiability()
  },

  getAdvisorDefinitionsIncomeSourceObject() {
    return defaultAdvisorDefinitionsIncomeSource()
  },

  getAdvisorDefinitionsSpendingStreamObject() {
    return defaultAdvisorDefinitionsSpendingStream()
  },

  getAdvisorDefinitionsModelPortfolioObject() {
    return defaultAdvisorDefinitionsModelPortfolio()
  },

  getDefinitions() {
    return defaultDefinitions()
  },

  getSystemGoalsPersonal() {
    return systemGoalsPersonal()
  },

  getSystemGoalsInstitutional() {
    return systemGoalsInstitutional()
  },

  getProjection() {
    return defaultProjection()
  },

  getSystemLedger() {
    return systemLedger()
  },

  getAsset() {
    return defaultAsset()
  },

  getLiability() {
    return defaultLiability()
  },

  getIncomeSource() {
    return defaultIncomeSource()
  },

  getSpendingStream() {
    return defaultSpendingStream()
  },

  getGoal() {
    return defaultGoal()
  },

  getAdvice() {
    return defaultAdvice()
  },

  getUnitFiat() {
    return unitFiat()
  },

  getCategoryInvestment() {
    let categories = []
    Object.entries(defaultDefinitions().definitions.assets).forEach(
      ([, val]) => {
        if (val.class == 'Investment') {
          categories.push(val.category)
        }
      }
    )
    return categories
  },

  getCategoryPreciousMetal() {
    return categoryPreciousMetal()
  },

  getUnitPreciousMetal() {
    return unitPreciousMetal()
  },

  getCategoryCryptoCurrency() {
    return categoryCryptoCurrency()
  },

  getCategoryGender() {
    return categoryGender()
  }
}
