import { API } from 'aws-amplify'
import SystemService from '@/services/SystemService.js'
import StaticDataService from '@/services/StaticDataService.js'

export const state = StaticDataService.getClientProfile()

export const mutations = {
  INIT_CLIENT_PROFILE(state) {
    console.log('INIT_CLIENT_PROFILE')
    Object.assign(state, StaticDataService.getClientProfile())
  },

  SET_CLIENT_PROFILE(state, attributes) {
    console.log('SET_CLIENT_PROFILE', attributes)
    state.profile = attributes
  },

  SET_CLIENT_PROFILE_JOINT(state, attributes) {
    console.log('SET_CLIENT_PROFILE_JOINT', attributes)
    state.profileJoint = attributes
  },

  SET_CLIENT_PROFILE_ATTR_NAME(state, value) {
    console.log('SET_CLIENT_PROFILE_ATTR_NAME', value)
    state.profile.name = value
  },

  SET_CLIENT_PROFILE_ATTR_TYPE(state, value) {
    console.log('SET_CLIENT_PROFILE_ATTR_TYPE', value)
    state.profile.type = value
  },

  SET_CLIENT_PROFILE_ATTR_PREF(state, value) {
    console.log('SET_CLIENT_PROFILE_ATTR_PREF', value)
    state.profile.pref = value
  },

  SET_CLIENT_PROFILE_ATTR_JOINTID(state, value) {
    console.log('SET_CLIENT_PROFILE_ATTR_JOINTID', value)
    state.profile.jointId = value
  }
}

export const actions = {
  // Init or Reset Client to Default Parameters
  initClientGlobal({ commit }) {
    commit('INIT_CLIENT_PROFILE')
    commit('RESET_CLIENT_LEDGER')
    commit('INIT_CLIENT_PROJECTION')
  },

  // Fetch Client Global (Profile and Parameters on initial opening of a client)
  fetchClientGlobal({ dispatch }, Id) {
    return dispatch('fetchClient', Id)
      .then(() => dispatch('fetchFxRatesToday'))
      .then(() => dispatch('fetchClientLedger'))
  },

  createClient({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      let path = '/clients'
      let myinit = {
        body: state.profile
      }
      API.post('Falcon9API', path, myinit)
        .then(event => {
          console.log('SUCCESS - API registered new client', event)
          commit('SET_CLIENT_PROFILE', event.appContext)
          dispatch('fetchFxRatesToday')
          resolve(event.appContext.sortKey)
        })
        .catch(err => {
          console.log('ERROR - API failed to register new client', err.response)
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  createClient1st({ state, dispatch, commit }) {
    return dispatch('createClient').then(primaryClientId => {
      // copy attributes for second profile
      let name = state.profile.name
      let type = state.profile.type
      // reset profile in preparation to gather second profile information
      commit('INIT_CLIENT_PROFILE')
      // copy attributes from first profile as preset for second profile
      commit('SET_CLIENT_PROFILE_ATTR_NAME', name)
      commit('SET_CLIENT_PROFILE_ATTR_TYPE', type)
      commit('SET_CLIENT_PROFILE_ATTR_PREF', 'secondary')
      commit('SET_CLIENT_PROFILE_ATTR_JOINTID', primaryClientId)
    })
  },

  fetchClient({ commit, dispatch }, Id) {
    return new Promise((resolve, reject) => {
      let path = '/clients/' + Id
      API.get('Falcon9API', path)
        .then(event => {
          commit('SET_CLIENT_PROFILE', event.response.Item)
          commit('SET_CLIENT_PROFILE_JOINT', event.response.ItemJoint)
          console.log(
            'SUCCESS - API retrieved client profile',
            event.response.Item
          )
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to retrieve client profile',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  updateClient({ commit, dispatch }, Id) {
    return new Promise((resolve, reject) => {
      let path = '/clients/' + Id
      let myinit = {
        body: state.profile
      }
      API.put('Falcon9API', path, myinit)
        .then(event => {
          commit('SET_CLIENT_PROFILE', event.appContext)
          dispatch('fetchClients')
          console.log('SUCCESS - API updated client profile', event.appContext)
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to update client profile',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  updateClientJoint({ commit, dispatch }, Id) {
    return new Promise((resolve, reject) => {
      let path = '/clients/' + Id
      let myinit = {
        body: state.profileJoint
      }
      API.put('Falcon9API', path, myinit)
        .then(event => {
          commit('SET_CLIENT_PROFILE_JOINT', event.appContext)
          dispatch('fetchClients')
          console.log(
            'SUCCESS - API updated joint client profile',
            event.appContext
          )
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to update joint client profile',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  updateClientSettings({ commit, dispatch }, Id) {
    return new Promise((resolve, reject) => {
      let path = '/clients/settings/' + Id
      let myinit = {
        body: state.profile
      }
      API.put('Falcon9API', path, myinit)
        .then(event => {
          commit('SET_CLIENT_PROFILE', event.appContext)
          dispatch('fetchClients')
          console.log('SUCCESS - API updated client settings', event.appContext)
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to update client settings',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  }
}

export const getters = {
  clientLoaded(state) {
    return !!state.profile.partKey
  },

  clientProfile(state) {
    return state.profile
  },

  clientProfileJoint(state) {
    return state.profileJoint
  },

  clientProfileType(state) {
    return state.profile.type != '' ? state.profile.type : ''
  },

  clientName(state) {
    return state.profile.name
  },

  // Client 1 for account profile (mapping can change due to which client has been opened)
  clientAccHolder1(state) {
    return (
      (state.profile.title || '') +
      ' ' +
      state.profile.firstName +
      ' ' +
      state.profile.familyName
    )
  },

  // Client 2 for account profile  (mapping can change due to which client has been opened)
  clientAccHolder2(state) {
    return state.profile.jointId != null
      ? (state.profileJoint.title || '') +
          ' ' +
          state.profileJoint.firstName +
          ' ' +
          state.profileJoint.familyName
      : '  '
  },

  // primary Client (pref attribute) for retirement mapping (unique mapping)
  clientPerson1(state) {
    return state.profile.pref == 'primary'
      ? {
          fullName:
            (state.profile.title || '') +
            ' ' +
            state.profile.firstName +
            ' ' +
            state.profile.familyName,
          firstName: state.profile.firstName,
          birthDate: state.profile.birthDate,
          gender: state.profile.gender,
          currentAge: Math.floor(
            (Date.parse(SystemService.dateToday()) -
              Date.parse(state.profile.birthDate)) /
              31557600000
          )
        }
      : {
          fullName:
            (state.profileJoint.title || '') +
            ' ' +
            state.profileJoint.firstName +
            ' ' +
            state.profileJoint.familyName,
          firstName: state.profileJoint.firstName,
          birthDate: state.profileJoint.birthDate,
          gender: state.profileJoint.gender,
          currentAge: Math.floor(
            (Date.parse(SystemService.dateToday()) -
              Date.parse(state.profileJoint.birthDate)) /
              31557600000
          )
        }
  },

  // secondary Client (pref attribute) for retirement mapping (unique mapping)
  clientPerson2(state) {
    let client
    if (state.profile.jointId != null) {
      if (state.profileJoint.pref == 'secondary') {
        client = {
          fullName:
            (state.profileJoint.title || '') +
            ' ' +
            state.profileJoint.firstName +
            ' ' +
            state.profileJoint.familyName,
          firstName: state.profileJoint.firstName,
          birthDate: state.profileJoint.birthDate,
          gender: state.profileJoint.gender,
          currentAge: Math.floor(
            (Date.parse(SystemService.dateToday()) -
              Date.parse(state.profileJoint.birthDate)) /
              31557600000
          )
        }
      } else {
        client = {
          fullName:
            (state.profile.title || '') +
            ' ' +
            state.profile.firstName +
            ' ' +
            state.profile.familyName,
          firstName: state.profile.firstName,
          birthDate: state.profile.birthDate,
          gender: state.profile.gender,
          currentAge: Math.floor(
            (Date.parse(SystemService.dateToday()) -
              Date.parse(state.profile.birthDate)) /
              31557600000
          )
        }
      }
    } else client = ''
    return client
  },

  clientAccHolderItems(state, getters) {
    let itemsOwner = [state.profile.name]
    if (state.profile.type == 'Joint') {
      itemsOwner.push(getters.clientAccHolder1)
      itemsOwner.push(getters.clientAccHolder2)
    }
    return itemsOwner
  },

  clientId(state) {
    return state.profile.sortKey
  },

  clientIdJoint(state) {
    return state.profileJoint.sortKey
  },

  clientIdPrimary(state) {
    // ClientId (type = primary) where all the data is stored in DDB
    return state.profile.pref == 'primary'
      ? state.profile.sortKey
      : state.profile.jointId
  }
}
