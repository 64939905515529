import { API } from 'aws-amplify'

export const state = {
  profile: null
}

export const mutations = {
  INIT_ADVISOR_PROFILE(state) {
    state.profile = null
  },

  SET_ADVISOR_PROFILE(state, attributes) {
    console.log('Set_ADVISOR_PROFILE', attributes)
    state.profile = attributes
  }
}

export const actions = {
  // initialize profile object (dynamically via API) before AdvisorProfile page is being shown
  async initDbAdvisorProfile({ state, commit, dispatch }) {
    if (!state.profile) {
      let type = this.getters.userId
      let path = '/advisors/' + type
      await API.get('Falcon9API', path)
        .then(event => {
          commit('SET_ADVISOR_PROFILE', event.response.Item)
          console.log(
            'SUCCESS - API GET Response INIT Advisor Profile:',
            event.response.Item
          )
        })
        .catch(err => {
          console.log(
            'ERROR - API GET Response INIT Advisor Profile:',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
        })
    }
  },

  async readDbAdvisorProfile({ commit, dispatch }) {
    let type = this.getters.userId
    let path = '/advisors/' + type
    await API.get('Falcon9API', path)
      .then(event => {
        commit('SET_ADVISOR_PROFILE', event.response.Item)
        console.log(
          'SUCCESS - API GET Response READ Advisor Profile:',
          event.response.Item
        )
      })
      .catch(err => {
        console.log(
          'ERROR - API GET Response READ Advisor Profile:',
          err.response
        )
        const notification = {
          type: 'error',
          message: err.message
        }
        dispatch('addNotification', notification)
      })
  },

  async updateDbAdvisorProfile({ state, commit, dispatch }) {
    let name = state.profile.firstName + ' ' + state.profile.familyName
    state.profile.name = name
    let type = this.getters.userId
    let path = '/advisors/' + type
    let myinit = {
      body: state.profile
    }
    await API.put('Falcon9API', path, myinit)
      .then(event => {
        commit('SET_ADVISOR_PROFILE', event.appContext)
        console.log('SUCCESS - PUT Response WRITE Advisor Profile:', event)
      })
      .catch(err => {
        console.log(
          'ERROR - API PUT Response WRITE Advisor Profile:',
          err.response
        )
        const notification = {
          type: 'error',
          message: err.message
        }
        dispatch('addNotification', notification)
      })
  }
}

export const getters = {
  advisorProfile(state) {
    return state.profile
  }
}
