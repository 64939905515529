import { API } from 'aws-amplify'
import StaticDataService from '@/services/StaticDataService.js'

export const state = StaticDataService.getDefinitions()

export const mutations = {
  INIT_ADMIN_DEFINITIONS(state) {
    Object.assign(state, StaticDataService.getDefinitions())
  },

  SET_ADMIN_DEFINITIONS(state, attributes) {
    state.definitions = attributes
  },

  CREATE_ADMIN_DEFINITION_ITEM(state, definitionItem) {
    console.log('CREATE_ADMIN_DEFINITION_ITEM', definitionItem.item)
    state.definitions[definitionItem.group].push(definitionItem.item)
  },

  UPDATE_ADMIN_DEFINITION_ITEM(state, definitionItem) {
    console.log('UPDATE_ADMIN_DEFINITION_ITEM', definitionItem.item)
    let index = state.definitions[definitionItem.group].findIndex(
      obj => obj.id == definitionItem.item.id
    )
    state.definitions[definitionItem.group].splice(index, 1)
    state.definitions[definitionItem.group].push(definitionItem.item)
  },

  DELETE_ADMIN_DEFINITION_ITEM(state, definitionItem) {
    console.log('DELETE_ADMIN_DEFINITION_ITEM', definitionItem.item)
    let index = state.definitions[definitionItem.group].findIndex(
      obj => obj.id == definitionItem.item.id
    )
    state.definitions[definitionItem.group].splice(index, 1)
  },

  DELETE_ADMIN_MODEL_PORTFOLIO(state, definitionItem) {
    console.log('DELETE_ADMIN_MODEL_PORTFOLIO', definitionItem.item)
    definitionItem.item.map(id => {
      let index = state.definitions[definitionItem.group].findIndex(
        obj => obj.id == id
      )
      state.definitions[definitionItem.group].splice(index, 1)
    })
  }
}

export const actions = {
  createAdminGenesisDefinitions({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      let path = '/admin/definitions'
      let myinit = {
        body: state.definitions
      }
      API.post('Falcon9API', path, myinit)
        .then(event => {
          console.log('SUCCESS - API created genesis admin definitions', event)
          commit('SET_ADMIN_DEFINITIONS', event.appContext)
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to create genesis admin definitions',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  fetchAdminDefinitions({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      let path = '/admin/definitions'
      API.get('Falcon9API', path)
        .then(event => {
          if (event.response.modelPortfolios.length != 0) {
            commit('SET_ADMIN_DEFINITIONS', event.response)
            console.log(
              'SUCCESS - API retrieved admin definitions',
              event.response
            )
            resolve()
          } else {
            dispatch('createAdminGenesisDefinitions').then(() => resolve())
          }
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to retrieve admin definitions',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  createAdminDefinitionItem({ commit, dispatch }, definitionItem) {
    return new Promise((resolve, reject) => {
      let group = definitionItem.group
      let path = '/admin/definitions/' + group
      let myinit = {
        body: definitionItem.item
      }
      API.post('Falcon9API', path, myinit)
        .then(event => {
          commit('CREATE_ADMIN_DEFINITION_ITEM', definitionItem)
          console.log('SUCCESS - API created definition in DDB', event)
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to create definition in DDB',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  updateAdminDefinitionItem({ commit, dispatch }, definitionItem) {
    return new Promise((resolve, reject) => {
      let group = definitionItem.group
      let path = '/admin/definitions/' + group
      let myinit = {
        body: definitionItem.item
      }
      API.put('Falcon9API', path, myinit)
        .then(event => {
          commit('UPDATE_ADMIN_DEFINITION_ITEM', definitionItem)
          console.log('SUCCESS - API updated definition in DDB', event)
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to update definition in DDB',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  },

  deleteAdminDefinitionItem({ commit, dispatch }, definitionItem) {
    return new Promise((resolve, reject) => {
      let group = definitionItem.group
      let path = '/admin/definitions/' + group
      let myinit = {
        body: definitionItem.item
      }
      API.del('Falcon9API', path, myinit)
        .then(() => {
          if (group == 'modelPortfolios') {
            commit('DELETE_ADMIN_MODEL_PORTFOLIO', definitionItem)
            console.log(
              'SUCCESS - SUCCESS - API deleted model portfolio in DDB'
            )
            resolve()
          } else {
            commit('DELETE_ADMIN_DEFINITION_ITEM', definitionItem)
            console.log('SUCCESS - SUCCESS - API deleted definition in DDB')
            resolve()
          }
        })
        .catch(err => {
          console.log(
            'ERROR - API failed to delete definition in DDB',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  }
}

export const getters = {
  adminAssetClass(state) {
    let assetClasses = []
    Object.entries(state.definitions.assets).forEach(([, val]) => {
      assetClasses.push(val.class)
    })
    return new Set(assetClasses)
  },

  adminDefinitions(state) {
    return state.definitions
  },

  adminDefinitionsAssets(state) {
    return state.definitions.assets
  },

  adminDefinitionsAssetInvestmentCategories(state) {
    const investmentCategories = []
    const investmentCategoryNamesRanked = []
    // retrieve all(default/individual) Investment Categories (ranked) from Database
    Object.entries(state.definitions.assets).forEach(([, obj]) => {
      if (obj.class == 'Investment') {
        investmentCategories.push(obj)
      }
    })
    // sort admin investment category objects according to rank
    investmentCategories.sort(function(a, b) {
      return a.rank - b.rank
    })
    // create an array of investment category names (sorted by rank)
    Object.entries(investmentCategories).forEach(([, obj]) => {
      investmentCategoryNamesRanked.push({ name: obj.category, type: obj.type })
    })
    return investmentCategoryNamesRanked
  },

  adminDefinitionsLiabilities(state) {
    return state.definitions.liabilities
  },

  adminDefinitionsIncome(state) {
    return state.definitions.incomeSources
  },

  adminDefinitionsSpending(state) {
    return state.definitions.spendingStreams
  },

  adminDefinitionsRiskTypes(state) {
    // retrieve all(default/individual) RiskType Objects (ranked) from Database
    let riskTypesRanked = state.definitions.riskTypes
    riskTypesRanked.sort(function(a, b) {
      return a.rank - b.rank
    })
    return riskTypesRanked
  },

  adminDefinitionsRiskTypeNames(state, getters) {
    const riskTypeNames = []
    // retrieve all(default/individual) RiskType Names (ranked) from Database
    Object.entries(getters.adminDefinitionsRiskTypes).forEach(([, obj]) => {
      riskTypeNames.push(obj.name)
    })
    return riskTypeNames
  },

  adminDefinitionsModelPortfolios(state) {
    return state.definitions.modelPortfolios
  }
}
