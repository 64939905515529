import { API } from 'aws-amplify'
import StaticDataService from '@/services/StaticDataService.js'

export const state = StaticDataService.getAdvisorProfile()

export const mutations = {
  INIT_ADMIN_ADVISOR_PROFILE(state) {
    console.log('INIT_ADMIN_ADVISOR_PROFILE')
    Object.assign(state, StaticDataService.getAdvisorProfile())
  },

  SET_ADMIN_ADVISOR_PROFILE(state, attributes) {
    console.log('SET_ADMIN_ADVISOR_PROFILE', attributes)
    state.profile = attributes
  },

  SET_ADMIN_ADVISOR_PROFILE_ATTR_NAME(state, value) {
    console.log('SET_ADMIN_ADVISOR_PROFILE_ATTR_NAME', value)
    state.profile.name = value
  }
}

export const actions = {
  initAdminAdvisorProfile({ commit }) {
    commit('INIT_ADMIN_ADVISOR_PROFILE')
  },

  setAdvisor({ commit }, attributes) {
    commit('SET_ADMIN_ADVISOR_PROFILE', attributes)
  },

  createAdvisor({ dispatch }) {
    return new Promise((resolve, reject) => {
      let path = '/admin/advisors'
      let myinit = {
        body: state.profile
      }
      API.post('Falcon9API', path, myinit)
        .then(event => {
          console.log('SUCCESS - API admin registered new advisor', event)
          dispatch('fetchAdvisors')
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API admin failed to register new advisor',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
          reject()
        })
    })
  },

  fetchAdvisor({ commit }, Id) {
    return new Promise((resolve, reject) => {
      let path = '/admin/advisors/' + Id
      API.get('Falcon9API', path)
        .then(event => {
          commit('SET_ADMIN_ADVISOR_PROFILE', event.response.Item)
          console.log(
            'SUCCESS - API admin retrieved advisor profile',
            event.response.Item
          )
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API admin failed to retrieve advisor profile',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
          reject()
        })
    })
  },

  updateAdvisor({ dispatch }, Id) {
    return new Promise((resolve, reject) => {
      console.log('advisor-id =', Id)
      let path = '/admin/advisors/' + Id
      let myinit = {
        body: state.profile
      }
      API.put('Falcon9API', path, myinit)
        .then(event => {
          // commit('SET_ADMIN_ADVISOR_PROFILE', event.appContext)
          dispatch('initAdminAdvisorProfile')
          dispatch('fetchAdvisors')
          console.log(
            'SUCCESS - API admin updated advisor profile',
            event.appContext
          )
          resolve()
        })
        .catch(err => {
          console.log(
            'ERROR - API admin failed to update advisor profile',
            err.response
          )
          const notification = {
            type: 'error',
            message: err.message
          }
          dispatch('addNotification', notification)
          reject()
        })
    })
  }
}

export const getters = {
  adminAdvisorId(state) {
    return state.profile.sortKey
  }
}
